import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

export const HandleDownload = async (data: any, fileName: any) => {
  console.log('data', data);
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Property Information Sheet');
  //   worksheet.columns = [
  //     { header: 'Name', key: 'name', width: 20 },
  //     { header: 'Age', key: 'age', width: 10 },
  //     { header: 'Email', key: 'email', width: 30 },
  //   ];
  const columns = [];
  for (let item in data[0]) {
    // pascalToNormalText
    const withSpaces = item
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between lowercase and uppercase
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2') // Add space between acronym and capitalized word
      .trim();

    const header =
      withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1).toLowerCase();
    columns.push({
      header,
      key: item,
    });
  }
  worksheet.columns = columns;
  worksheet.addRows(data);

  // Style the header row
  const headerRow = worksheet.getRow(1); // Header row is usually row 1
  headerRow.eachCell((cell) => {
    cell.font = { bold: true }; // Make bold and increase font size
  });

  // Generate Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  saveAs(
    blob,
    `${fileName}_${new Date().toISOString().replace(/[:.]/g, '-')}.xlsx`,
  );
};
