import { Col, DatePicker, Empty, Modal, Row, Select, Spin } from 'antd'
import { useGetParcelStatsQuery } from 'app/services'
import DownloadButton from 'components/DownloadExcel/DownloadButton'
import { HandleDownload } from 'components/DownloadExcel/HandleDownload'
import React, { useEffect, useState } from 'react'
import { CITIESFORSEARCH, stringifySearchObject } from 'utils'

const MarketStats = ({ isStatsModalVisible, setIsStatsModalVisible, title }: any) => {

   const [statsData, setStatsData] = useState<any>(null)
   const [statsDataFilters, setStatsDataFilters] = useState<any>({})

   const onFilterChange = (filter: any, value: any) => {
      setStatsDataFilters({ ...statsDataFilters, [filter]: value })
   }

   const {
      data: ParcelsStats,
      isSuccess: isParcelsStatsuccess,
      isFetching: isParcelsStatsFetching,
   } = useGetParcelStatsQuery(`?${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true,
      skip: !isStatsModalVisible
   });

   useEffect(() => {
      if (isParcelsStatsuccess && ParcelsStats?.length) {
         setStatsData(ParcelsStats[0])
      }
   }, [ParcelsStats, isParcelsStatsuccess])

   return (
      <Modal
         title={<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>{title}</div>
            <DownloadButton type="" onClick={() => HandleDownload(ParcelsStats, `${title}`)} style={{ borderRadius: 7, marginRight: 7 }} items={ParcelsStats} />
         </div>}
         open={isStatsModalVisible}
         onOk={() => setIsStatsModalVisible(false)}
         onCancel={() => setIsStatsModalVisible(false)}
         width={900}
         style={{ marginTop: 50 }}
         bodyStyle={{ minHeight: 100, maxHeight: 700, overflowY: 'scroll', fontSize: 13 }}
      >
         <Spin
            style={{ marginTop: 15 }}
            spinning={isParcelsStatsFetching}
            tip='Loading' size="large"
         >
            {statsData ?
               <>
                  <Row>
                     <Col span={8}>
                        <Select
                           showSearch
                           mode='multiple'
                           allowClear
                           placeholder="Select Cities"
                           optionFilterProp="children"
                           filterOption={(input: any, option: any) => (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase())}
                           options={CITIESFORSEARCH}
                           style={{ width: '100%' }}
                           dropdownStyle={{ zIndex: 9999, width: '100%' }}
                           onChange={(e) => onFilterChange('cities', e)}
                        />
                     </Col>
                     <Col span={6}></Col>
                     <Col span={10}>
                        <DatePicker.RangePicker style={{ borderRadius: '50px' }}
                           onChange={(e, time) => onFilterChange('dates', time)} />
                     </Col>
                  </Row>
                  <Row style={{ margin: '15px 5px' }}>
                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">Average List Dom: </span>
                           <span className="meta-detail-value">{statsData?.average_list_dom}</span>
                        </div>
                     </Col>
                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">Average List Price: </span>
                           <span className="meta-detail-value">{statsData?.average_list_price}</span>
                        </div>
                     </Col>
                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">Average Lot Size: </span>
                           <span className="meta-detail-value">{statsData?.average_lot_size}</span>
                        </div>
                     </Col>
                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">Average Sqft: </span>
                           <span className="meta-detail-value">{statsData?.avg_sqft}</span>
                        </div>
                     </Col>
                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">High List Dom: </span>
                           <span className="meta-detail-value">{statsData?.high_list_dom}</span>
                        </div>
                     </Col>
                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">High List Price: </span>
                           <span className="meta-detail-value">{statsData?.high_list_price}</span>
                        </div>
                     </Col>
                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">Low List Dom: </span>
                           <span className="meta-detail-value">{statsData?.low_list_dom}</span>
                        </div>
                     </Col>
                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">Low List Price: </span>
                           <span className="meta-detail-value">{statsData?.low_list_price}</span>
                        </div>
                     </Col>
                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">Median List Dom: </span>
                           <span className="meta-detail-value">{statsData?.median_list_dom}</span>
                        </div>
                     </Col>
                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">Median List Price: </span>
                           <span className="meta-detail-value">{statsData?.median_list_price}</span>
                        </div>
                     </Col>

                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">Q1 List Dom: </span>
                           <span className="meta-detail-value">{statsData?.q1_list_dom}</span>
                        </div>
                     </Col>
                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">Q1 List Price: </span>
                           <span className="meta-detail-value">{statsData?.q1_list_price}</span>
                        </div>
                     </Col>
                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">Q3 List Dom: </span>
                           <span className="meta-detail-value">{statsData?.q3_list_dom}</span>
                        </div>
                     </Col>
                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">Q3 List Price: </span>
                           <span className="meta-detail-value">{statsData?.q3_list_price}</span>
                        </div>
                     </Col>
                     <Col span={12}>
                        <div className="meta-detail">
                           <span className="meta-detail-name">Number of Listings: </span>
                           <span className="meta-detail-value">{statsData?.number_of_listings}</span>
                        </div>
                     </Col>
                  </Row></> : !isParcelsStatsFetching ? <div className="popupEmptyMsg">
                     <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div> : null}
         </Spin>

      </Modal>
   )
}

export default MarketStats