import { skipToken } from "@reduxjs/toolkit/query";
import { Badge, Card, Empty, Spin, Tabs } from "antd"
import TabPane from "antd/lib/tabs/TabPane";
import { useGetSurveyQueriesQuery, useGetSurveyResutsCountsQuery, useGetUserByQuestionProIdQuery } from "app/services";
import { useEffect, useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";

export const ViewQuery = () => {

    const Tab = ({ property, total }: any) => (
        <div style={{ display: 'flex' }}>
            <div className="name">
                <span>{property}</span>
            </div>
            <div className="total" style={{ marginLeft: 6 }}>
                {/* <span>{total}</span> */}
                <Badge count={total} color='#1C7A92' overflowCount={100000} showZero />
            </div>
        </div>
    );

    const [questionProID, setQuestionProID] = useState<any>(null)
    const [queries, setQueries] = useState<any>({})
    const [user, setUser] = useState<any>(null)

    const { data: surveyResponse, isLoading: isSurveyResponseLoading, isSuccess: isSurveyResponseSuccess } =
        useGetUserByQuestionProIdQuery
            ({ id: questionProID }, {
                skip: !questionProID,
            });

    useEffect(() => {
        if (!isSurveyResponseLoading && surveyResponse) {
            setUser(surveyResponse)
        }
    }, [isSurveyResponseLoading, surveyResponse])

    const { data: surveyQueries, isLoading: isSurveyQueriesLoading } =
        useGetSurveyQueriesQuery
            ({ id: questionProID, isRecalculating: false }, {
                skip: !questionProID || !user || user.role !== 'admin',
            });

    let {
        data: builderCount,
        isFetching: isBuilderCountFetching
    } = useGetSurveyResutsCountsQuery(
        {
            surveyid: surveyResponse?.surveyid ? surveyResponse.surveyid : skipToken,
            page: 1,
            limit: 10,
            matchingtype: 'builders',
            isRecalculating: false
        },
        { skip: !surveyResponse || !surveyResponse.surveyid },
    );

    let {
        data: investorCount,
        isFetching: isInvestorCountFetching
    } = useGetSurveyResutsCountsQuery(
        {
            surveyid: surveyResponse?.surveyid ? surveyResponse.surveyid : skipToken,
            page: 1,
            limit: 10,
            matchingtype: 'investors',
            isRecalculating: false
        },
        { skip: !surveyResponse || !surveyResponse.surveyid },
    );

    let {
        data: developerCount,
        isFetching: isDeveloperCountFetching
    } = useGetSurveyResutsCountsQuery(
        {
            surveyid: surveyResponse?.surveyid ? surveyResponse.surveyid : skipToken,
            page: 1,
            limit: 10,
            matchingtype: 'developers',
            isRecalculating: false
        },
        { skip: !surveyResponse || !surveyResponse.surveyid },
    );

    let {
        data: realEstateAgentCount,
        isFetching: isRealEstateAgentCountFetching
    } = useGetSurveyResutsCountsQuery(
        {
            surveyid: surveyResponse?.surveyid ? surveyResponse.surveyid : skipToken,
            page: 1,
            limit: 10,
            matchingtype: 'realEstateAgents',
            isRecalculating: false
        },
        { skip: !surveyResponse || !surveyResponse.surveyid },
    );

    let {
        data: newBuildHomebuyerCount,
        isFetching: isNewBuildHomebuyerCountFetching
    } = useGetSurveyResutsCountsQuery(
        {
            surveyid: surveyResponse?.surveyid ? surveyResponse.surveyid : skipToken,
            page: 1,
            limit: 10,
            matchingtype: 'newBuildHomebuyers',
            isRecalculating: false
        },
        { skip: !surveyResponse || !surveyResponse.surveyid },
    );

    let {
        data: waterRightsCount,
        isFetching: isWaterRightsCountFetching
    } = useGetSurveyResutsCountsQuery(
        {
            surveyid: surveyResponse?.surveyid ? surveyResponse.surveyid : skipToken,
            page: 1,
            limit: 10,
            matchingtype: 'waterRights',
            isRecalculating: false
        },
        { skip: !surveyResponse || !surveyResponse.surveyid },
    );

    let {
        data: parcelsCount,
        isFetching: isParcelsCountFetching
    } = useGetSurveyResutsCountsQuery(
        {
            surveyid: surveyResponse?.surveyid ? surveyResponse.surveyid : skipToken,
            page: 1,
            limit: 10,
            matchingtype: 'parcels',
            isRecalculating: false
        },
        { skip: !surveyResponse || !surveyResponse.surveyid },
    );

    let {
        data: projectsCount,
        isFetching: isProjectsCountFetching
    } = useGetSurveyResutsCountsQuery(
        {
            surveyid: surveyResponse?.surveyid ? surveyResponse.surveyid : skipToken,
            page: 1,
            limit: 10,
            matchingtype: 'projects',
            isRecalculating: false
        },
        { skip: !surveyResponse || !surveyResponse.surveyid },
    );

    const getColumnNamesFromQuery = (sql: any) => {
        const columnRegex = /c\.(".*?")/g;
        const columnMatches = [...sql.matchAll(columnRegex)];
        const columnNames = columnMatches.map(match => match[1].replace(/"/g, ''));
        return [...new Set(columnNames)]
    }

    const getColumnNamesFromQueryForProjects = (sql: any) => {
        const columnNames = sql.match(/"([^"]+)"/g);
        const cleanColumnNames = columnNames.map((elem: any) => elem.replace(/"/g, ''));
        return [...new Set(cleanColumnNames.filter((elem: any) => elem !== 'Project' && elem !== 'projects_new_history' && elem !== 'parcels_new_history'))]
    }

    useEffect(() => {
        if (surveyQueries) {
            const waterRights = surveyQueries?.waterRights?.trim()?.replace('* FROM', 'COUNT(*) FROM')
            const projects = surveyQueries?.projects
            const parcels = surveyQueries?.parcels

            // Investors
            const investorsContactsArr: any = []
            const investorsCompaniesArr: any = []
            surveyQueries?.contacts?.forEach((elem: any) => {
                if (elem.includes("SELECT *, 'Investors'")) {
                    investorsContactsArr.push(elem.replace(`*, 'Investors' as "role"`, 'COUNT(*)')?.trim())
                }
            })
            surveyQueries?.companies?.forEach((elem: any) => {
                if (elem.includes("SELECT *, 'Investors'")) {
                    investorsCompaniesArr.push(elem.replace(`*, 'Investors' as "role"`, 'COUNT(*)')?.trim())
                }
            })
            const investorContacts = investorsContactsArr?.join(' UNION ')
            const investorCompanies = investorsCompaniesArr?.join(' UNION ')
            // Investors

            // Builders
            const buildersContactsArr: any = []
            const buildersCompaniesArr: any = []
            surveyQueries?.contacts?.forEach((elem: any) => {
                if (elem.includes("SELECT *, 'Builder'")) {
                    buildersContactsArr.push(elem.replace(`*, 'Builder' as "role"`, 'COUNT(*)')?.trim())
                }
            })
            surveyQueries?.companies?.forEach((elem: any) => {
                if (elem.includes("SELECT *, 'Builder'")) {
                    buildersCompaniesArr.push(elem.replace(`*, 'Builder' as "role"`, 'COUNT(*)')?.trim())
                }
            })
            const builderContacts = buildersContactsArr?.join(' UNION ')
            const builderCompanies = buildersCompaniesArr?.join(' UNION ')
            // Builders

            // Developers
            const developersContactsArr: any = []
            const developersCompaniesArr: any = []
            surveyQueries?.contacts?.forEach((elem: any) => {
                if (elem.includes("SELECT *, 'Developer'")) {
                    developersContactsArr.push(elem.replace(`*, 'Developer' as "role"`, 'COUNT(*)')?.trim())
                }
            })
            surveyQueries?.companies?.forEach((elem: any) => {
                if (elem.includes("SELECT *, 'Developer'")) {
                    developersCompaniesArr.push(elem.replace(`*, 'Developer' as "role"`, 'COUNT(*)')?.trim())
                }
            })
            const developerContacts = developersContactsArr?.join(' UNION ')
            const developerCompanies = developersCompaniesArr?.join(' UNION ')
            // Developers

            // Homebuyers
            const homebuyersContactsArr: any = []
            const homebuyersCompaniesArr: any = []
            surveyQueries?.contacts?.forEach((elem: any) => {
                if (elem.includes("SELECT *, 'New Build Homebuyer'")) {
                    homebuyersContactsArr.push(elem.replace(`*, 'New Build Homebuyer' as "role"`, 'COUNT(*)')?.trim())
                }
            })
            surveyQueries?.companies?.forEach((elem: any) => {
                if (elem.includes("SELECT *, 'New Build Homebuyer'")) {
                    homebuyersCompaniesArr.push(elem.replace(`*, 'New Build Homebuyer' as "role"`, 'COUNT(*)')?.trim())
                }
            })
            const homebuyerContacts = homebuyersContactsArr?.join(' UNION ')
            const homebuyerCompanies = homebuyersCompaniesArr?.join(' UNION ')
            // Homebuyers

            // RealEstateAgents
            const realEstateAgentContactsArr: any = []
            const realEstateAgentCompaniessArr: any = []
            surveyQueries?.contacts?.forEach((elem: any) => {
                if (elem.includes("SELECT *, 'Real Estate Agent'")) {
                    realEstateAgentContactsArr.push(elem.replace(`*, 'Real Estate Agent' as "role"`, 'COUNT(*)')?.trim())
                }
            })
            surveyQueries?.companies?.forEach((elem: any) => {
                if (elem.includes("SELECT *, 'Real Estate Agent'")) {
                    realEstateAgentCompaniessArr.push(elem.replace(`*, 'Real Estate Agent' as "role"`, 'COUNT(*)')?.trim())
                }
            })
            const realEstateAgentContacts = realEstateAgentContactsArr?.join(' UNION ')
            const realEstateAgentCompanies = realEstateAgentCompaniessArr?.join(' UNION ')
            // RealEstateAgents

            const finalQueries: any = {}
            if (builderContacts || builderCompanies) {
                finalQueries.Builders = []
                if (builderContacts) {
                    finalQueries.Builders.push({
                        title: 'Contacts',
                        query: builderContacts,
                        count: isBuilderCountFetching ? 'Loading..' : builderCount ? builderCount?.contactsCount : 0,
                        totalCount: isBuilderCountFetching ? 'Loading..' : builderCount ? +builderCount?.contactsCount + +builderCount?.companiesCount : 0,
                        columns: builderContacts ?
                            getColumnNamesFromQuery(builderContacts) : []
                    })
                }
                if (builderCompanies) {
                    finalQueries.Builders.push({
                        title: 'Companies',
                        query: builderCompanies,
                        count: isBuilderCountFetching ? 'Loading..' : builderCount ? builderCount?.companiesCount : 0,
                        totalCount: isBuilderCountFetching ? 'Loading..' : builderCount ? +builderCount?.contactsCount + +builderCount?.companiesCount : 0,
                        columns: builderCompanies ?
                            getColumnNamesFromQuery(builderCompanies) : []
                    })
                }
            }
            if (investorContacts || investorCompanies) {
                finalQueries.Investors = []
                if (investorContacts) {
                    finalQueries.Investors.push({
                        title: 'Contacts',
                        query: investorContacts,
                        count: isInvestorCountFetching ? 'Loading..' : investorCount ? investorCount?.contactsCount : 0,
                        totalCount: isInvestorCountFetching ? 'Loading..' : investorCount ? +investorCount?.contactsCount +
                            +investorCount?.companiesCount : 0,
                        columns: investorContacts ?
                            getColumnNamesFromQuery(investorContacts) : []
                    })
                }
                if (investorCompanies) {
                    finalQueries.Investors.push({
                        title: 'Companies',
                        query: investorCompanies,
                        count: isInvestorCountFetching ? 'Loading..' : investorCount ? investorCount?.companiesCount : 0,
                        totalCount: isInvestorCountFetching ? 'Loading..' : investorCount ? +investorCount?.contactsCount +
                            +investorCount?.companiesCount : 0,
                        columns: investorCompanies ?
                            getColumnNamesFromQuery(investorCompanies) : []
                    })
                }
            }

            if (developerContacts || developerCompanies) {
                finalQueries.Developers = []
                if (developerContacts) {
                    finalQueries.Developers.push({
                        title: 'Contacts',
                        query: developerContacts,
                        count: isDeveloperCountFetching ? 'Loading..' : developerCount ? developerCount?.contactsCount : 0,
                        totalCount: isDeveloperCountFetching ? 'Loading..' : developerCount ? +developerCount?.contactsCount +
                            +developerCount?.companiesCount : 0,
                        columns: developerContacts ?
                            getColumnNamesFromQuery(developerContacts) : []
                    })
                }
                if (developerCompanies) {
                    finalQueries.Developers.push({
                        title: 'Companies',
                        query: developerCompanies,
                        count: isDeveloperCountFetching ? 'Loading..' : developerCount ? developerCount?.companiesCount : 0,
                        totalCount: isDeveloperCountFetching ? 'Loading..' : developerCount ? +developerCount?.contactsCount +
                            +developerCount?.companiesCount : 0,
                        columns: developerCompanies ?
                            getColumnNamesFromQuery(developerCompanies) : []
                    })
                }
            }

            if (homebuyerContacts || homebuyerCompanies) {
                finalQueries['New Build Homebuyer'] = []
                if (homebuyerContacts) {
                    finalQueries['New Build Homebuyer'].push({
                        title: 'Contacts',
                        query: homebuyerContacts,
                        count: isNewBuildHomebuyerCountFetching ? 'Loading..' : newBuildHomebuyerCount ? newBuildHomebuyerCount?.contactsCount : 0,
                        totalCount: isNewBuildHomebuyerCountFetching ? 'Loading..' : newBuildHomebuyerCount ? +newBuildHomebuyerCount?.contactsCount +
                            +newBuildHomebuyerCount?.companiesCount : 0,
                        columns: homebuyerContacts ?
                            getColumnNamesFromQuery(homebuyerContacts) : []
                    })
                }
                if (homebuyerCompanies) {
                    finalQueries['New Build Homebuyer'].push({
                        title: 'Companies',
                        query: homebuyerCompanies,
                        count: isNewBuildHomebuyerCountFetching ? 'Loading..' : newBuildHomebuyerCount ? newBuildHomebuyerCount?.companiesCount : 0,
                        totalCount: isNewBuildHomebuyerCountFetching ? 'Loading..' : newBuildHomebuyerCount ? +newBuildHomebuyerCount?.contactsCount +
                            +newBuildHomebuyerCount?.companiesCount : 0,
                        columns: homebuyerCompanies ?
                            getColumnNamesFromQuery(homebuyerCompanies) : []
                    })
                }
            }

            if (realEstateAgentContacts || realEstateAgentCompanies) {
                finalQueries['Real Estate Agents'] = []
                if (realEstateAgentContacts) {
                    finalQueries['Real Estate Agents'].push({
                        title: 'Contacts',
                        query: realEstateAgentContacts,
                        count: isRealEstateAgentCountFetching ? 'Loading..' : realEstateAgentCount ? realEstateAgentCount?.contactsCount : 0,
                        totalCount: isRealEstateAgentCountFetching ? 'Loading..' : realEstateAgentCount ? +realEstateAgentCount?.contactsCount +
                            +realEstateAgentCount?.companiesCount : 0,
                        columns: realEstateAgentContacts ?
                            getColumnNamesFromQuery(realEstateAgentContacts) : []
                    })
                }
                if (realEstateAgentCompanies) {
                    finalQueries['Real Estate Agents'].push({
                        title: 'Companies',
                        query: realEstateAgentCompanies,
                        count: isRealEstateAgentCountFetching ? 'Loading..' : realEstateAgentCount ? realEstateAgentCount?.companiesCount : 0,
                        totalCount: isRealEstateAgentCountFetching ? 'Loading..' : realEstateAgentCount ? +realEstateAgentCount?.contactsCount +
                            +realEstateAgentCount?.companiesCount : 0,
                        columns: realEstateAgentCompanies ?
                            getColumnNamesFromQuery(realEstateAgentCompanies) : []
                    })
                }
            }

            if (waterRights) {
                finalQueries['Water Rights'] = []
                finalQueries['Water Rights'].push({
                    title: 'Water Rights',
                    count: isWaterRightsCountFetching ? 'Loading..' : waterRightsCount ? waterRightsCount?.waterRightsCount : 0,
                    totalCount: isWaterRightsCountFetching ? 'Loading..' : waterRightsCount ? waterRightsCount?.waterRightsCount : 0,
                    query: waterRights,
                    columns: waterRights ? ['WRNUM'] : []
                })
            }

            if (projects) {
                finalQueries.Projects = []
                finalQueries.Projects.push({
                    title: 'Projects',
                    count: isProjectsCountFetching ? 'Loading..' : projectsCount?.projectsCount ? projectsCount?.projectsCount : 0,
                    totalCount: isProjectsCountFetching ? 'Loading..' : projectsCount?.projectsCount ? projectsCount?.projectsCount : 0,
                    query: projects,
                    columns: projects ? getColumnNamesFromQueryForProjects(projects) : []
                })
            }

            if (parcels) {
                finalQueries.Parcels = []
                finalQueries.Parcels.push({
                    title: 'Parcels',
                    count: isParcelsCountFetching ? 'Loading..' : parcelsCount?.parcelsCount ? parcelsCount?.parcelsCount : 0,
                    totalCount: isParcelsCountFetching ? 'Loading..' : parcelsCount?.parcelsCount ? parcelsCount?.parcelsCount : 0,
                    query: parcels,
                    columns: parcels ? getColumnNamesFromQueryForProjects(parcels) : []
                })
            }
            setQueries(finalQueries)
            console.log('final', finalQueries)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surveyQueries, isBuilderCountFetching, isInvestorCountFetching,
        isRealEstateAgentCountFetching, isNewBuildHomebuyerCountFetching,
        isRealEstateAgentCountFetching, isWaterRightsCountFetching,
        isDeveloperCountFetching, isProjectsCountFetching, isParcelsCountFetching])
    useEffect(() => {
        const surveyIdMatch = window.location.search.match(/surveyId=(\d+)/);
        const surveyId = surveyIdMatch ? surveyIdMatch[1] : null;
        setQuestionProID(surveyId)
    }, [])
    return (
        <div style={{ padding: '25px' }}>
            {isSurveyResponseLoading || isSurveyQueriesLoading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Spin
                        style={{ marginBottom: 100 }}
                        spinning={isSurveyResponseLoading || isSurveyQueriesLoading}
                        tip='Loading' size="large"
                    ></Spin>
                </div> : null
            }
            {!isSurveyQueriesLoading && !isSurveyResponseLoading && isSurveyResponseSuccess && (!user || user?.role !== 'admin') &&
                <div className="view-query-empty"><Empty
                    className="matched-item-popover-no-results"
                    description={"User restricted!"}
                />
                </div>
            }
            {!isSurveyQueriesLoading && !isSurveyResponseLoading && user?.role === 'admin' &&
                <Tabs>
                    {
                        Object.entries(queries)?.map(([key, value]: any) => (
                            <TabPane
                                tab={
                                    <Tab
                                        property={`${key}`}
                                        total={value?.length ? value[0]?.totalCount : 0}
                                    />
                                }
                                key={key}
                            >
                                <Tabs type='card'>
                                    {
                                        value?.length && value?.map((elem: any) => (
                                            <TabPane
                                                tab={
                                                    <Tab
                                                        property={`${elem.title}`}
                                                        total={elem.count || 0}
                                                    />
                                                }
                                                key={elem.title}
                                            >
                                                <CopyBlock
                                                    language="sql"
                                                    text={elem?.query}
                                                    wrapLines
                                                    theme={dracula}
                                                />
                                                {elem?.columns?.length &&
                                                    <div style={{ marginTop: 25 }}>
                                                        <Card title="Columns used in query" style={{ width: 500 }}>
                                                            <ul>
                                                                {elem.columns.map((item: any) =>
                                                                    <li style={{ marginBottom: 10 }}
                                                                        key={item}>{item}</li>)}
                                                            </ul>
                                                        </Card>
                                                    </div>
                                                }
                                            </TabPane>
                                        ))
                                    }
                                </Tabs>
                            </TabPane>
                        ))}
                </Tabs>
            }
        </div>
    )
} 