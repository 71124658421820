/* eslint-disable react-hooks/exhaustive-deps */
import { useJsApiLoader } from '@react-google-maps/api';
import {
  selectInfoWindowOpenValue,
  selectInputValue,
  selectIsAdvancedFiltersDrawerOpenValue,
  selectIsGridViewOpenValue,
  selectIsSelectedResultDrawerOpenValue,
  // selectProjectIdValue,
  // selectShowProjectsTypeValue,
  setAutocompleteInput,
  setCoordsValue,
  setDrawerWidthValue,
  setInfoWindowOpenValue,
  setIsAdvancedFiltersDrawerOpenValue,
  setIsGridViewOpenValue,
  setIsSelectedResultDrawerOpenValue,
  setItem,
  setSelectedItemDetailsOnMap,
  setSelectedSurveyTakerDetails,
  setShowAddressMarkerValue,
  // setShowParcelsTypeValue,
  setZoomValue,
  useGetSurveyContactsQuery,
  useGetUrlParamsFromSurveyQuery,
} from 'app/services';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseSearchString, stringifySearchObject, useDisclosure } from 'utils';
import { AdvancedFilterDrawer } from './AdvancedFilterDrawer';
import { MainFilterDrawer } from './MainFilterDrawer';
import { libraries, NaxxaGoogleMap } from './NaxxaGoogleMap';
import { SelectedResultDrawer } from './SelectedResultDrawer';
import { GridView } from './GridView';
import { ViewMoreCompany } from './ViewMoreCompany';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import SiteLayout from 'components/SiteLayout';
import { getSurveyCriteria } from 'services/surveys.service';
import { BookmarksDrawer } from './BookmarksDrawer';
import { constructFilter } from 'utils/constructFilter';
import { useResetFilters } from 'utils/resetFilters';
import { setSurveyCriteriasHelper } from 'utils/setSurveyCriterias';
import ErrorBoundary from 'components/ErrorBoundary';

export const MapPage = () => {
  const { replace, push } = useHistory();
  const { pathname, search } = useLocation();
  const inputValue = useSelector(selectInputValue);

  const { resetFiltersForViewOnMap } = useResetFilters()

  const isAdvancedFiltersDrawerOpen: boolean = useSelector(selectIsAdvancedFiltersDrawerOpenValue);
  const isInfoWindowOpen: any = useSelector(selectInfoWindowOpenValue)

  useEffect(() => {
    console.log('map init REACT_APP_GOOGLE_KEY', process.env.REACT_APP_GOOGLE_KEY)
  }, [])


  const openInfoWindowOpen = () => {
    dispatch(setInfoWindowOpenValue(true))
  }
  const closeInfoWindowOpen = () => {
    dispatch(setInfoWindowOpenValue(false))
  }

  const { isOpen: isMainFiltersDrawerOpen, toggle: toggleMainFiltersDrawer } =
    useDisclosure(true);

  const openAdvancedFiltersDrawer = () => {
    dispatch(setIsAdvancedFiltersDrawerOpenValue(true))
  }
  const closeAdvancedFiltersDrawer = () => {
    dispatch(setIsAdvancedFiltersDrawerOpenValue(false))
  }

  const isSelectedResultDrawerOpen: boolean = useSelector(selectIsSelectedResultDrawerOpenValue)

  const openSelectedResultDrawer = () => {
    dispatch(setIsSelectedResultDrawerOpenValue(true))
  }
  const closeSelectedResultDrawer = () => {
    dispatch(setIsSelectedResultDrawerOpenValue(false))
    resetFiltersForViewOnMap()
    setIsDrawerOpen(true)
    dispatch(setDrawerWidthValue(1050))
  }

  const isGridViewOpen: any = useSelector(selectIsGridViewOpenValue)

  const openGridView = () => {
    dispatch(setIsGridViewOpenValue(true))
    dispatch(setItem({}))
    dispatch(setInfoWindowOpenValue(false))
    localStorage.removeItem('parentProjectsData')
    window.dispatchEvent(new Event("viewChildProject"));

    localStorage.removeItem('showingChildProjectOnMap')
    window.dispatchEvent(new Event("showingChildProjectOnMap"));
    dispatch(setDrawerWidthValue(1050))
    if (document.getElementsByClassName('drawer-menu-1') && document.getElementsByClassName('drawer-menu-1').length) {
      let element: HTMLElement = document.getElementsByClassName('drawer-menu-1')[0] as HTMLElement;
      element.click();
    }
  }
  const closeGridView = () => {
    dispatch(setIsGridViewOpenValue(false))
    resetFiltersForViewOnMap()
  }

  const {
    isOpen: isBookmarksViewOpen,
    open: openBookmarksView,
    close: closeBookmarkeView,
  } = useDisclosure();

  const closeBookmarks = () => {
    resetFiltersForViewOnMap()
    closeBookmarkeView()
  }

  const {
    isOpen: isViewMoreCompanyOpen,
    // eslint-disable-next-line
    open: openViewMoreCompany,
    close: closeViewMoreCompany,
  } = useDisclosure();

  // const projectId = useSelector(selectProjectIdValue);
  // const [showParcelsType, setShowParcelsType] = useState('all')

  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const dispatch = useDispatch();
  const handleOnViewAllClick = (item: any) => {
    dispatch(setDrawerWidthValue(1050))
    dispatch(setItem(item));
    openSelectedResultDrawer();
    setIsDrawerOpen(false)
  };
  const [surveyid, setSurveyid] = useState('');
  const [criteriaData, setCriteriaData] = useState<any>([])

  // const showProjectsType: any = useSelector(selectShowProjectsTypeValue);


  const loginUser =
    localStorage.getItem('user') &&
    JSON.parse(localStorage.getItem('user') as string);

  // useEffect(() => {
  //   const type: any = localStorage.getItem('showParcelsType')
  //   if(!showProjectsType) {
  //     dispatch(setShowParcelsTypeValue(type))
  //   }
  // }, [projectId])


  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        dispatch(setCoordsValue({ lat: latitude, lng: longitude }))
      },
    );
    if (!localStorage.getItem('isSwitchProfileOn')) {
      localStorage.removeItem('surveyId')
      // localStorage.removeItem('surveyEmail')
    }
  }, []);

  useEffect(() => {
    loginUser.role === 'registered' && push({ pathname: '/home' });
  }, []);

  const onLoad = (autoComplete: google.maps.places.Autocomplete) =>
    setAutocomplete(autoComplete);

  const surveyId = useMemo(() => qs.parse(search).surveyId, [search]);
  const { data: survey, isSuccess } = useGetUrlParamsFromSurveyQuery(
    (surveyId as string) || '',
    { skip: !surveyId },
  );

  const [surveyTakerFromSurvey, setSurveyTakerFromSurvey] = useState('')

  const { data: surveyContacts } =
    useGetSurveyContactsQuery(surveyTakerFromSurvey, {
      skip: !surveyTakerFromSurvey,
    });


  useEffect(() => {
    if (
      typeof surveyContacts !== 'undefined' &&
      surveyContacts?.length > 0 &&
      typeof surveyContacts[0].surveyid !== 'undefined' && surveyTakerFromSurvey
    ) {
      if (surveyTakerFromSurvey) {
        let surveyTakerRecord = surveyContacts.find((elem: any) =>
          elem.value === surveyTakerFromSurvey)
        if (surveyTakerRecord) {
          setSurveyid(surveyTakerRecord?.surveyid)
        }
      } else {
        setSurveyid(surveyContacts[0].surveyid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyContacts, surveyTakerFromSurvey]);


  // let { data: criteriaData } = useGetSurveyCriteriaQuery({
  //   surveyid: surveyid ? surveyid : skipToken,
  // }, { skip: !surveyid });

  const getCriteriaDataFromSurvey = async () => {
    if (surveyid) {
      const res = await getSurveyCriteria(surveyid)
      setCriteriaData(res.data)
    }
  }

  useEffect(() => {
    getCriteriaDataFromSurvey()
  }, [surveyid])


  const styleOfLotsQueryGenerator = (data: any) => {
    const params: any = {};
    //styleoflots
    let styleOfLots: any = [];
    if (data?.numberAndStyleOfLotsPurchased) {
      if (typeof data?.numberAndStyleOfLotsPurchased == 'string') {
        styleOfLots = [
          ...styleOfLots,
          ...data.numberAndStyleOfLotsPurchased.split(';'),
        ];
      } else {
        styleOfLots = [
          ...styleOfLots,
          ...data.numberAndStyleOfLotsPurchased,
        ];
      }
    }
    if (data?.desiredNumberAndStyleOfLots) {
      if (typeof data?.desiredNumberAndStyleOfLots == 'string') {
        styleOfLots = [
          ...styleOfLots,
          ...data.desiredNumberAndStyleOfLots.split(';'),
        ];
      } else {
        styleOfLots = [
          ...styleOfLots,
          ...data.desiredNumberAndStyleOfLots,
        ];
      }
    }
    if (data?.numberAndStyleOfLots) {
      if (typeof data?.numberAndStyleOfLots == 'string') {
        styleOfLots = [
          ...styleOfLots,
          ...data.numberAndStyleOfLots.split(';'),
        ];
      } else {
        styleOfLots = [
          ...styleOfLots,
          ...data.numberAndStyleOfLots,
        ];
      }
    }
    if (data?.developerTags) {
      if (typeof data?.developerTags == 'string') {
        styleOfLots = [
          ...styleOfLots,
          ...data.developerTags.split(';'),
        ];
      } else {
        styleOfLots = [...styleOfLots, ...data.developerTags];
      }
    }
    if (data?.clientDeveloperTags) {
      if (typeof data?.clientDeveloperTags == 'string') {
        styleOfLots = [
          ...styleOfLots,
          ...data.clientDeveloperTags.split(';'),
        ];
      } else {
        styleOfLots = [...styleOfLots, ...data.clientDeveloperTags];
      }
    }
    if (data && styleOfLots?.length) {
      styleOfLots = [...new Set([...styleOfLots])];
      const condos = styleOfLots.filter((elem: any) =>
        elem.includes('Apartments/Condos'),
      );
      if (condos?.length) {
        params.condos = condos;
      }

      const singleFamily = styleOfLots.filter((elem: any) =>
        elem.includes('Single Family'),
      );
      if (singleFamily?.length) {
        params.singleFamily = singleFamily;
      }

      const townhouse = styleOfLots.filter((elem: any) =>
        elem.includes('Townhouse'),
      );
      if (townhouse?.length) {
        params.townhouse = townhouse;
      }

      const land = styleOfLots.filter((elem: any) =>
        elem.includes('Agriculture'),
      );
      if (land?.length) {
        params.land = land;
      }

      const commercial = styleOfLots.filter(
        (elem: any) =>
          !elem.includes('Apartments/Condos') &&
          !elem.includes('Single Family') &&
          !elem.includes('Townhouse') &&
          !elem.includes('Agriculture')
      );
      if (commercial?.length) {
        params.commercial = commercial;
      }
    }
    //styleoflots

    // special Product Types
    // if (data?.specialProductTypes) {
    //   params.specialProductTypes = data.specialProductTypes.split(';')
    // }
    if (data?.specialProductTypes) {
      if (typeof data?.specialProductTypes == 'string') {
        params.specialProductTypes = [
          ...data.specialProductTypes.split(';'),
        ];
      } else {
        params.specialProductTypes = [
          ...data.specialProductTypes,
        ];
      }
    }
    // special Product Types

    // Lot Size
    if (data?.lotSize) {
      params.lotSize = data.lotSize
    }
    // Lot Size

    // utahAreTags
    const utahAreaTags = [];
    if (data && data.utahAreaTags?.length) {
      if (typeof data.utahAreaTags == 'string') {
        const splitted = data.utahAreaTags.split(';');
        utahAreaTags.push(...splitted);
      } else {
        utahAreaTags.push(...data.utahAreaTags);
      }
    }
    if (data && data.utahAreaInvestmentTags?.length) {
      utahAreaTags.push(...data.utahAreaInvestmentTags);
    }
    if (data && data.tempWaterRightUtahAreaTags?.length) {
      utahAreaTags.push(...data.tempWaterRightUtahAreaTags.split(';'));
    }
    params.utahAreaTags = [...new Set([...utahAreaTags])];
    // utahAreTags

    // cities
    if (data && data.propertyLocatedInCity) {
      params.cities = data.propertyLocatedInCity;
    }
    if (data && data.newBuildHomeBuyerDesiredCities) {
      params.cities = data.newBuildHomeBuyerDesiredCities.split(';');
    }
    if (data && data.tempCityOfWaterRight) {
      params.cities = data.tempCityOfWaterRight.split(';');
    }
    if (data && data.city) {
      params.cities = data.city.split(';');
    }
    if (data && data.clientPropertyLocatedInCity) {
      params.cities = data.clientPropertyLocatedInCity;
    }
    // cities

    // type style of home
    const houseStyle = [];
    if (data && data.houseStyle?.length) {
      if (typeof data.houseStyle == 'string') {
        const splitted = data.houseStyle.split(';');
        houseStyle.push(...splitted);
      } else {
        houseStyle.push(...data.houseStyle);
      }
    }
    params.houseStyle = [...new Set([...houseStyle])];
    // type style of home
    console.log('params', params)

    // project status
    if (data && data.projectStatus) {
      params.projectStatus = data.projectStatus
    }
    // project status

    replace({
      pathname,
      search: stringifySearchObject(params),
    });
  };

  const setSurveyCriterias = () => {
    if (criteriaData && criteriaData.length) {
      const surveyNameObj: any = criteriaData.find((elem: any) => elem.criteria === 'surveyname')
      let obj = setSurveyCriteriasHelper(criteriaData)
      localStorage.setItem('profileCriterias', JSON.stringify(obj))
      localStorage.setItem('isSwitchProfileOn', 'true')
      localStorage.setItem('surveyName', surveyNameObj.value)
      localStorage.setItem('surveyEmail', surveyNameObj.email)
      localStorage.setItem('surveyMessage', `Current Profile - ${surveyNameObj.value}`)
      localStorage.setItem('surveyId', surveyid)
      window.dispatchEvent(new Event("storage"));
      styleOfLotsQueryGenerator(obj)
    }
  }

  useEffect(() => {
    if (loginUser.role !== 'registered') {
      // styleOfLotsQueryGenerator(criteriaData);
      setSurveyCriterias()

    }
  }, [criteriaData]);

  useEffect(() => {
    if (localStorage.getItem('isSwitchProfileOn') === 'true') {
      const data: any = localStorage.getItem('profileCriterias')
      if (loginUser.role !== 'registered') {
        styleOfLotsQueryGenerator(JSON.parse(data));
      }
    }
  }, [])

  useEffect(() => {
    if (surveyId && isSuccess) {
      replace({
        pathname,
        search: survey?.url,
      });
    }
  }, [isSuccess, pathname, replace, surveyId, survey]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      const surveyIdFromStorage: any = localStorage.getItem('surveyId')
      if (!surveyIdFromStorage) {
        setSurveyid('')
        // replace({
        //   search: stringifySearchObject({}),
        // });
      } else {
        setSurveyid(surveyIdFromStorage)
      }
    })
    window.addEventListener('resetSearch', () => {
      dispatch(setShowAddressMarkerValue(false))
      dispatch(setAutocompleteInput(''))
    })
  }, [])

  // useEffect(() => {
  //   window.addEventListener('storage', () => {
  //     const surveyIdFromStorage: any = localStorage.getItem('surveyId')
  //     setSurveyid(surveyIdFromStorage)
  //   })
  // },[])

  const onPlaceChanged = useCallback(() => {
    if (!autocomplete || !inputValue) return;
    const place = autocomplete.getPlace();
    if (!place.geometry || !place.geometry.location) return;
    let cityVal = ''
    const addressCompArr = place?.address_components || []
    const cityIndex = addressCompArr.findIndex(x => x?.types && (x.types.includes("locality") && x.types.includes("political")))
    if (cityIndex >= 0) {
      cityVal = addressCompArr[cityIndex].short_name
    }
    const lat = place?.geometry?.location?.lat();
    const lng = place?.geometry?.location?.lng();
    dispatch(setAutocompleteInput(autocomplete.getPlace().formatted_address!));
    dispatch(setCoordsValue({ lat, lng }));

    // setZoom(20);
    dispatch(setZoomValue(20))
    dispatch(setShowAddressMarkerValue(true))
    let url = decodeURIComponent(search);
    let params: any = parseSearchString(url);
    params = {
      ...params,
      addressCoords: `${lat},${lng}`
    }
    if (cityVal) {
      params.addressCoords += `,${cityVal}`
    }

    const contructedFilter = constructFilter(params)
    replace({
      pathname,
      search: stringifySearchObject(contructedFilter),
    });
  }, [autocomplete, inputValue]);

  const { isLoaded } = useJsApiLoader({
    libraries,
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA2COZejZVTyGD_QYldjxuLDM2fFw2ZN64',
  });

  if (!isLoaded) return <h1>Loading</h1>;

  const onSetSurveyid = (id: any) => {
    setSurveyid(id);
  };


  return (
    // <SiteLayout menuShow={false} setSurveyid={onSetSurveyid}>
    <ErrorBoundary>
      <SiteLayout isDrawerOpen={isDrawerOpen} menuShow={true} onSurveyTakerChange={(value) => {
        dispatch(setSelectedItemDetailsOnMap(null))
        dispatch(setSelectedSurveyTakerDetails(null))
        dispatch(setIsSelectedResultDrawerOpenValue(false))
        if (!value || !value?.length) {
          replace({
            pathname,
            search: '',
          });
          onSetSurveyid('')
          setSurveyTakerFromSurvey('')
        }
        if (localStorage.getItem('surveyId')) {
          onSetSurveyid(localStorage.getItem('surveyId'))
        } else {
          value?.length && setSurveyTakerFromSurvey(value[0])
          value?.length && localStorage.setItem('surveyEmail', value)
          window.dispatchEvent(new Event("storage"));
        }
      }}>
        <div id="maplayout">
          <NaxxaGoogleMap
            handleOnViewAllClick={handleOnViewAllClick}
            isInfoWindowOpen={isInfoWindowOpen}
            openInfoWindowOpen={openInfoWindowOpen}
            closeInfoWindowOpen={closeInfoWindowOpen}
            closeSelectedResultDrawer={closeSelectedResultDrawer}
          />
          <MainFilterDrawer
            onPlaceChanged={onPlaceChanged}
            isSelectedResultDrawerOpen={isSelectedResultDrawerOpen}
            onLoad={onLoad}
            isMainFiltersDrawerOpen={isMainFiltersDrawerOpen}
            openAdvancedFiltersDrawer={openAdvancedFiltersDrawer}
            toggleMainFiltersDrawer={toggleMainFiltersDrawer}
            openViewAll={openGridView}
            openBookmarksView={openBookmarksView}
            handleOnViewAllClick={handleOnViewAllClick}
            closeInfoWindowOpen={closeInfoWindowOpen}
            showAllSelectedFilters={undefined}
            onOpenSelectedFilters={function (): void {
              throw new Error('Function not implemented.');
            }} />
          <AdvancedFilterDrawer
            isOpen={isAdvancedFiltersDrawerOpen}
            closeAdvancedFiltersDrawer={closeAdvancedFiltersDrawer}
          />
          <SelectedResultDrawer
            isSelectedResultDrawerOpen={isSelectedResultDrawerOpen}
            openViewMoreCompany={openViewMoreCompany}
            closeSelectedResultDrawer={closeSelectedResultDrawer}
          />

          <GridView
            closeViewAll={closeGridView}
            isViewAllOpen={isGridViewOpen}
          />

          <BookmarksDrawer
            closeViewAll={closeBookmarks}
            isViewAllOpen={isBookmarksViewOpen} />

          <ViewMoreCompany
            // isViewMoreCompanyOpen
            isViewMoreCompanyOpen={isViewMoreCompanyOpen}
            closeViewMoreCompany={closeViewMoreCompany}
          />
        </div>
      </SiteLayout>
    </ErrorBoundary>
  );
};
