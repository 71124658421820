import React, { useEffect } from "react";
import { saveAs } from "file-saver";
import { Button } from "antd";
// eslint-disable-next-line import/no-extraneous-dependencies
import ExcelJS from "exceljs";
import { InformationFromCity, InformationFromOnline, InformationFromOwner } from "utils/parcelsExtraInfo/downloadExcel";

const DownloadQualificationSheet = ({ data }: any) => {

  useEffect(() => {
    console.log('data', data)
  }, [data])

  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Property Information Sheet");
    worksheet.mergeCells(1, 1, 1, 2);
    worksheet.getCell(1, 1).value = "Information Obtained From Owner";
    worksheet.getCell(1, 1).font = { bold: true };
    worksheet.getCell(1, 1).alignment = { horizontal: "center" };

    worksheet.mergeCells(1, 3, 1, 4);
    worksheet.getCell(1, 3).value = "Information Obtained Online";
    worksheet.getCell(1, 3).font = { bold: true };
    worksheet.getCell(1, 3).alignment = { horizontal: "center" };

    worksheet.mergeCells(1, 5, 1, 6);
    worksheet.getCell(1, 5).value = "Information Obtained From City";
    worksheet.getCell(1, 5).font = { bold: true };
    worksheet.getCell(1, 5).alignment = { horizontal: "center" };

    // Add labels and values for each section
    const maxRows = Math.max(
      InformationFromOwner.length,
      InformationFromOnline.length,
      InformationFromCity.length
    );

    for (let i = 0; i < maxRows; i++) {
      const row = worksheet.getRow(i + 2);

      // Owner Section
      const ownerInfo = InformationFromOwner[i];
      if (ownerInfo) {
        row.getCell(1).value = ownerInfo.label;
        row.getCell(2).value = data[ownerInfo.value];
      }

      // Online Section
      const onlineInfo = InformationFromOnline[i];
      if (onlineInfo) {
        row.getCell(3).value = onlineInfo.label;
        row.getCell(4).value = data[onlineInfo.value];
      }

      // City Section
      const cityInfo = InformationFromCity[i];
      if (cityInfo) {
        row.getCell(5).value = cityInfo.label;
        row.getCell(6).value = data[cityInfo.value];
      }
    }


    // Adjust column widths
    worksheet.columns = [
      { width: 30 }, // Column A
      { width: 30 }, // Column B
      { width: 30 }, // Column C
      { width: 30 }, // Column D
      { width: 30 }, // Column E
      { width: 30 }, // Column F
    ];

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, `QS-${data.parcel_id}.xlsx`);
  };


  return <Button type="primary" size="large" onClick={handleDownload}>
    Download
  </Button>;
};

export default DownloadQualificationSheet;
