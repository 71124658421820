import { Button } from "antd";
import React, { Component } from "react";

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    console.error("Error caught in ErrorBoundary:", error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="errror-boundary-container">
          <div>
            <img
              src='Naxxa.png'
              alt='logo'
              style={{ cursor: 'pointer', height: 100, width: 100 }}
            // onClick={() => deleteBookmark(true)}
            />
          </div>
          <div style={{ marginLeft: 50 }}>
            <div style={{ fontSize: 36 }}>404</div>
            <div style={{ fontSize: 18, color: 'gray', marginBottom: 6 }}>Oops! Something went wrong</div>
            <Button type="primary" size="middle" onClick={() => window.location.reload()}>
              Go Back
            </Button>
          </div>

        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
