/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Pagination, Button } from 'antd';
import { selectCoordsPaginationValue, selectDrawerWidthValue, setCoordsPaginationValue, setDrawerWidthValue, setSelectedItemDetailsOnMap, setShowParcelsTypeValue, setShowProjectsTypeValue, setZoomValue, useGetAllParcelBookmarksQuery } from 'app/services';
import {
  Color,
  ResultCard,
} from 'components';

import { useEffect, useMemo, useState } from 'react';
import { ParcelInterface } from 'types/parcels';
import { createMetaData } from 'utils/metaDataHelpers';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { HandleDownload } from 'components/DownloadExcel/HandleDownload';
import DownloadButton from 'components/DownloadExcel/DownloadButton';

export interface ParcelBookmarksProps {
  setParcelsTotal: (total: number) => void;
  setShowingTotal: (total: number) => void;
  setIsCountLoading: (val: boolean) => void;
  closeViewAll: () => void;
}

// ResultsGrid component renders a grid of ResultCard components
const ResultsGrid = ({
  dataSource,
  closeViewAll,
  setCurrentPage
}: {
  dataSource:
  | { type: string; item: ParcelInterface }[]
  | undefined;
  closeViewAll: () => void;
  setCurrentPage?: (val: any) => any;
}) => {

  return (
    <>
      <Row
        style={{ marginBottom: 30 }}
        className="result-content"
        gutter={[
          { xs: 6, sm: 12, md: 16, lg: 24 },
          { xs: 6, sm: 12, md: 16, lg: 24 },
        ]}
      >
        {dataSource &&
          dataSource.map((result, i) => (
            <Col key={i}>
              {/* Display a ResultCard for each dataSource item */}
              <ResultCard
                title={result?.type}
                allData={result}
                titleColor={Color.navy}
                from='bookmark-drawer'
                setCurrentPage={setCurrentPage}
                data={createMetaData(result)}
                closeViewAll={closeViewAll}
                id={result.item?.id}
                parcelId={result.item?.parcelId || result.item?.id_serial}
                idSerial={result.item?.id_serial}
                id_serial={result.item?.id_serial}
                formattedParcelId={result.item?.formattedParcelId}
              />
            </Col>
          ))}
      </Row>
    </>
  );
};

export const ParcelBookmarks = ({
  setParcelsTotal,
  setShowingTotal,
  closeViewAll,
  setIsCountLoading
}: ParcelBookmarksProps) => {
  const drawerWidthValue: any = useSelector(selectDrawerWidthValue)

  const [limit, setLimit] = useState(20); // State for pagination limit
  const [currentPage, setCurrentPage] = useState(1); // State for current page number

  const coordsPagination: any = useSelector(selectCoordsPaginationValue);

  // Effect to sync currentPage state with coordsPagination from Redux
  useEffect(() => {
    setCurrentPage(coordsPagination?.page);
  }, [coordsPagination]);

  // Effect to reset currentPage to 1 when limit changes
  useEffect(() => {
    setCurrentPage(1);
  }, [limit]);


  // Effect to dispatch coords pagination value change when currentPage changes
  useEffect(() => {
    dispatch(setCoordsPaginationValue({ page: currentPage, limit }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const [surveyTaker, setSurveyTaker] = useState<any>(localStorage.getItem('surveyEmail'))

  useEffect(() => {
    window.addEventListener('storage', () => {
      const surveyTakerEmail = localStorage.getItem('surveyEmail')
      setSurveyTaker(surveyTakerEmail)
    })
  }, [])
  // Memoized string for paginated query search
  // const paginatedQuerySearch = useMemo(
  //   () => `?page=${currentPage}&take=${limit}&surveyTaker=${surveyTaker}`,
  //   [limit, currentPage, surveyTaker],
  // );

  const [showingItems, setShowingItems] = useState<
    { type: string; item: ParcelInterface }[]
  >([]);

  // Query for fetching all parcel bookmarks
  const {
    data: parcelsData,
    isFetching: isFetchingParcels,
    isSuccess: isSuccessParcels,
  } = useGetAllParcelBookmarksQuery({ page: currentPage, take: limit, surveyTaker });

  // Effect to update totals when parcelsData changes
  useEffect(() => {
    if (typeof parcelsData?.pagination?.totalItems !== 'undefined') {
      setShowingTotal(parcelsData?.pagination?.totalItems);
      setParcelsTotal(parcelsData?.pagination?.totalItems);
    }
  }, [parcelsData, setShowingTotal]);

  // Memoized listData derived from parcelsData
  const listData = useMemo(() => {
    return parcelsData?.data.map((item: any) => ({ item, type: 'Parcel' })) || [];
  }, [parcelsData]);

  // Effect to update showingItems and isLoading when parcelsData changes
  useEffect(() => {
    if (!isFetchingParcels && isSuccessParcels) {
      // setShowingItems((pv) => [...new Set([...pv, ...(listData || [])])]);
      let allShowingItems
      allShowingItems = [...new Set([...(listData || [])])];
      setShowingItems(allShowingItems)
    }
    setIsCountLoading(isFetchingParcels);
  }, [
    isFetchingParcels,
    isSuccessParcels,
    listData
  ]);
  const dispatch = useDispatch()

  // Function to handle "View all on map" button click
  const viewAllOnMapHandler = () => {
    dispatch(setDrawerWidthValue(680))
    dispatch(setCoordsPaginationValue({ page: currentPage, limit }))
    dispatch(setZoomValue(6))
    dispatch(setShowProjectsTypeValue(null))
    dispatch(setShowParcelsTypeValue('bookmarks'))
    // closeViewAll()
    dispatch(setSelectedItemDetailsOnMap({ viewType: 'Bookmarks', selectedType: 'parcels', totalCount: parcelsData?.pagination?.totalItems }))
  }


  return (
    <div>
      {isFetchingParcels ? (
        // Render skeleton loading indicators while fetching data
        <>
          <Row
            style={{ marginBottom: 30 }}
            className="result-content"
            gutter={[
              { xs: 6, sm: 12, md: 16, lg: 24 },
              { xs: 6, sm: 12, md: 16, lg: 24 },
            ]}
          >
            {Array(7)
              .fill('')
              .map(() => {
                return (
                  <Col key={1} style={{ flex: 1 }}>
                    <Skeleton
                      className="grid-skeleton"
                      height={160}
                      count={1}
                    />
                  </Col>
                );
              })}
          </Row>
          <Row
            style={{ marginBottom: 15 }}
            className="result-content"
            gutter={[
              { xs: 6, sm: 12, md: 16, lg: 24 },
              { xs: 6, sm: 12, md: 16, lg: 24 },
            ]}
          >
            {Array(7)
              .fill('')
              .map(() => {
                return (
                  <Col key={1} style={{ flex: 1 }}>
                    <Skeleton
                      className="grid-skeleton"
                      height={160}
                      count={1}
                    />
                  </Col>
                );
              })}
          </Row>
        </>
      ) :
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 18 }}>
            <div style={{ flexDirection: 'row', alignItems: 'center' }}>
              <DownloadButton type="" onClick={() => HandleDownload(showingItems?.map((elem: any) => elem.item), 'Parcels_Bookmarks')} style={{ marginRight: 8, fontSize: 20, borderRadius: 7 }} items={showingItems} />
              <Button className='title-btn' type="primary" onClick={viewAllOnMapHandler} disabled={!showingItems?.length}>
                View all on map
              </Button>
            </div>
          </div>
          <ResultsGrid
            dataSource={showingItems}
            closeViewAll={closeViewAll}
            setCurrentPage={(val) => {
              setCurrentPage(val);
            }}
          />
          <div className="pagination-container" style={{ width: drawerWidthValue }}>
            {/* Pagination component for navigating through pages */}
            <Pagination
              current={currentPage}
              total={parcelsData?.pagination?.totalItems || 0}
              pageSize={limit}
              showTotal={(total, range) => isFetchingParcels ? 'Loading...' : `${range[0]}-${range[1]} of ${total} items`}
              onShowSizeChange={(current, pageSize) => setLimit(pageSize)}
              onChange={(pg) => setCurrentPage(pg)} />
          </div>
        </div>}

    </div>
  );
};
