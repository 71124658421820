import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'

const DownloadButton = (props: any) => {
   const [isAdmin, setIsAdmin] = useState(false)
   useEffect(() => {
      const userDetails = localStorage.getItem('user')
      if (userDetails && JSON.parse(userDetails)?.role && JSON.parse(userDetails)?.role === 'admin') {
         setIsAdmin(true)
      }
   }, [])
   return (
      <>
         {isAdmin ?
            <Button type={props.type} icon={<DownloadOutlined />} size="large" onClick={props.onClick} style={props.style} disabled={!props.items?.length} />
            : null}
      </>
   )
}

export default DownloadButton