/* eslint-disable react-hooks/exhaustive-deps */
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  StreetViewPanorama,
  InfoWindow,
  Polygon,
  DrawingManager
} from '@react-google-maps/api';
import { Alert, Button, notification, Popover, Spin } from 'antd';
import {
  selectCurrentItem,
  setItem,
  useGetParcelCenterPointQuery,
  useGetParcelGeoDataQuery,
  useGetProjectsParcelGeoDataQuery,
  useGetParcelInfoQuery,
  useGetParentProjectInfoQuery,
  useGetChildProjectGeoDataQuery,
  useGetParcelWithCoordsQuery,
  useGetParentProjectGeoDataQuery,
  useGetParentProjectCenterPointQuery,
  useGetAllParentProjectGeoDataQuery,
  useGetParentProjectCenterQuery,
  selectInputValue,
  selectCoordsValue,
  setCoordsValue,
  setZoomValue,
  selectZoomValue,
  selectShowAddressMarkerValue,
  selectActiveTabValue,
  selectProjectIdValue,
  selectShowParcelsTypeValue,
  selectViewOnMapParcelItemValue,
  selectMapCenterPointValue,
  selectSelectedProjectTypeValue,
  selectInfoWindowPositionValue,
  setInfoWindowPositionValue,
  selectDataTypeValue,
  setDataTypeValue,
  selectShowProjectsTypeValue,
  useGetAllProjectToProjectsMatchingsGeoDataQuery,
  useGetParcelsParcelGeoDataQuery,
  selectParcelIdValue,
  useGetParcelsProjectsGeoDataQuery,
  selectCoordsPaginationValue,
  useGetProjectGeoDataQuery,
  selectProjectsInnerActiveTabValue,
  useGetAllParcelBookmarksCoordinatesQuery,
  useGetAllProjectBookmarksCoordinatesQuery,
  selectSelectedItemDetailsOnMap,
  selectParcelPageCoordsValue,
  selectParentProjectListDataValue,
  selectShowLoaderOnMap,
  setCoordsPaginationValue,
  setSelectedItemDetailsOnMap,
  setIsSelectedResultDrawerOpenValue,
  setShowProjectsTypeValue,
  setShowParcelsTypeValue
} from 'app/services';
import { Color, MatchedItemPopover } from 'components';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ActiveTabType } from 'types/common';
import { LatLngInterface } from 'types/geography';
import { PointType } from 'types/geometry';
import Spinner from 'react-spinkit';
import qs from 'query-string';
import * as turf from '@turf/turf';
import booleanIntersects from '@turf/boolean-intersects';



import { convertPointToGooglePoint } from 'utils/geoHelpers';
import { containerStyle, NaxxaMapOptions } from './settings';
import { parseSearchString, stringifySearchObject } from 'utils';
import { constructFilterFoPolygon } from 'utils/constructFilterForPolygon';
import { joinHypotheticals } from 'utils/joinHypotheticals';
import { getHypotheticalsTitle } from 'utils/getHypotheticalsTitle';
import { AimOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

interface NaxxaGoogleMapProps {
  handleOnViewAllClick: (item: any) => void;
  isInfoWindowOpen: boolean;
  openInfoWindowOpen: () => void;
  closeInfoWindowOpen: () => void;
  closeSelectedResultDrawer?: () => void;
}
export type MarkerType = {
  id: string;
  location: google.maps.LatLngLiteral;
  name: string;
  phone_number: string;
  website: string;
};

interface ResultPolygonProps {
  points: LatLngInterface[];
  color: string;
  id: string;
  parcelId?: string;
  formattedParcelId?: string;
  id_serial?: string;
  polygonHolesArray?: any;
  activeTab: string;
  isHovered: boolean;
  setInfoWindowPosition: (position: LatLngInterface) => void;
  openInfoWindowOpen: () => void;
  setHoveredItemId: (id: string | null) => void;
}

const ResultPolygon = ({
  points,
  polygonHolesArray,
  color,
  id,
  parcelId,
  formattedParcelId,
  id_serial,
  isHovered,
  setHoveredItemId,
  setInfoWindowPosition,
  openInfoWindowOpen,
  activeTab,
}: ResultPolygonProps) => {
  const options: any = {
    strokeColor: color,
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillOpacity: 0.3,
    fillColor: color,
    zIndex: 15,
  };
  if (isHovered) {
    options.strokeColor = 'blue';
    options.zIndex = 10;
  }
  const dispatch = useDispatch();

  const handleOnClickOnPolygon = (event: google.maps.MapMouseEvent) => {
    event.latLng &&
      setInfoWindowPosition({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });

    openInfoWindowOpen();
    dispatch(setItem({
      id, type: 'Parcel', parcelId,
      formattedParcelId, id_serial
    }));
    dispatch(setDataTypeValue(activeTab))
    dispatch(setSelectedItemDetailsOnMap(null))
    dispatch(setIsSelectedResultDrawerOpenValue(false))
  };
  return (
    polygonHolesArray?.length ?
      <Polygon
        onClick={handleOnClickOnPolygon}
        onMouseOver={() => setHoveredItemId(id)}
        onMouseOut={() => setHoveredItemId(null)}
        paths={[points, ...polygonHolesArray]}
        options={options}
      /> : <Polygon
        onClick={handleOnClickOnPolygon}
        onMouseOver={() => setHoveredItemId(id)}
        onMouseOut={() => setHoveredItemId(null)}
        paths={points}
        options={options}
      />

  );
};

export const NaxxaGoogleMap = ({
  handleOnViewAllClick,
  isInfoWindowOpen,
  openInfoWindowOpen,
  closeInfoWindowOpen,
  closeSelectedResultDrawer,
}: NaxxaGoogleMapProps) => {
  const dispatch = useDispatch()
  const mapRef = React.useRef<google.maps.Map | null>(null);
  const zoom: number = useSelector(selectZoomValue);
  const projectId: number | string = useSelector(selectProjectIdValue);
  const selectedParcelId: number | string = useSelector(selectParcelIdValue);
  const activeTab: ActiveTabType = useSelector(selectActiveTabValue);
  const coordsPagination: any = useSelector(selectCoordsPaginationValue);
  const selectedItemDetailsOnMapValue: any = useSelector(selectSelectedItemDetailsOnMap)

  const { search, pathname } = useLocation();
  const getQueryParams = () => {
    // Get the query string from the URL
    const queryString = window.location.search.substring(1);

    // Split the query string into an array of key-value pairs
    const queryParamsArray = queryString.split('&');

    // Initialize an object to store the key-value pairs
    const queryParams: any = {};

    // Loop through the key-value pairs and populate the object
    queryParamsArray.forEach(param => {
      const [key, value] = param.split('=');
      queryParams[key] = decodeURIComponent(value.replace(/\+/g, ' '));
    });

    return queryParams;
  }
  let coordsParsed = null
  let defaultPolygonPaths = null;
  if (search && search.includes('coords')) {
    const searchObj = getQueryParams()
    const coordsStr = searchObj.coords
    coordsParsed = JSON.parse(coordsStr)
    defaultPolygonPaths = [...coordsParsed, coordsParsed[0]].map((elem: any) => {
      return {
        lat: elem[1],
        lng: elem[0]
      }
    })
  }
  const { replace } = useHistory();
  const [drawerRect, setDrawerRect] = useState<any>(null)
  const [coordsArr, setCoordsArr] = useState<any>(coordsParsed || [])
  const [selectedParentProjectId, setSelectedParentProjectId] =
    useState<any>('')
  const [isShowingChildProjectonMap, setIShowingChildProjectonMap] =
    useState<any>(false)
  const [isShowingParentProjectonMap, setIsShowingParentProjectonMap] =
    useState<any>(false)
  const [getParcelCoords, setGetParcelCoords] = useState<any>(null)

  const selectedItem: any = useSelector(selectCurrentItem);
  const showParcelsType: any = useSelector(selectShowParcelsTypeValue);
  const showProjectsType: any = useSelector(selectShowProjectsTypeValue);

  const selectedProjectType = useSelector(selectSelectedProjectTypeValue)

  const coords: google.maps.LatLng | google.maps.LatLngLiteral = useSelector(selectCoordsValue);
  const innerActiveTab: any = useSelector(selectProjectsInnerActiveTabValue);
  const showAddressMarker = useSelector(selectShowAddressMarkerValue);
  const showLoader = useSelector(selectShowLoaderOnMap)


  const setCoordsFn = (value: any) => {
    dispatch(setCoordsValue(value))
  }
  const {
    data: ParcelGeoData,
    isSuccess,
    isFetching,
  } = useGetParcelGeoDataQuery(search ? search + `&activeTab=${activeTab}&page=${coordsPagination?.page}&limit=${coordsPagination?.limit}` : `?activeTab=${activeTab}&page=${coordsPagination?.page}&limit=${coordsPagination?.limit}`, {
    refetchOnReconnect: true,
    skip: activeTab === 'Projects' || selectedItemDetailsOnMapValue?.viewType !== 'Grid view' || !coordsPagination?.page ? true : false,
  });

  const {
    data: ParcelCenterPoint
  } = useGetParcelCenterPointQuery(search ? search + `&activeTab=${activeTab}` : `?activeTab=${activeTab}`, {
    refetchOnReconnect: true,
    skip: activeTab === 'Parcels' || activeTab === 'Both' ? false : true,
  });

  const {
    data: ProjectCenterPoint
  } = useGetParentProjectCenterPointQuery(search ? search + `&parentId=${selectedParentProjectId}` : `?activeTab=${activeTab}&parentId=${selectedParentProjectId}`, {
    refetchOnReconnect: true,
    skip: localStorage.getItem('selectedProjectType') === 'childProject' ? true : activeTab === 'Projects' || activeTab === 'Both' ? false : true,
  });

  const {
    data: ParentProjectCenterPoint
  } = useGetParentProjectCenterQuery(selectedParentProjectId, {
    refetchOnReconnect: true,
    skip: !selectedParentProjectId || localStorage.getItem('selectedProjectType') === 'parentProject',
  });



  const {
    data: ProjectsParcelGeoData,
    isSuccess: isProjectParcelsGeoDataSuccess,
    isFetching: isProjectParcelsGeoDataFetching,
  } = useGetProjectsParcelGeoDataQuery({
    projectId, type: showParcelsType,
    isChild: selectedItem?.parent_project_id || selectedProjectType === 'child' ? true : false,
    page: coordsPagination?.page,
    limit: coordsPagination?.limit,
    isProjectNameSearch: qs.parse(search)?.name || qs.parse(search)?.childName ? true : false
  }, {
    refetchOnReconnect: true,
    skip: !projectId || showProjectsType || !showParcelsType
  });

  const {
    data: ParcelsParcelGeoData,
    isSuccess: isParcelsParcelsGeoDataSuccess,
    isFetching: isParcelsParcelsGeoDataFetching,
  } = useGetParcelsParcelGeoDataQuery({
    parcelId: selectedParcelId,
    page: coordsPagination?.page,
    limit: coordsPagination?.limit
  }, {
    refetchOnReconnect: true,
    skip: !selectedParcelId || !showParcelsType
  });

  const {
    data: ParcelsBookmarksGeoData,
    isSuccess: isParcelsBookmarksGeoDataSuccess,
    isFetching: isParcelsBookmarksGeoDataFetching,
  } = useGetAllParcelBookmarksCoordinatesQuery({
    page: coordsPagination?.page,
    limit: coordsPagination?.limit,
    surveyTaker: localStorage.getItem('surveyEmail')
  }, {
    refetchOnReconnect: true,
    skip: !showParcelsType || showParcelsType !== 'bookmarks'
  });

  const {
    data: ProjectsBookmarksGeoData,
    isSuccess: isProjectsBookmarksGeoDataSuccess,
    isFetching: isProjectsBookmarksGeoDataFetching,
  } = useGetAllProjectBookmarksCoordinatesQuery({
    page: coordsPagination?.page,
    limit: coordsPagination?.limit,
    isChild: innerActiveTab === 'childProject' ? true : false,
    surveyTaker: localStorage.getItem('surveyEmail')
  }, {
    refetchOnReconnect: true,
    skip: activeTab !== 'Projects' || !innerActiveTab || showProjectsType !== 'bookmarks',
  });

  useEffect(() => {
    if (isProjectParcelsGeoDataFetching) {
      window.dispatchEvent(new CustomEvent("isProjectParcelLoadingTrue"));
    } else {
      window.dispatchEvent(new CustomEvent("isProjectParcelLoadingFalse"));
    }

    let msg = "Project's parcels retrieved successfully";
    let description = "The Project's parcels has been retrieved successfully";
    if (showParcelsType === 'buildableParcels') {
      msg = `Project's buildable parcels retrieved successfully`;
      description = `The Project's buildable parcels are retrieved successfully`;
    } else if (showParcelsType === 'alreadyBuildedParcels') {
      msg = `Project's already built parcels retrieved successfully`
      description = `The Project's buildable parcels are retrieved successfully`
    }
    if (!isProjectParcelsGeoDataFetching && ProjectsParcelGeoData?.length) {
      if (ProjectsParcelGeoData[0]?.center?.coordinates) {
        mapRef.current?.setCenter({ lat: ProjectsParcelGeoData[0].center.coordinates[1], lng: ProjectsParcelGeoData[0].center.coordinates[0] })
      }

      notification.success({
        placement: 'topRight',
        message: msg,
        description: description
      })
    }
    if (coordsPagination?.page > 1) {
      setZoomFn(13)
    } else {
      setZoomFn(6)
    }
  }, [isProjectParcelsGeoDataFetching])

  useEffect(() => {
    let msg = "Parcel's matching parcels retrieved successfully";
    let desc = "The Parcel's matching are parcels retrieved successfully"
    if (!isParcelsParcelsGeoDataFetching && ParcelsParcelGeoData?.length) {
      if (ParcelsParcelGeoData[0]?.center?.coordinates) {
        mapRef.current?.setCenter({ lat: ParcelsParcelGeoData[0].center.coordinates[1], lng: ParcelsParcelGeoData[0].center.coordinates[0] })
      }
      notification.success({
        placement: 'topRight',
        message: msg,
        description: desc
      })
    }
    if (coordsPagination?.page > 1) {
      setZoomFn(13)
    } else {
      setZoomFn(6)
    }
  }, [isParcelsParcelsGeoDataFetching])

  useEffect(() => {
    let msg = "Parcels bookmarks coordinates retrieved successfully";
    if (!isParcelsBookmarksGeoDataFetching && ParcelsBookmarksGeoData?.data?.length) {
      notification.success({
        placement: 'topRight',
        message: msg
      })
    }

  }, [isParcelsBookmarksGeoDataFetching])

  useEffect(() => {
    let msg = "Projects bookmarks coordinates retrieved successfully";
    if (!isProjectsBookmarksGeoDataFetching && ProjectsBookmarksGeoData?.data?.length) {
      notification.success({
        placement: 'topRight',
        message: msg
      })
    }
  }, [isProjectsBookmarksGeoDataFetching])

  const searchToObject = (searchVal: string) => {
    const searchParams = new URLSearchParams(searchVal);
    const obj: Record<string, string> = {};

    for (const [key, value] of searchParams.entries()) {
      obj[key] = value;
    }

    return obj;
  }


  const setZoomFn = (val: number) => {
    dispatch(setZoomValue(val))
  }

  useEffect(() => {
    if (isFetching && !drawerRect) {
      // if (coordsPagination?.limit <= 100) {
      //   setZoomFn(4);
      // } else {
      //   setZoomFn(6);
      // }
      setZoomFn(6);

      const searchObj = searchToObject(search)
      if (searchObj && searchObj.addressCoords) {
        const addressArr = searchObj.addressCoords.split(',')
        setCoordsFn({ lat: +addressArr[0], lng: +addressArr[1] });
        setZoomFn(20);
      }
    }
  }, [isFetching, setZoomFn]);

  const {
    data: ProjectGeoData,
    isSuccess: projectSuccess,
    isFetching: projectFetching,
  } = useGetParentProjectGeoDataQuery(search ? search + `&page=${coordsPagination?.page}&limit=${coordsPagination?.limit}` : `?page=${coordsPagination?.page}&limit=${coordsPagination?.limit}`, {
    refetchOnReconnect: true,
    skip: activeTab !== 'Projects' || innerActiveTab !== 'parentProject' || selectedItemDetailsOnMapValue?.viewType !== 'Grid view',
  });

  const {
    data: childProjectsGeoData,
    isSuccess: childProjectsSuccess,
    isFetching: childProjectsFetching,
  } = useGetProjectGeoDataQuery(search ? search + `&page=${coordsPagination?.page}&limit=${coordsPagination?.limit}` : `?page=${coordsPagination?.page}&limit=${coordsPagination?.limit}`, {
    refetchOnReconnect: true,
    skip: activeTab !== 'Projects' || innerActiveTab !== 'childProject' || selectedItemDetailsOnMapValue?.viewType !== 'Grid view',
  });

  const {
    data: ChildProjectGeoData,
    isSuccess: isChildProjectGeoDataSuccess,
    isFetching: isChildProjectGeoDataFetching,
  } = useGetChildProjectGeoDataQuery({ parentId: selectedParentProjectId, page: coordsPagination?.page, limit: coordsPagination?.limit }, {
    refetchOnReconnect: true,
    skip: !selectedParentProjectId || localStorage.getItem('selectedProjectType') === 'childProject'
  }) as { data: any, isSuccess: any, isFetching: any };

  // useEffect(() => {
  //   if (isChildProjectGeoDataFetching && !drawerRect) {
  //     if (coordsPagination?.limit <= 20) {
  //       setZoomFn(4);
  //     } else {
  //       setZoomFn(6);
  //     }
  //   }
  // }, [isChildProjectGeoDataFetching])

  useEffect(() => {
    window.addEventListener('viewChildProject', () => {
      let parentProjectsData: any = localStorage.getItem('parentProjectsData')
      if (parentProjectsData) {
        parentProjectsData = JSON.parse(parentProjectsData)
        setSelectedParentProjectId(parentProjectsData?.id)
      } else {
        setSelectedParentProjectId(null)
      }
    })
  }, [])

  const {
    data: ParentProjectGeoData,
    isSuccess: isParentProjectsGeoDataSuccess,
    isFetching: isParentProjectsGeoDataFetching,
  } = useGetAllParentProjectGeoDataQuery(selectedParentProjectId, {
    refetchOnReconnect: false,
    skip: !selectedParentProjectId
  }) as { data: any, isSuccess: any, isFetching: any };

  const {
    data: projectToProjectsMatchingGeoData,
    isSuccess: isProjectToProjectsMatchingGeoDataSuccess,
    isFetching: isProjectToProjectsMatchingGeoDataFetching,
  } = useGetAllProjectToProjectsMatchingsGeoDataQuery({ projectId, showProjectsType, isChild: selectedProjectType === 'child' ? true : false, page: coordsPagination?.page, limit: coordsPagination?.limit }, {
    refetchOnReconnect: false,
    skip: !projectId || !showProjectsType
  }) as { data: any, isSuccess: any, isFetching: any };

  useEffect(() => {
    let msg = "Project's matching projects retrieved successfully";
    let desc = "The Project's matching projects are retrieved successfully";
    if (!isProjectToProjectsMatchingGeoDataFetching && projectToProjectsMatchingGeoData?.length) {
      notification.success({
        placement: 'topRight',
        message: msg,
        description: desc
      })
      if (projectToProjectsMatchingGeoData[0]?.lat) {
        mapRef.current?.setCenter({ lat: projectToProjectsMatchingGeoData[0]?.lat, lng: projectToProjectsMatchingGeoData[0]?.lng })
      }
    }
    if (coordsPagination?.page > 1) {
      setZoomFn(11)
    } else {
      setZoomFn(6)
    }
  }, [isProjectToProjectsMatchingGeoDataFetching])

  const {
    data: parcelToProjectsMatchingGeoData,
    isSuccess: isParcelToProjectsMatchingGeoDataSuccess,
    isFetching: isParcelToProjectsMatchingGeoDataFetching,
  } = useGetParcelsProjectsGeoDataQuery({ parcelId: selectedParcelId, isChild: selectedProjectType === 'child' ? true : false, page: coordsPagination?.page, limit: coordsPagination?.limit }, {
    refetchOnReconnect: false,
    skip: !selectedParcelId || !selectedProjectType
  }) as { data: any, isSuccess: any, isFetching: any };

  useEffect(() => {
    let msg = "Parcel's matching projects retrieved successfully";
    let desc = "The Parcel's matching projects are retrieved successfully";
    if (!isParcelToProjectsMatchingGeoDataFetching && parcelToProjectsMatchingGeoData?.length) {
      if (parcelToProjectsMatchingGeoData[0]?.lat) {
        mapRef.current?.setCenter({ lat: parcelToProjectsMatchingGeoData[0]?.lat, lng: parcelToProjectsMatchingGeoData[0]?.lng })
      }
      notification.success({
        placement: 'topRight',
        message: msg,
        description: desc
      })
    }
    if (coordsPagination?.page > 1) {
      setZoomFn(11)
    } else {
      setZoomFn(6)
    }
  }, [isParcelToProjectsMatchingGeoDataFetching])


  useEffect(() => {
    window.addEventListener('viewParentProject', () => {
      let childProjectsData: any = localStorage.getItem('childProjectsData')
      if (childProjectsData) {
        childProjectsData = JSON.parse(childProjectsData)
        setSelectedParentProjectId(childProjectsData?.id)
      } else {
        setSelectedParentProjectId(null)
      }
    })
  }, [])

  useEffect(() => {
    window.addEventListener('showingChildProjectOnMap', () => {
      let childProjectShowing: any = localStorage.getItem('showingChildProjectOnMap')
      if (childProjectShowing === 'true') {
        setIShowingChildProjectonMap(true)
        setIsShowingParentProjectonMap(false)

      } else if (childProjectShowing === 'false') {
        localStorage.setItem('selectedProjectType', '')
        setIsShowingParentProjectonMap(true)
      } else {
        setIShowingChildProjectonMap(false)
      }
    })
  }, [])

  useEffect(() => {
    if (projectFetching && !drawerRect) {
      // if (coordsPagination?.limit <= 100) {
      //   setZoomFn(4);
      // } else {
      //   setZoomFn(6);
      // }
      setZoomFn(6);
    }
  }, [projectFetching, setZoomFn]);

  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);
  const dataType: any = useSelector(selectDataTypeValue)
  const [centerPoint, setCenterPoint] = useState<any>()

  useEffect(() => {
    const existingCenter = localStorage.getItem('existingCenter')
    const existingZoom = localStorage.getItem('existingZoom')
    if (existingCenter && existingZoom) {
      localStorage.removeItem('existingCenter')
      localStorage.removeItem('existingZoom')
      return
    }
    if (ParcelCenterPoint?.centeredParcel?.length &&
      ParcelCenterPoint?.centeredParcel[0].center_point?.coordinates) {
      let centeredPoint: any = convertPointToGooglePoint(ParcelCenterPoint?.
        centeredParcel[0].center_point.coordinates)
      setCenterPoint(centeredPoint)
      if (!search && centeredPoint) {
        setCoordsFn(centeredPoint)
      }
    }
  }, [ParcelCenterPoint])

  useEffect(() => {
    if (ProjectCenterPoint?.centeredProject?.length &&
      ProjectCenterPoint?.centeredProject[0].center_point?.coordinates) {
      let centeredPoint: any = convertPointToGooglePoint(ProjectCenterPoint?.
        centeredProject[0].center_point.coordinates)
      setCenterPoint(centeredPoint)
      if (mapRef?.current) {
        mapRef?.current?.setCenter(centeredPoint)
        if (selectedParentProjectId) {
          // mapRef.current.setZoom(16)
          setZoomFn(16);
        }
      }
    }
  }, [ProjectCenterPoint])

  const mapCenterPointValue: any = useSelector(selectMapCenterPointValue)

  useEffect(() => {
    if (mapCenterPointValue && mapRef?.current) {
      mapRef?.current?.setCenter(mapCenterPointValue)
    }
  }, [mapCenterPointValue])

  const onZoomChanged = () => {
    const currentZoom = mapRef?.current?.getZoom()
    if (currentZoom) {
      setZoomFn(currentZoom)
    }
  }


  useEffect(() => {
    if (ParentProjectCenterPoint?.centeredProject?.length &&
      ParentProjectCenterPoint?.centeredProject[0].center_point?.coordinates) {
      let centeredPoint: any = convertPointToGooglePoint(ParentProjectCenterPoint?.
        centeredProject[0].center_point.coordinates)
      setCenterPoint(centeredPoint)
      if (mapRef?.current) {
        mapRef?.current?.setCenter(centeredPoint)
        if (selectedParentProjectId) {
          // mapRef.current.setZoom(16)
          setZoomFn(16)
        }
      }
    }
  }, [ParentProjectCenterPoint])

  const parcelsClusters: any = useMemo(() => {
    return (
      ParcelGeoData &&
      ParcelGeoData.parcels &&
      ParcelGeoData.parcels.length > 0 &&
      ParcelGeoData.parcels.map(({ center }) =>
        convertPointToGooglePoint(center?.coordinates),
      )
    );
  }, [ParcelGeoData]);
  const parentProjectListValue: any = useSelector(selectParentProjectListDataValue)
  const parcelPageCoordsValue: any = useSelector(selectParcelPageCoordsValue)
  const parcelsPageClusters: any = useMemo(() => {
    return parcelPageCoordsValue.map(({ center }: { center: any }) =>
      convertPointToGooglePoint(center?.coordinates),
    );
  }, [parcelPageCoordsValue]);

  useEffect(() => {
    if (mapRef?.current?.getZoom()) {
      mapRef.current.setZoom(mapRef?.current?.getZoom() as number)
    }
  }, [parcelsPageClusters, parentProjectListValue])


  const parcelsPolygons:
    | {
      border: LatLngInterface[];
      id: string;
      parcelId?: any;
      id_serial?: any;
      formattedParcelId?: any;
      polygonHolesArray?: any;
    }[]
    | undefined = useMemo(
      () =>
        ParcelGeoData &&
        ParcelGeoData.parcels.map(
          ({
            points: { coordinates, type },
            id,
            parcelId,
            formattedParcelId,
            id_serial
          }) => {
            if (type === 'MultiPolygon') {
              const intersectingPoints: any = [];
              if (search && search.includes('coords')) {
                const coordsLocal: any = [...coordsArr, coordsArr[0]]
                const polygon = turf.polygon([coordsLocal]);
                coordinates.forEach((polygonCoords: any) => {
                  const poly = turf.polygon(polygonCoords);
                  const intersect = turf.intersect(polygon, poly);
                  if (intersect && booleanIntersects(intersect, poly)) {
                    intersectingPoints.push(intersect);
                  }
                });
              }
              let multiPolygonBorder: any = []
              coordinates?.forEach((elem) => {
                let singleBorder: any = []
                elem[0]?.forEach((item) => {
                  singleBorder.push(convertPointToGooglePoint(item))
                })
                multiPolygonBorder.push(singleBorder)
              })
              return {
                id,
                parcelId,
                formattedParcelId,
                id_serial,
                border: intersectingPoints.length ?
                  intersectingPoints[0].geometry.coordinates.map((point: any) =>
                    convertPointToGooglePoint(point as PointType),
                  ) :
                  multiPolygonBorder,
              };
            }
            const coordsArray: any = []
            if (coordinates?.length) {
              coordinates.slice(0, 1).forEach((elem) => {
                if (elem?.length) {
                  let abc = elem.map((point: any) =>
                    convertPointToGooglePoint(point as PointType),
                  )
                  coordsArray.push(...abc)

                }
              })
            }
            const polygonHolesArray: any = []
            if (coordinates?.length > 1) {
              coordinates.slice(1).forEach((elem) => {
                if (elem?.length) {
                  let abc = elem.map((point: any) =>
                    convertPointToGooglePoint(point as PointType),
                  )
                  polygonHolesArray.push(abc)
                }
              })
            }
            return {
              id,
              parcelId,
              id_serial,
              formattedParcelId,
              border: [...new Set([...coordsArray])],
              polygonHolesArray: polygonHolesArray
            };

          },
        ),
      [ParcelGeoData],
    );
  const parcelsPagePolygons:
    | {
      border: LatLngInterface[];
      id: string;
      parcelId?: any;
      id_serial?: any;
      formattedParcelId?: any;
      polygonHolesArray?: any;
    }[]
    | undefined = useMemo(
      () =>
        parcelPageCoordsValue &&
        parcelPageCoordsValue.map(
          ({
            points: { coordinates, type },
            id,
            parcelId,
            formattedParcelId,
            id_serial
          }: any) => {
            if (type === 'MultiPolygon') {
              const intersectingPoints: any = [];
              let multiPolygonBorder: any = []
              coordinates?.forEach((elem: any) => {
                let singleBorder: any = []
                elem[0]?.forEach((item: any) => {
                  singleBorder.push(convertPointToGooglePoint(item))
                })
                multiPolygonBorder.push(singleBorder)
              })
              return {
                id,
                parcelId,
                formattedParcelId,
                id_serial,
                border: intersectingPoints.length ?
                  intersectingPoints[0].geometry.coordinates.map((point: any) =>
                    convertPointToGooglePoint(point as PointType),
                  ) :
                  multiPolygonBorder,
              };
            }
            const coordsArray: any = []
            if (coordinates?.length) {
              coordinates.slice(0, 1).forEach((elem: any) => {
                if (elem?.length) {
                  let abc = elem.map((point: any) =>
                    convertPointToGooglePoint(point as PointType),
                  )
                  coordsArray.push(...abc)

                }
              })
            }
            const polygonHolesArray: any = []
            if (coordinates?.length > 1) {
              coordinates.slice(1).forEach((elem: any) => {
                if (elem?.length) {
                  let abc = elem.map((point: any) =>
                    convertPointToGooglePoint(point as PointType),
                  )
                  polygonHolesArray.push(abc)
                }
              })
            }
            return {
              id,
              parcelId,
              id_serial,
              formattedParcelId,
              border: [...new Set([...coordsArray])],
              polygonHolesArray: polygonHolesArray
            };

          },
        ),
      [parcelPageCoordsValue],
    );

  const projectsParcelsClusters: any = useMemo(() => {
    return (
      ProjectsParcelGeoData &&
      ProjectsParcelGeoData?.length > 0 &&
      ProjectsParcelGeoData?.map(({ center }: any) =>
        convertPointToGooglePoint(center?.coordinates),
      )
    );
  }, [ProjectsParcelGeoData]);
  const projectsParcelsPolygons:
    | {
      border: LatLngInterface[];
      id: string;
      id_serial?: any;
      parcelId?: any;
      formattedParcelId?: any;
      polygonHolesArray?: any;
      color: string
    }[]
    | undefined = useMemo(
      () =>
        ProjectsParcelGeoData &&
        ProjectsParcelGeoData.map(
          ({
            points: { coordinates, type },
            id,
            parcelId,
            id_serial,
            formattedParcelId,
            possibleNumberAndStyleOfLotsProxy
          }: any) => {
            let projectPolygonCenter: any = localStorage.getItem('projectPolygonCenter')
            if (projectPolygonCenter && mapRef?.current && projectId) {
              projectPolygonCenter = JSON.parse(projectPolygonCenter)
              mapRef?.current?.setCenter(projectPolygonCenter)

            }

            if (type === 'MultiPolygon') {
              const intersectingPoints: any = [];
              if (search && search.includes('coords')) {
                const coordsLocal: any = [...coordsArr, coordsArr[0]]
                const polygon = turf.polygon([coordsLocal]);
                coordinates.forEach((polygonCoords: any) => {
                  const poly = turf.polygon(polygonCoords);
                  const intersect = turf.intersect(polygon, poly);
                  if (intersect && booleanIntersects(intersect, poly)) {
                    intersectingPoints.push(intersect);
                  }
                });
              }
              let multiPolygonBorder: any = []
              coordinates?.forEach((elem: any) => {
                let singleBorder: any = []
                elem[0]?.forEach((item: any) => {
                  singleBorder.push(convertPointToGooglePoint(item))
                })
                multiPolygonBorder.push(singleBorder)
              })
              const intersectingBorder: any = []
              if (intersectingPoints?.length) {
                intersectingPoints[0].geometry.coordinates[0]?.forEach((point: any) =>
                  intersectingBorder.push(convertPointToGooglePoint(point as PointType)))
              }
              return {
                id,
                parcelId,
                id_serial,
                formattedParcelId,
                border: intersectingBorder.length ?
                  intersectingBorder :
                  multiPolygonBorder,
                color: showParcelsType === 'allMatchingParcels' ? Color.green : possibleNumberAndStyleOfLotsProxy ? Color.blue : Color.red
              };
            }
            const coordsArray: any = []
            if (coordinates?.length) {
              coordinates.slice(0, 1).forEach((elem: any) => {
                if (elem?.length) {
                  let abc = elem.map((point: any) =>
                    convertPointToGooglePoint(point as PointType),
                  )
                  coordsArray.push(...abc)

                }
              })
            }
            const polygonHolesArray: any = []
            if (coordinates?.length > 1) {
              coordinates.slice(1).forEach((elem: any) => {
                if (elem?.length) {
                  let abc = elem.map((point: any) =>
                    convertPointToGooglePoint(point as PointType),
                  )
                  polygonHolesArray.push(abc)
                }
              })
            }
            return {
              id,
              parcelId,
              id_serial,
              formattedParcelId,
              border: [...new Set([...coordsArray])],
              polygonHolesArray: polygonHolesArray,
              color: showParcelsType === 'allMatchingParcels' ? Color.green : possibleNumberAndStyleOfLotsProxy ? Color.blue : Color.red
            };
          },
        ),
      [ProjectsParcelGeoData],
    );


  const parcelsParcelsClusters: any = useMemo(() => {
    return (
      ParcelsParcelGeoData &&
      ParcelsParcelGeoData?.length > 0 &&
      ParcelsParcelGeoData?.map(({ center }: any) =>
        convertPointToGooglePoint(center?.coordinates),
      )
    );
  }, [ParcelsParcelGeoData]);
  const parcelsParcelsPolygons:
    | {
      border: LatLngInterface[];
      id: string;
      id_serial?: any;
      parcelId?: any;
      formattedParcelId?: any;
      polygonHolesArray?: any;
      color: string
    }[]
    | undefined = useMemo(
      () =>
        ParcelsParcelGeoData &&
        ParcelsParcelGeoData.map(
          ({
            points: { coordinates, type },
            id,
            parcelId,
            id_serial,
            formattedParcelId,
            possibleNumberAndStyleOfLotsProxy
          }: any) => {
            let projectPolygonCenter: any = localStorage.getItem('projectPolygonCenter')
            if (projectPolygonCenter && mapRef?.current && projectId) {
              projectPolygonCenter = JSON.parse(projectPolygonCenter)
              mapRef?.current?.setCenter(projectPolygonCenter)

            }

            if (type === 'MultiPolygon') {
              const intersectingPoints: any = [];
              if (search && search.includes('coords')) {
                const coordsLocal: any = [...coordsArr, coordsArr[0]]
                const polygon = turf.polygon([coordsLocal]);
                coordinates.forEach((polygonCoords: any) => {
                  const poly = turf.polygon(polygonCoords);
                  const intersect = turf.intersect(polygon, poly);
                  if (intersect && booleanIntersects(intersect, poly)) {
                    intersectingPoints.push(intersect);
                  }
                });
              }
              let multiPolygonBorder: any = []
              coordinates?.forEach((elem: any) => {
                let singleBorder: any = []
                elem[0]?.forEach((item: any) => {
                  singleBorder.push(convertPointToGooglePoint(item))
                })
                multiPolygonBorder.push(singleBorder)
              })
              return {
                id,
                parcelId,
                id_serial,
                formattedParcelId,
                border: intersectingPoints.length ?
                  intersectingPoints[0].geometry.coordinates.map((point: any) =>
                    convertPointToGooglePoint(point as PointType),
                  ) : multiPolygonBorder,
                color: (showParcelsType === 'buildableParcels' || showParcelsType === 'alreadyBuildedParcels') ? possibleNumberAndStyleOfLotsProxy ? Color.blue : Color.red : Color.green
              };
            }
            const coordsArray: any = []
            if (coordinates?.length) {
              coordinates.slice(0, 1).forEach((elem: any) => {
                if (elem?.length) {
                  let abc = elem.map((point: any) =>
                    convertPointToGooglePoint(point as PointType),
                  )
                  coordsArray.push(...abc)

                }
              })
            }
            const polygonHolesArray: any = []
            if (coordinates?.length > 1) {
              coordinates.slice(1).forEach((elem: any) => {
                if (elem?.length) {
                  let abc = elem.map((point: any) =>
                    convertPointToGooglePoint(point as PointType),
                  )
                  polygonHolesArray.push(abc)
                }
              })
            }
            return {
              id,
              parcelId,
              id_serial,
              formattedParcelId,
              border: [...new Set([...coordsArray])],
              polygonHolesArray: polygonHolesArray,
              color: showParcelsType === 'allMatchingParcels' ? Color.green : possibleNumberAndStyleOfLotsProxy ? Color.blue : Color.red
            };
          },
        ),
      [ParcelsParcelGeoData],
    );

  const parcelsBookmarksClusters: any = useMemo(() => {
    return (
      ParcelsBookmarksGeoData &&
      ParcelsBookmarksGeoData?.data &&
      ParcelsBookmarksGeoData?.data.length > 0 &&
      ParcelsBookmarksGeoData?.data.map(({ center }: any) =>
        convertPointToGooglePoint(center?.coordinates),
      )
    );
  }, [ParcelsBookmarksGeoData]);
  const parcelsBookmarksPolygons:
    | {
      border: LatLngInterface[];
      id: string;
      id_serial?: any;
      parcelId?: any;
      formattedParcelId?: any;
      polygonHolesArray?: any;
      color: string
    }[]
    | undefined = useMemo(
      () =>
        ParcelsBookmarksGeoData?.data &&
        ParcelsBookmarksGeoData?.data?.map(
          ({
            points: { coordinates, type },
            id,
            parcelId,
            id_serial,
            formattedParcelId,
            possibleNumberAndStyleOfLotsProxy
          }: any) => {
            let projectPolygonCenter: any = localStorage.getItem('projectPolygonCenter')
            if (projectPolygonCenter && mapRef?.current && projectId) {
              projectPolygonCenter = JSON.parse(projectPolygonCenter)
              mapRef?.current?.setCenter(projectPolygonCenter)

            }

            if (type === 'MultiPolygon') {
              const intersectingPoints: any = [];
              if (search && search.includes('coords')) {
                const coordsLocal: any = [...coordsArr, coordsArr[0]]
                const polygon = turf.polygon([coordsLocal]);
                coordinates.forEach((polygonCoords: any) => {
                  const poly = turf.polygon(polygonCoords);
                  const intersect = turf.intersect(polygon, poly);
                  if (intersect && booleanIntersects(intersect, poly)) {
                    intersectingPoints.push(intersect);
                  }
                });
              }
              let multiPolygonBorder: any = []
              coordinates?.forEach((elem: any) => {
                let singleBorder: any = []
                elem[0]?.forEach((item: any) => {
                  singleBorder.push(convertPointToGooglePoint(item))
                })
                multiPolygonBorder.push(singleBorder)
              })
              return {
                id,
                parcelId,
                id_serial,
                formattedParcelId,
                border: intersectingPoints.length ?
                  intersectingPoints[0].geometry.coordinates.map((point: any) =>
                    convertPointToGooglePoint(point as PointType),
                  ) : multiPolygonBorder,
                color: (showParcelsType === 'buildableParcels' || showParcelsType === 'alreadyBuildedParcels') ? possibleNumberAndStyleOfLotsProxy ? Color.blue : Color.red : Color.green
              };
            }
            const coordsArray: any = []
            if (coordinates?.length) {
              coordinates.slice(0, 1).forEach((elem: any) => {
                if (elem?.length) {
                  let abc = elem.map((point: any) =>
                    convertPointToGooglePoint(point as PointType),
                  )
                  coordsArray.push(...abc)

                }
              })
            }
            const polygonHolesArray: any = []
            if (coordinates?.length > 1) {
              coordinates.slice(1).forEach((elem: any) => {
                if (elem?.length) {
                  let abc = elem.map((point: any) =>
                    convertPointToGooglePoint(point as PointType),
                  )
                  polygonHolesArray.push(abc)
                }
              })
            }
            return {
              id,
              parcelId,
              id_serial,
              formattedParcelId,
              border: [...new Set([...coordsArray])],
              polygonHolesArray: polygonHolesArray,
              color: showParcelsType === 'allMatchingParcels' ? Color.green : possibleNumberAndStyleOfLotsProxy ? Color.blue : Color.red
            };
          },
        ),
      [ParcelsBookmarksGeoData],
    );

  useEffect(() => {
    if (mapRef?.current && parcelsBookmarksPolygons?.length && showParcelsType === 'bookmarks') {
      // mapRef.current.setZoom(17)
      setZoomFn(6)
      mapRef?.current?.setCenter(parcelsBookmarksPolygons[0].border[0])
      setZoomFn(4)
    }
  }, [parcelsBookmarksPolygons])

  const [message, setMessage] = useState('')
  const [popoverDetails, setPopoverDetails] = useState<any>()
  const [surveyTaker, setSurveyTaker] = useState<any>()
  const [showClearBtn, setShowClearBtn] = useState<boolean>(false)
  const [clickedPos, setClickedPos] = React.useState<any>({});
  const [clickedPosData, setClickedPosData] = React.useState<any>({});

  const onLoad = (map: google.maps.Map): void => {
    mapRef.current = map;
  };
  const zoomChange = (): void => {
    // console.log('centerPoint', centerPoint)
    // if (centerPoint && (!selectedItem ||
    //   Object.keys(selectedItem)?.length <= 0)) {
    //   if (mapRef?.current) {
    //     mapRef?.current?.setCenter(centerPoint)
    //   }
    // }
    if (centerPoint) {
      if (mapRef?.current) {
        mapRef?.current?.setCenter(centerPoint)
      }
    }
    // mapRef.current?.getZoom() && setZoom(mapRef.current?.getZoom());
  };

  const onUnMount = (): void => {
    mapRef.current = null;
  };

  const onMapClick = (e: google.maps.MapMouseEvent) => {
    if (!e.latLng) return;
    setClickedPos({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    setGetParcelCoords({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    closeInfoWindowOpen()
  };
  const infoWindowPosition: any = useSelector(selectInfoWindowPositionValue)
  const setInfoWindowPositionFn = (val: any) => {
    dispatch(setInfoWindowPositionValue(val))
  }
  const isPolygonHovered = useCallback(
    (id: string) => {
      return hoveredItemId === id;
    },
    [hoveredItemId],
  );


  const skipSingleParcelQuery = useMemo(() => {
    return !(selectedItem && selectedItem.type === 'Parcel');
  }, [selectedItem]);

  const skipSingleProjectQuery = useMemo(() => {
    return !(selectedItem && (selectedItem.type === 'Project' || selectedItem.type === 'Projects' || selectedItem.type === 'Both'));
  }, [selectedItem]);

  const { data: selectedParcel, isSuccess: isParcelSuccess,
    isFetching: isFetchingParcel,
  } =
    useGetParcelInfoQuery(
      {
        id: selectedItem?.id_serial || '',
        surveyTaker: localStorage.getItem('surveyEmail'),
        hypothetical:
          search && qs.parse(search)?.hypotheticals
            ? qs.parse(search)?.hypotheticals
            : '',
      },
      {
        refetchOnReconnect: true,
        skip: skipSingleParcelQuery,
      },
    );
  const { data: selectedProjects, isSuccess: isProjectSuccess,
    isFetching: isFetchingProject } =
    useGetParentProjectInfoQuery(
      {
        id: selectedItem?.id || '',
        isChild: selectedItem?.parent_project_id || innerActiveTab === 'childProject' ? true : false,
        surveyTaker: localStorage.getItem('surveyEmail'),
      },
      {
        refetchOnReconnect: true,
        skip: skipSingleProjectQuery,
      },
    );


  const { data: selectedParcelWithCoords,
    isFetching: isFetchingParcelWithCoords,
  } =
    useGetParcelWithCoordsQuery(getParcelCoords,
      {
        refetchOnReconnect: true,
        skip: !getParcelCoords,
      },
    );

  useEffect(() => {
    if (!isFetchingParcelWithCoords && selectedParcelWithCoords?.length) {
      setZoomFn(16)
      mapRef?.current?.setCenter({ lat: getParcelCoords?.lat, lng: getParcelCoords?.lng })
      handleOnClickForSelected(getParcelCoords?.lat, getParcelCoords?.lng,
        selectedParcelWithCoords[0].id, selectedParcelWithCoords[0].id_serial, 'Parcel')
    }
  }, [selectedParcelWithCoords, isFetchingParcelWithCoords])
  const infoWindowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    closeInfoWindowOpen();
  }, [activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  // const handleOnBlur = useCallback(
  //   (event) => {
  //     if (
  //       isInfoWindowOpen &&
  //       infoWindowRef.current &&
  //       !infoWindowRef?.current.contains(event.target)
  //     )
  //       closeInfoWindowOpen();
  //   },
  //   [closeInfoWindowOpen, isInfoWindowOpen, infoWindowRef],
  // );

  // useEffect(() => {
  //   window.addEventListener('click', handleOnBlur);

  //   return () => {
  //     window.removeEventListener('click', handleOnBlur);
  //   };
  // }, [handleOnBlur]);
  // useEffect(() => {
  //   setInputValue('')
  // }, [selectedItem])


  const parcelInfoPolygon: any = useMemo(
    () => {
      if (selectedParcel?.points?.type === 'MultiPolygon') {
        const intersectingPoints: any = [];
        if (search && search.includes('coords')) {
          const coordsLocal: any = [...coordsArr, coordsArr[0]]
          const polygon = turf.polygon([coordsLocal]);
          selectedParcel?.points?.coordinates.forEach((polygonCoords: any) => {
            const poly = turf.polygon(polygonCoords);
            const intersect = turf.intersect(polygon, poly);
            if (intersect && booleanIntersects(intersect, poly)) {
              intersectingPoints.push(intersect);
            }
          });
        }
        let multiPolygonBorder: any = []
        selectedParcel?.points?.coordinates?.forEach((elem: any) => {
          let singleBorder: any = []
          elem[0]?.forEach((item: any) => {
            singleBorder.push(convertPointToGooglePoint(item))
          })
          multiPolygonBorder.push(singleBorder)
        })
        return {
          border: intersectingPoints.length ?
            intersectingPoints[0].geometry.coordinates.map((point: any) =>
              convertPointToGooglePoint(point as PointType),
            ) : multiPolygonBorder,
        };
      }
      const coordsArray: any = []
      if (selectedParcel?.points?.coordinates?.length) {
        selectedParcel?.points?.coordinates.slice(0, 1).forEach((elem: any) => {
          if (elem?.length) {
            let abc = elem.map((point: any) =>
              convertPointToGooglePoint(point as PointType),
            )
            coordsArray.push(...abc)

          }
        })
      }
      const polygonHolesArray: any = []
      if (selectedParcel?.points?.coordinates?.length > 1) {
        selectedParcel?.points?.coordinates.slice(1).forEach((elem: any) => {
          if (elem?.length) {
            let abc = elem.map((point: any) =>
              convertPointToGooglePoint(point as PointType),
            )
            polygonHolesArray.push(abc)
          }
        })
      }
      return {
        border: [...new Set([...coordsArray])],
        polygonHolesArray: polygonHolesArray
      };
    },
    [selectedParcel],
  );

  const viewOnMapParcelItem: any = useSelector(selectViewOnMapParcelItemValue);
  useEffect(() => {
    if (mapRef.current && viewOnMapParcelItem) {
      // mapRef.current.setZoom(20)
      setZoomFn(20)
      mapRef?.current?.setCenter(viewOnMapParcelItem)
      setClickedPos({ lat: viewOnMapParcelItem.lat, lng: viewOnMapParcelItem?.lng })
      handleOnClickForSelected(viewOnMapParcelItem.lat, viewOnMapParcelItem?.lng,
        viewOnMapParcelItem.id, viewOnMapParcelItem.id_serial, 'Parcel')
      if (closeSelectedResultDrawer) {
        closeSelectedResultDrawer()
      }
    }
  }, [viewOnMapParcelItem])
  useEffect(() => {
    const viewOnMapParcels: any = localStorage.getItem('showingParcelsOnMap')
    if (mapRef.current && selectedParcel && viewOnMapParcels) {
      // mapRef.current.setZoom(18)
      setZoomFn(18)
      console.log('selectedParcel change', selectedParcel)
      mapRef?.current?.setCenter({
        lat: selectedParcel?.center.coordinates[1],
        lng: selectedParcel?.center.coordinates[0],
      })
      setClickedPosData(selectedParcel)
      setClickedPos({ lat: selectedParcel.center.coordinates[1], lng: selectedParcel?.center.coordinates[0] })
      handleOnClickForSelected(selectedParcel.center.coordinates[1], selectedParcel.center.coordinates[0],
        selectedParcel.id_serial, selectedParcel.id_serial, 'Parcel', null, true, selectedParcel.formattedParcelId)
    }

  }, [selectedParcel])


  useEffect(() => {
    if (typeof selectedItem !== 'undefined' && selectedItem) {
      const selected: any = parcelsPolygons?.filter(
        (src) => src.parcelId === selectedItem.parcelId,
      );

      // const selectedProject: any = projectsPolygons?.filter(
      //   (src: any) => src.id === selectedItem.id,
      // );
      if (
        selected?.length > 0 &&
        selected[0]?.id === selectedItem.id &&
        (activeTab === 'Parcels' || activeTab === 'Both')
      ) {
        dispatch(setDataTypeValue('Parcels'))
        if (selected[0].border?.length) {
          if (Array.isArray(selected[0].border[0]) && selected[0].border[0][0]) {
            setCoordsFn(selected[0].border[0][0]);
          } else {
            setCoordsFn(selected[0].border[0])
          }
          if (mapRef?.current) {
            // mapRef.current.setZoom(17)
            setZoomFn(17)
            if ((Array.isArray(selected[0].border[0]) && selected[0].border[0][0])) {
              mapRef?.current?.setCenter(selected[0].border[0][0])
            } else {
              mapRef?.current?.setCenter(selected[0].border[0])
            }
          }
        }
        // setZoom(17);
        setHoveredItemId(selectedItem.id);
        // setInfoWindowPosition(selected[0].border[0]);
      }
      // onClear()
      // if (drawerRect) {
      //   const localDrawerRect = drawerRect
      //   localDrawerRect.setMap(null);
      // }
      // if (
      //   selectedProject?.length > 0 &&
      //   typeof selectedProject[0].border !== 'undefined' &&
      //   selectedProject[0].border &&
      //   selectedProject[0].border[0] &&
      //   selectedProject[0]?.id === selectedItem.id &&
      //   (activeTab === 'Projects' || activeTab === 'Both')
      // ) {
      //   setDataType('Projects');
      //   setCoords(selectedProject[0].border[0]);
      //   if (!drawerRect) {
      //     setZoom(20);
      //   }
      //   setHoveredItemId(selectedItem.id);
      //   // setInfoWindowPosition(selectedProject[0].border[0]);
      // }
    }
  }, [selectedItem, parcelsPolygons]); // eslint-disable-line react-hooks/exhaustive-deps

  const data = useMemo(() => {
    if (activeTab === 'Projects' && selectedProjects && selectedItem && Object.keys(selectedItem)?.length) {
      if (!drawerRect) {
        setZoomFn(30);
      }
      if (drawerRect) {
        setZoomFn(40);
      }
    }
    const projectData = [
      {
        name: 'Name',
        value: selectedProjects?.project_name_processed?.toString(),
      },
      {
        name: 'Acreage',
        value: selectedProjects?.avgAcreage?.toFixed(2) ||
          selectedProjects?.avg_acreage?.toFixed(2),
      },
      { name: 'City', value: selectedProjects?.city_by_geometry },
    ]
    if (
      selectedProjects?.numberAndStyleOfLots?.length ||
      selectedProjects?.desiredNumberAndStyleOfLots?.length
    ) {
      projectData.push({
        name: selectedProjects?.numberAndStyleOfLots?.length ||
          selectedProjects?.numberandstyleoflots?.length
          ? 'Buildable number and style of lots'
          : selectedProjects?.desiredNumberAndStyleOfLots?.length ||
            selectedProjects?.desirednumberandstyleoflots?.length
            ? 'Desired number and style of lots'
            : '',
        value: selectedProjects?.numberAndStyleOfLots?.length
          ? selectedProjects?.numberAndStyleOfLots.join(', ')
          : selectedProjects?.desiredNumberAndStyleOfLots?.length
            ? selectedProjects?.desiredNumberAndStyleOfLots.join(', ')
            : null,
      });
    } else {
      projectData.push({
        name: 'Buildable number and style of lots',
        value: ''
      })
    }
    return (activeTab === 'Parcels' || activeTab === 'Both' || ((clickedPos?.lat || projectId) && selectedItem?.type !== 'Projects')) &&
      selectedParcel &&
      (dataType === 'Parcels' || dataType === 'Parcel' || ((clickedPos?.lat || projectId) && (selectedItem?.type !== 'Projects' && selectedItem?.type !== 'Project')))
      ? {
        information: {
          title: 'Parcel',
          titleColor: Color.navy,
          allData: selectedParcel.allData,
          id_serial: selectedParcel.id_serial,
          existsInBookmarks: selectedParcel.existsInBookmarks,
          data: [
            {
              name: 'Parcel-Id',
              value: selectedParcel.formattedParcelId,
            },
            {
              // name: 'Number & Style Of Lots',
              name: getHypotheticalsTitle(),
              value: joinHypotheticals(selectedParcel?.
                possibleNumberAndStyleOfLots,
                selectedParcel?.possibleNumberAndStyleOfLotsNeighbors,
                selectedParcel?.possibleNumberAndStyleOfLotsProxy,
                selectedParcel?.possibleNumberAndStyleOfLotsZoning),
            },
            { name: 'Acreage', value: selectedParcel.acreage?.toFixed(2) },
            { name: 'City', value: selectedParcel.city_by_geometry || selectedParcel.city },
            { name: 'County', value: selectedParcel.county_by_geometry || selectedParcel?.county },
          ],
        },
        // matches: Object.entries(selectedParcel?.matches).map(
        //   ([property, { count }]) => ({
        //     property,
        //     total: selectedParcel?.matches[property],
        //   }),
        // ),
      }
      : (activeTab === 'Projects' || activeTab === 'Both') &&
        selectedProjects &&
        (dataType === 'Projects' || dataType === 'Both')
        ? {
          information: {
            title: 'Project',
            titleColor: Color.green,
            id_serial: selectedProjects.id_serial,
            id: selectedProjects.id,
            existsInBookmarks: selectedProjects.existsInBookmarks,
            parcels: selectedProjects.parcels,
            allData: selectedProjects.allData || selectedProjects,
            data: projectData
          },
          // matches: Object.entries(selectedProjects?.matches).map(
          //   ([property, { count }]) => ({
          //     property,
          //     total: count,
          //   }),
          // ),
        }
        : {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParcel, selectedProjects, activeTab, dataType, selectedItem]);

  // useEffect(() => {
  //   onClear()
  // }, [activeTab])

  const onClear = () => {
    const existingCenter = mapRef?.current?.getCenter()
    const existingZoom = mapRef?.current?.getZoom()
    if (existingCenter && existingZoom) {
      localStorage.setItem('existingCenter', JSON.stringify({ lat: existingCenter?.lat(), lng: existingCenter?.lng() }))
      localStorage.setItem('existingZoom', existingZoom.toString())
    }
    if (drawerRect) {
      const localDrawerRect = drawerRect
      localDrawerRect.setMap(null);
    }
    replace({
      pathname,
      search: '',
    });
  };

  const handleRectangleComplete = (polygon: google.maps.Polygon) => {
    setSelectedParentProjectId('')
    setIShowingChildProjectonMap(false)
    dispatch(setShowProjectsTypeValue(null))
    dispatch(setShowParcelsTypeValue(null))
    if (drawerRect) {
      const localDrawerRect = drawerRect
      localDrawerRect.setMap(null);
    }
    polygon.setOptions({
      fillOpacity: 0.0
    })
    setDrawerRect(polygon)
    const paths = polygon.getPath().getArray()
    const mapCoords: any = paths.map((a: any) => [a.lng(), a.lat()]);
    setCoordsArr(mapCoords)
    console.log('search', search)
    let url = decodeURIComponent(search);
    console.log('url', url)
    let params: any = parseSearchString(url);
    params = {
      ...params,
      coords: JSON.stringify(mapCoords)
    }
    console.log('params', params)
    const contructedFilter = constructFilterFoPolygon(params)
    console.log('contructedFilter', contructedFilter)
    replace({
      pathname,
      search: stringifySearchObject(contructedFilter),
    });
  };

  useEffect(() => {
    if (!search || (search && !search.includes('coords'))) {
      if (drawerRect) {
        const localDrawerRect = drawerRect
        localDrawerRect.setMap(null);
      }
    }
  }, [search])

  useEffect(() => {
    if (search && search.includes('coords')) {
      setShowClearBtn(true)
    } else {
      setShowClearBtn(false)
    }
  }, [search])

  useEffect(() => {
    if (selectedProjects?.lat) {
      setTimeout(() => {
        mapRef?.current?.setZoom(20)
        setCoordsFn({
          lat: selectedProjects?.lat,
          lng: selectedProjects?.lng,
        });
      }, 1000)
      if (isShowingChildProjectonMap) {
        handleOnClickForSelected(selectedProjects.lat,
          selectedProjects.lng,
          selectedProjects.id, selectedProjects.id, 'Project', selectedProjects?.parent_project_id)
      } else if (isShowingParentProjectonMap) {
        handleOnClickForSelected(selectedProjects.lat,
          selectedProjects.lng,
          selectedProjects.id, selectedProjects.id, 'Project', selectedProjects?.parent_project_id)
      }
    }
  }, [selectedProjects]);



  const setMessages = () => {
    const surveyMessage = localStorage.getItem('surveyMessage')
    const surveyTakerEmail = localStorage.getItem('surveyEmail')
    setSurveyTaker(surveyTakerEmail)
    setMessage(surveyMessage || '')
    const surveyInnerDetails = localStorage.getItem('surveyInnerDetails')
    if (surveyInnerDetails) {
      setPopoverDetails(JSON.parse(surveyInnerDetails))
    }
  }

  useEffect(() => {
    setMessages()
    window.addEventListener('storage', () => {
      setMessages()
    })
  }, [])

  const content = (
    <div>
      {popoverDetails?.projectName &&
        <p>Project name - {popoverDetails.projectName}</p>}
      {popoverDetails?.acres &&
        <p>Acres - {popoverDetails.acres}</p>}
      {popoverDetails?.address &&
        <p>Address - {popoverDetails.address}</p>}
      {popoverDetails?.city &&
        <p>City - {popoverDetails.city}</p>}
      {popoverDetails?.createdDate &&
        <p>Created Date - {popoverDetails.createdDate}</p>}
    </div>
  );


  const handleOnClick = (event: google.maps.MapMouseEvent, id: any,
    id_serial: any, type: any, parentProjectId?: any, project_name_processed?: any) => {
    console.log('handleOnClick =>>>', id, id_serial, type, project_name_processed)
    setClickedPos({})
    event.latLng &&
      setInfoWindowPositionFn({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    dispatch(setDataTypeValue(activeTab))
    console.log('infowin open 1')
    openInfoWindowOpen();
    dispatch(setItem({ id, type, id_serial, parent_project_id: parentProjectId, project_name_processed }));
  };
  const handleActivePolyOnClick = (event: google.maps.MapMouseEvent) => {
    setClickedPos({})
    event.latLng &&
      setInfoWindowPositionFn({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    dispatch(setDataTypeValue(activeTab))
    openInfoWindowOpen();
  };

  const handleOnClickForSelected = (lat: any, lng: any,
    id: any, id_serial: any, type: any, parentProjectId?: any, ignoreInfoWindow?: boolean, formattedParcelId?: any) => {
    if (!lat && !lng) {
      notification.error({
        placement: 'topRight',
        message: 'Oops!',
        description: 'Latitude and Longitude for the project not found',
        duration: 10,
      });
      return
    }
    setInfoWindowPositionFn({
      lat,
      lng,
    });
    dispatch(setDataTypeValue(activeTab))
    if (id) {
      dispatch(setItem({ id, type, id_serial, parent_project_id: parentProjectId, formattedParcelId }));
    }
    if (!ignoreInfoWindow) {
      openInfoWindowOpen();
    }
  };

  const greenMarkerIcon = {
    url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
    scaledSize: new window.google.maps.Size(40, 40),
  };
  const inputValue = useSelector(selectInputValue);

  const handleViewOnMapPagination = ({ type }: any) => {
    let page
    if (type === 'next') {
      page = coordsPagination?.page + 1
      dispatch(setCoordsPaginationValue({ ...coordsPagination, page }))
    } else if (type === 'prev') {
      page = coordsPagination?.page - 1
      dispatch(setCoordsPaginationValue({ ...coordsPagination, page: coordsPagination?.page }))
    }
    if (selectedItemDetailsOnMapValue?.currentChildProjectsPage) {
      dispatch(setSelectedItemDetailsOnMap({ ...selectedItemDetailsOnMapValue, currentChildProjectsPage: page }))
    }
    if (selectedItemDetailsOnMapValue?.currentPage) {
      dispatch(setSelectedItemDetailsOnMap({ ...selectedItemDetailsOnMapValue, currentPage: page }))
    }
    if (selectedItemDetailsOnMapValue?.currentCompanyPage) {
      dispatch(setSelectedItemDetailsOnMap({ ...selectedItemDetailsOnMapValue, currentCompanyPage: page }))
    }
  }

  return (
    // Important! Always set the container height explicitly

    <div className="google-map-container">
      <Spin
        spinning={showLoader || isFetching || projectFetching || childProjectsFetching
          || isChildProjectGeoDataFetching || isProjectToProjectsMatchingGeoDataFetching || isParcelToProjectsMatchingGeoDataFetching ||
          isFetchingParcelWithCoords ||
          isParentProjectsGeoDataFetching || isProjectParcelsGeoDataFetching || isParcelsParcelsGeoDataFetching || isParcelsBookmarksGeoDataFetching || isProjectsBookmarksGeoDataFetching}
        indicator={
          <Spinner
            name="three-bounce"
            color="#1c7a92"
            style={{ transform: 'rotate(-90deg)' }}
          />
        }
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={NaxxaMapOptions}
          center={coords}
          zoom={zoom}
          onZoomChanged={onZoomChanged}
          onLoad={onLoad}
          onUnmount={onUnMount}
          onClick={onMapClick}
        >
          {message ?
            popoverDetails ?
              <Popover content={content} title="More Info">
                <Alert className='notification' message={
                  <>
                    <div className='alert-text'>{message}</div>
                    {surveyTaker && <div className='alert-text'>Survey Taker - {surveyTaker}</div>}
                  </>
                } type="success">
                </Alert>
              </Popover> : <Alert className='notification' message={
                <>
                  <div className='alert-text'>{message}</div>
                  {surveyTaker && <div className='alert-text'>Survey Taker - {surveyTaker}</div>}
                </>
              } type="success">
              </Alert> : null}
          {
            selectedItemDetailsOnMapValue ? <Alert className='notification' message={
              selectedItemDetailsOnMapValue?.viewType === 'parentOrChildProjects' ?
                <>
                  <div className='alert-text-medium'>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        Selected {selectedItemDetailsOnMapValue?.selectedType}: {selectedItemDetailsOnMapValue?.name}
                      </div>
                      {/* <div role='button' onClick={() => handleOnViewAllClick(
                      selectedItem
                    )} className="view-matches-link">View matches</div> */}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                      {selectedItemDetailsOnMapValue?.matchingType} of {selectedItemDetailsOnMapValue?.name}:
                      <Button style={{ color: '#fff' }} type="text" disabled={coordsPagination?.page === 1} className='view-matches-prev-icon' onClick={() => handleViewOnMapPagination({ type: 'prev' })}>
                        <CaretLeftOutlined />
                      </Button>
                      Page {coordsPagination?.page}
                      <Button style={{ color: '#fff' }} type="text" disabled={Math.ceil(selectedItemDetailsOnMapValue?.totalCount / coordsPagination?.limit) === coordsPagination?.page} className='view-matches-next-icon' onClick={() => handleViewOnMapPagination({ type: 'next' })}>
                        <CaretRightOutlined />
                      </Button>
                    </div>
                  </div>

                </> : selectedItemDetailsOnMapValue?.viewType === 'Bookmarks' || selectedItemDetailsOnMapValue?.viewType === 'Grid view' ? <>
                  <div className='alert-text-medium'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div>
                        {selectedItemDetailsOnMapValue?.viewType} of {selectedItemDetailsOnMapValue?.selectedType}
                      </div>
                      <div>
                        <Button style={{ color: '#fff' }} type="text" disabled={coordsPagination?.page === 1} className='view-matches-prev-icon' onClick={() => handleViewOnMapPagination({ type: 'prev' })}>
                          <CaretLeftOutlined />
                        </Button>
                        Page {coordsPagination?.page}
                        <Button style={{ color: '#fff' }} type="text" disabled={Math.ceil(selectedItemDetailsOnMapValue?.totalCount / coordsPagination?.limit) === coordsPagination?.page} className='view-matches-next-icon' onClick={() => handleViewOnMapPagination({ type: 'next' })}>
                          <CaretRightOutlined />
                        </Button>
                      </div>
                    </div>
                  </div>

                </> : selectedItemDetailsOnMapValue?.viewType === 'Saved search' ? <>
                  <div className='alert-text-medium'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div>
                        {selectedItemDetailsOnMapValue?.viewType}
                      </div>
                      <div>
                        {selectedItemDetailsOnMapValue?.description}
                      </div>
                    </div>
                  </div>

                </> : <>
                  <div className='alert-text-medium'>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        Selected {selectedItemDetailsOnMapValue?.selectedType}: {selectedItemDetailsOnMapValue?.name}
                        <span role='button' onClick={() => handleOnViewAllClick(
                          selectedItem
                        )} className="view-matches-link">View matches</span>
                      </div>
                      {/* <div role='button' onClick={() => handleOnViewAllClick(
                      selectedItem
                    )} className="view-matches-link">View matches</div>*/}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                      {selectedItemDetailsOnMapValue?.matchingType} matches:
                      <Button style={{ color: '#fff' }} type="text" disabled={coordsPagination?.page === 1} className='view-matches-prev-icon' onClick={() => handleViewOnMapPagination({ type: 'prev' })}>
                        <CaretLeftOutlined />
                      </Button>
                      Page {coordsPagination?.page}
                      <Button style={{ color: '#fff' }} type="text" disabled={Math.ceil(selectedItemDetailsOnMapValue?.totalCount / coordsPagination?.limit) === coordsPagination?.page} className='view-matches-next-icon' onClick={() => handleViewOnMapPagination({ type: 'next' })}>
                        <CaretRightOutlined />
                      </Button>
                    </div>
                  </div>

                </>
            } type="success"></Alert> : null
          }
          <div className='clear-button'>
            <DrawingManager
              options={{
                drawingControlOptions: {
                  position: google.maps.ControlPosition.TOP_RIGHT,
                  drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                },
                drawingControl: !showClearBtn,
                drawingMode: null
              }}
              onPolygonComplete={handleRectangleComplete}
            />
            {showClearBtn ?
              <Button
                onClick={onClear}
                style={{ marginRight: "50px", float: "right" }}
              >
                Clear
              </Button> : null}
          </div>
          {selectedProjects && activeTab === 'Projects' && !skipSingleProjectQuery ? <Marker position={coords}
            onClick={(e) =>
              handleOnClickForSelected(selectedProjects.lat || e?.latLng?.lat(),
                selectedProjects.lng || e?.latLng?.lng(),
                selectedProjects.id, selectedProjects.id, 'Project', selectedProjects?.parent_project_id)} />
            : null}
          {clickedPos.lat ?
            <Marker
              onClick={(cordsData) => {
                handleOnClickForSelected(getParcelCoords?.lat || cordsData?.latLng?.lat(),
                  getParcelCoords?.lng || cordsData?.latLng?.lng(),
                  selectedParcelWithCoords ? selectedParcelWithCoords[0].id : clickedPosData ? clickedPosData.id : null,
                  selectedParcelWithCoords ? selectedParcelWithCoords[0].id_serial : clickedPosData ? clickedPosData.id_serial : null, 'Parcel')
              }}
              icon={greenMarkerIcon} position={clickedPos} /> :
            null}
          {(defaultPolygonPaths && search.includes('coords')) && (
            <Polygon
              onClick={onMapClick}
              paths={defaultPolygonPaths}
              options={{
                strokeOpacity: 0.8,
                strokeWeight: 3,
                fillOpacity: 0.0,
                zIndex: 15,
              }}
            />
          )}
          {clickedPos.lat && selectedParcel && !isFetchingParcel
            && parcelInfoPolygon ? <>
            {parcelInfoPolygon?.polygonHolesArray?.length ?
              <Polygon
                onClick={handleActivePolyOnClick}
                paths={[parcelInfoPolygon.border,
                ...parcelInfoPolygon.polygonHolesArray]}
                options={{
                  strokeColor: Color.green,
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                  fillOpacity: 0.3,
                  fillColor: Color.green,
                  zIndex: 15,
                }}
              /> : <Polygon
                onClick={handleActivePolyOnClick}
                paths={parcelInfoPolygon.border}
                options={{
                  strokeColor: Color.green,
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                  fillOpacity: 0.3,
                  fillColor: Color.green,
                  zIndex: 15,
                }}
              />}
          </> : null}
          {inputValue && coords && showAddressMarker &&
            <Marker
              position={coords}
            />}
          {/* {selectedProjects?.allData?.lat && (
            <Marker
              position={{
                lat: selectedProjects.allData.lat,
                lng: selectedProjects.allData.lng,
              }}
            />
          )} */}
          {selectedItemDetailsOnMapValue?.viewType === 'Grid view' && !selectedParentProjectId && !isShowingChildProjectonMap && !showProjectsType && !showParcelsType && projectSuccess && activeTab === 'Projects' && ProjectGeoData?.projects?.length ?
            ProjectGeoData?.projects?.map((elem: any, index: any) => {
              if (elem?.lat && elem?.lng) {
                return (
                  <Marker
                    onClick={(e) => handleOnClick(e, elem.id, elem.id_serial, 'Project', elem.parent_project_id, elem.project_name_processed)}
                    key={elem.id + index}
                    position={{
                      lat: elem?.lat,
                      lng: elem?.lng,
                    }}
                  />
                )
              } else {
                return null;
              }
            })
            : !selectedParentProjectId && !isShowingChildProjectonMap && !showProjectsType && !showParcelsType && activeTab === 'Projects' && projectSuccess && parentProjectListValue?.length ?
              parentProjectListValue.map((elem: any, index: any) => {
                if (elem?.geomCenter?.coordinates) {
                  return (
                    <Marker
                      onClick={(e) => handleOnClick(e, elem.id, elem.id_serial, 'Project', elem.parent_project_id, elem.project_name_processed)}
                      key={elem.id + index}
                      position={{
                        lat: elem?.geomCenter?.coordinates[1],
                        lng: elem?.geomCenter?.coordinates[0],
                      }}
                    />
                  )
                } else {
                  return null;
                }
              })
              : null

          }

          {!selectedParentProjectId && !isShowingChildProjectonMap && showProjectsType === 'bookmarks' && !showParcelsType && isProjectsBookmarksGeoDataSuccess && ProjectsBookmarksGeoData?.data?.length &&
            ProjectsBookmarksGeoData?.data?.map((elem: any, index: any) => {
              if (elem?.lat && elem?.lng) {
                return (
                  <Marker
                    key={elem.id + index}
                    position={{
                      lat: elem?.lat,
                      lng: elem?.lng,
                    }}
                  />
                )
              } else {
                return null;
              }
            })}

          {selectedItemDetailsOnMapValue?.viewType === 'Grid view' && !selectedParentProjectId && !isShowingChildProjectonMap && !showProjectsType && !showParcelsType && activeTab === 'Projects' && childProjectsSuccess && childProjectsGeoData?.projects?.length ?
            childProjectsGeoData?.projects?.map((elem: any, index: any) => {
              if (elem?.lat && elem?.lng) {
                return (
                  <Marker
                    onClick={(e) => handleOnClick(e, elem.id, elem.id_serial, 'Project', elem.parent_project_id, elem.project_name_processed)}
                    key={elem.id + index}
                    position={{
                      lat: elem?.lat,
                      lng: elem?.lng,
                    }}
                  />
                )
              } else {
                return null;
              }
            })
            : !selectedParentProjectId && !isShowingChildProjectonMap && !showProjectsType && !showParcelsType && activeTab === 'Projects' && parentProjectListValue?.length ?
              parentProjectListValue?.map((elem: any, index: any) => {
                if (elem?.geomCenter?.coordinates) {
                  return (
                    <Marker
                      onClick={(e) => handleOnClick(e, elem.id, elem.id_serial, 'Project', elem.parent_project_id, elem.project_name_processed)}
                      key={elem.id + index}
                      position={{
                        lat: elem?.geomCenter?.coordinates[1],
                        lng: elem?.geomCenter?.coordinates[0],
                      }}
                    />
                  )
                } else {
                  return null;
                }
              })
              : null
          }

          {showProjectsType && (showProjectsType === 'parent' || showProjectsType === 'child') && isProjectToProjectsMatchingGeoDataSuccess &&
            projectToProjectsMatchingGeoData?.length &&
            projectToProjectsMatchingGeoData
              .map((elem: any, i: number) => (
                <Marker
                  onClick={(e) =>
                    handleOnClickForSelected(elem.lat || e?.latLng?.lat(),
                      elem.lng || e?.latLng?.lng(),
                      elem.id, elem.id, 'Project', elem?.parent_project_id)}
                  key={i}
                  position={{
                    lat: elem?.lat,
                    lng: elem?.lng,
                  }}
                />
              ))
          }

          {showProjectsType && (showProjectsType === 'parent' || showProjectsType === 'child') && isProjectToProjectsMatchingGeoDataSuccess &&
            projectToProjectsMatchingGeoData?.length && (
              <MarkerClusterer>
                {(clusterer) =>
                  projectToProjectsMatchingGeoData?.map((elem: any, i: number) => (
                    <Marker
                      key={i}
                      position={{
                        lat: elem?.lat,
                        lng: elem?.lng,
                      }}
                      animation={google.maps.Animation.DROP}
                      clusterer={clusterer}
                      visible={false}
                    />
                  ))
                }
              </MarkerClusterer>
            )}

          {selectedParcelId && isParcelToProjectsMatchingGeoDataSuccess &&
            parcelToProjectsMatchingGeoData?.length &&
            parcelToProjectsMatchingGeoData
              .map((elem: any, i: number) => (
                <Marker
                  onClick={(e) =>
                    handleOnClickForSelected(elem.lat || e?.latLng?.lat(),
                      elem.lng || e?.latLng?.lng(),
                      elem.id, elem.id, 'Project', elem?.parent_project_id)}
                  key={i}
                  position={{
                    lat: elem?.lat,
                    lng: elem?.lng,
                  }}
                />
              ))
          }

          {selectedParcelId && isParcelToProjectsMatchingGeoDataSuccess &&
            parcelToProjectsMatchingGeoData?.length && (
              <MarkerClusterer>
                {(clusterer) =>
                  parcelToProjectsMatchingGeoData
                    .map((elem: any, i: number) => (
                      <Marker
                        key={i}
                        position={{
                          lat: elem?.lat,
                          lng: elem?.lng,
                        }}
                        animation={google.maps.Animation.DROP}
                        clusterer={clusterer}
                        visible={false}
                      />
                    ))
                }
              </MarkerClusterer>
            )}


          {selectedParentProjectId && isChildProjectGeoDataSuccess &&
            ChildProjectGeoData?.projects?.length &&
            ChildProjectGeoData?.projects?.map((elem: any, index: any) => {
              if (elem?.lat && elem?.lng) {
                return (
                  <Marker
                    onClick={(e) => handleOnClick(e, elem.id, elem.id_serial, 'Project', elem.parent_project_id, elem.project_name_processed)}
                    key={elem.id + index}
                    position={{
                      lat: elem?.lat,
                      lng: elem?.lng,
                    }}
                  />
                )
              } else {
                return null;
              }
            })}
          {selectedParentProjectId && isChildProjectGeoDataSuccess &&
            ChildProjectGeoData?.projects?.length &&
            ChildProjectGeoData?.projects?.map((elem: any, index: any) => {
              if (elem?.lat && elem?.lng) {
                return (
                  <Marker
                    onClick={(e) => handleOnClick(e, elem.id, elem.id_serial, 'Project', elem.parent_project_id, elem.project_name_processed)}
                    key={elem.id + index}
                    position={{
                      lat: elem?.lat,
                      lng: elem?.lng,
                    }}
                  />
                )
              } else {
                return null;
              }
            })}

          {selectedParentProjectId && isChildProjectGeoDataSuccess &&
            ChildProjectGeoData?.projects?.length && (
              <MarkerClusterer>
                {(clusterer) =>
                  ChildProjectGeoData?.projects
                    .map((elem: any, i: number) => (
                      <Marker
                        key={i}
                        position={{
                          lat: elem?.lat,
                          lng: elem?.lng,
                        }}
                        animation={google.maps.Animation.DROP}
                        clusterer={clusterer}
                        visible={false}
                      />
                    ))
                }
              </MarkerClusterer>
            )}
          {selectedParentProjectId && isParentProjectsGeoDataSuccess &&
            ParentProjectGeoData?.projects?.length &&
            ParentProjectGeoData?.projects?.map((elem: any, index: any) => {
              if (elem?.lat && elem?.lng) {
                return (
                  <Marker
                    onClick={(e) => handleOnClick(e, elem.id, elem.id_serial, 'Project', elem.parent_project_id, elem.project_name_processed)}
                    key={elem.id + index}
                    position={{
                      lat: elem?.lat,
                      lng: elem?.lng,
                    }}
                  />
                )
              } else {
                return null;
              }
            })}

          {selectedItemDetailsOnMapValue?.viewType === 'Grid view' && !selectedProjectType && isSuccess && !isFetching && !showParcelsType && (
            <MarkerClusterer>
              {(clusterer) =>
                parcelsClusters &&
                parcelsClusters.map((location: any, i: number) => (
                  <Marker
                    key={i}
                    position={location}
                    animation={google.maps.Animation.DROP}
                    clusterer={clusterer}
                    visible={false}
                  // icon={{
                  //   path: 'M 946.5 505 L 534.6 93.4 a 31.93 31.93 0 0 0 -45.2 0 L 77.5 505 c -12 12 -18.8 28.3 -18.8 45.3 c 0 35.3 28.7 64 64 64 h 43.4 V 908 c 0 17.7 14.3 32 32 32 H 448 V 716 h 112 v 224 h 265.9 c 17.7 0 32 -14.3 32 -32 V 614.3 h 43.4 c 17 0 33.3 -6.7 45.3 -18.8 c 24.9 -25 24.9 -65.5 -0.1 -90.5 Z',
                  //   fillColor: 'red',
                  //   fillOpacity: 1,
                  //   scale: 0.04,
                  // }}
                  />
                ))
              }
            </MarkerClusterer>
          )}
          {selectedItemDetailsOnMapValue?.viewType !== 'Grid view' && !selectedProjectType && !showParcelsType && activeTab === 'Parcels' && (
            <MarkerClusterer>
              {(clusterer) =>
                parcelsPageClusters &&
                parcelsPageClusters.map((location: any, i: number) => {
                  return (
                    <Marker
                      key={i}
                      position={location}
                      animation={google.maps.Animation.DROP}
                      clusterer={clusterer}
                      visible={false}
                    // icon={{
                    //   path: 'M 946.5 505 L 534.6 93.4 a 31.93 31.93 0 0 0 -45.2 0 L 77.5 505 c -12 12 -18.8 28.3 -18.8 45.3 c 0 35.3 28.7 64 64 64 h 43.4 V 908 c 0 17.7 14.3 32 32 32 H 448 V 716 h 112 v 224 h 265.9 c 17.7 0 32 -14.3 32 -32 V 614.3 h 43.4 c 17 0 33.3 -6.7 45.3 -18.8 c 24.9 -25 24.9 -65.5 -0.1 -90.5 Z',
                    //   fillColor: 'red',
                    //   fillOpacity: 1,
                    //   scale: 0.04,
                    // }}
                    />
                  )
                })
              }
            </MarkerClusterer>
          )}

          {!showProjectsType && showParcelsType === 'bookmarks' && isParcelsBookmarksGeoDataSuccess && !isParcelsBookmarksGeoDataFetching && (
            <MarkerClusterer>
              {(clusterer) =>
                parcelsBookmarksClusters &&
                parcelsBookmarksClusters.map((location: any, i: number) => (
                  <Marker
                    key={i}
                    position={location}
                    animation={google.maps.Animation.DROP}
                    clusterer={clusterer}
                    visible={false}
                  />
                ))
              }
            </MarkerClusterer>
          )}

          {selectedItemDetailsOnMapValue?.viewType === 'Grid view' && activeTab === 'Projects' && !selectedParentProjectId && !showProjectsType && !showParcelsType && projectSuccess && !projectFetching && (
            <MarkerClusterer>
              {(clusterer) =>
                ProjectGeoData && ProjectGeoData?.projects?.length &&
                ProjectGeoData?.projects?.map((elem: any, i: number) => (
                  <Marker
                    key={i}
                    position={{
                      lat: elem?.lat,
                      lng: elem?.lng,
                    }}
                    animation={google.maps.Animation.DROP}
                    clusterer={clusterer}
                    visible={false}
                  />
                ))
              }
            </MarkerClusterer>
          )}
          {selectedItemDetailsOnMapValue?.viewType !== 'Grid view' && activeTab === 'Projects' && !selectedParentProjectId && !showProjectsType && !showParcelsType && parentProjectListValue?.length && (
            <MarkerClusterer>
              {(clusterer) =>
                parentProjectListValue &&
                parentProjectListValue.map((elem: any, i: number) => (
                  <Marker
                    key={i}
                    position={{
                      lat: elem?.geomCenter?.coordinates[1],
                      lng: elem?.geomCenter?.coordinates[0],
                    }}
                    animation={google.maps.Animation.DROP}
                    clusterer={clusterer}
                    visible={false}
                  />
                ))
              }
            </MarkerClusterer>
          )}

          {!selectedParentProjectId && showProjectsType === 'bookmarks' && !showParcelsType && isProjectsBookmarksGeoDataSuccess && !isProjectsBookmarksGeoDataFetching && (
            <MarkerClusterer>
              {(clusterer) =>
                ProjectsBookmarksGeoData && ProjectsBookmarksGeoData?.data?.length &&
                ProjectsBookmarksGeoData?.data?.map((elem: any, i: number) => (
                  <Marker
                    key={i}
                    position={{
                      lat: elem?.lat,
                      lng: elem?.lng,
                    }}
                    animation={google.maps.Animation.DROP}
                    clusterer={clusterer}
                    visible={false}
                  />
                ))
              }
            </MarkerClusterer>
          )}

          {selectedItemDetailsOnMapValue?.viewType === 'Grid view' && activeTab === 'Projects' && !selectedParentProjectId && !showProjectsType && !showParcelsType && childProjectsSuccess && !childProjectsFetching && (
            <MarkerClusterer>
              {(clusterer) =>
                childProjectsGeoData && childProjectsGeoData?.projects?.length &&
                childProjectsGeoData?.projects?.map((elem: any, i: number) => (
                  <Marker
                    key={i}
                    position={{
                      lat: elem?.lat,
                      lng: elem?.lng,
                    }}
                    animation={google.maps.Animation.DROP}
                    clusterer={clusterer}
                    visible={false}
                  />
                ))
              }
            </MarkerClusterer>
          )}
          {/* {selectedItemDetailsOnMapValue?.viewType !== 'Grid view' && !selectedParentProjectId && !showProjectsType && !showParcelsType && parentProjectListValue?.length && (
            <MarkerClusterer>
              {(clusterer) =>
                parentProjectListValue &&
                parentProjectListValue.map((elem: any, i: number) => (
                  <Marker
                    key={i}
                    position={{
                      lat: elem?.geomCenter?.coordinates[1],
                      lng: elem?.geomCenter?.coordinates[0],
                    }}
                    animation={google.maps.Animation.DROP}
                    clusterer={clusterer}
                    visible={false}
                  />
                ))
              }
            </MarkerClusterer>
          )} */}

          {selectedItemDetailsOnMapValue?.viewType === 'Grid view' && !selectedProjectType && isSuccess &&
            !isFetching && !showParcelsType &&
            parcelsPolygons &&
            parcelsPolygons.map(
              ({ border, id, parcelId, formattedParcelId, id_serial,
                polygonHolesArray }, index) => (
                <ResultPolygon
                  key={id + index}
                  points={border}
                  color={Color.green}
                  id={id}
                  parcelId={parcelId}
                  formattedParcelId={formattedParcelId}
                  id_serial={id_serial}
                  isHovered={isPolygonHovered(id)}
                  setInfoWindowPosition={setInfoWindowPositionFn}
                  openInfoWindowOpen={openInfoWindowOpen}
                  setHoveredItemId={setHoveredItemId}
                  activeTab={'Parcel'}
                  polygonHolesArray={polygonHolesArray}
                />
              ),
            )}
          {selectedItemDetailsOnMapValue?.viewType !== 'Grid view' && activeTab === 'Parcels' && !selectedProjectType && !showParcelsType &&
            parcelsPagePolygons &&
            parcelsPagePolygons.map(
              ({ border, id, parcelId, formattedParcelId, id_serial,
                polygonHolesArray }, index) => (
                <ResultPolygon
                  key={id + index}
                  points={border}
                  color={Color.green}
                  id={id}
                  parcelId={parcelId}
                  formattedParcelId={formattedParcelId}
                  id_serial={id_serial}
                  isHovered={isPolygonHovered(id)}
                  setInfoWindowPosition={setInfoWindowPositionFn}
                  openInfoWindowOpen={openInfoWindowOpen}
                  setHoveredItemId={setHoveredItemId}
                  activeTab={'Parcel'}
                  polygonHolesArray={polygonHolesArray}
                />
              ),
            )}

          {isProjectParcelsGeoDataSuccess &&
            !isProjectParcelsGeoDataFetching &&
            projectId &&
            projectsParcelsPolygons &&
            projectsParcelsPolygons.map(
              ({ border, id, parcelId, formattedParcelId, id_serial,
                polygonHolesArray, color }) => (
                <ResultPolygon
                  key={id}
                  points={border}
                  color={color}
                  id={id}
                  id_serial={id_serial}
                  parcelId={parcelId}
                  formattedParcelId={formattedParcelId}
                  isHovered={isPolygonHovered(id)}
                  activeTab={'Parcel'}
                  polygonHolesArray={polygonHolesArray}
                  setInfoWindowPosition={setInfoWindowPositionFn}
                  setHoveredItemId={setHoveredItemId}
                  openInfoWindowOpen={openInfoWindowOpen}
                />
              ),
            )}

          {isProjectParcelsGeoDataSuccess &&
            !isProjectParcelsGeoDataFetching &&
            projectId &&
            projectsParcelsClusters && (
              <MarkerClusterer>
                {(clusterer) =>
                  projectsParcelsClusters &&
                  projectsParcelsClusters.map((location: any, i: number) => (
                    <Marker
                      key={i}
                      position={location}
                      animation={google.maps.Animation.DROP}
                      clusterer={clusterer}
                      visible={false}
                    />
                  ))
                }
              </MarkerClusterer>
            )}

          {!selectedProjectType && isParcelsParcelsGeoDataSuccess &&
            !isParcelsParcelsGeoDataFetching &&
            selectedParcelId &&
            parcelsParcelsPolygons &&
            parcelsParcelsPolygons.map(
              ({ border, id, parcelId, formattedParcelId, id_serial,
                polygonHolesArray, color }) => (
                <ResultPolygon
                  key={id}
                  points={border}
                  color={color}
                  id={id}
                  id_serial={id_serial}
                  parcelId={parcelId}
                  formattedParcelId={formattedParcelId}
                  isHovered={isPolygonHovered(id)}
                  activeTab={'Parcel'}
                  polygonHolesArray={polygonHolesArray}
                  setInfoWindowPosition={setInfoWindowPositionFn}
                  setHoveredItemId={setHoveredItemId}
                  openInfoWindowOpen={openInfoWindowOpen}
                />
              ),
            )}

          {!selectedProjectType && isParcelsParcelsGeoDataSuccess &&
            !isParcelsParcelsGeoDataFetching &&
            selectedParcelId && parcelsParcelsClusters && (
              <MarkerClusterer>
                {(clusterer) =>
                  parcelsParcelsClusters &&
                  parcelsParcelsClusters.map((location: any, i: number) => (
                    <Marker
                      key={i}
                      position={location}
                      animation={google.maps.Animation.DROP}
                      clusterer={clusterer}
                      visible={false}
                    />
                  ))
                }
              </MarkerClusterer>
            )}

          {!selectedProjectType && isParcelsBookmarksGeoDataSuccess &&
            !isParcelsBookmarksGeoDataFetching &&
            showParcelsType === 'bookmarks' &&
            parcelsBookmarksPolygons &&
            parcelsBookmarksPolygons.map(
              ({ border, id, parcelId, formattedParcelId, id_serial,
                polygonHolesArray, color }) => (
                <ResultPolygon
                  key={id}
                  points={border}
                  color={color}
                  id={id}
                  id_serial={id_serial}
                  parcelId={parcelId}
                  formattedParcelId={formattedParcelId}
                  isHovered={isPolygonHovered(id)}
                  activeTab={'Parcel'}
                  polygonHolesArray={polygonHolesArray}
                  setInfoWindowPosition={setInfoWindowPositionFn}
                  setHoveredItemId={setHoveredItemId}
                  openInfoWindowOpen={openInfoWindowOpen}
                />
              ),
            )}



          {isInfoWindowOpen && (
            <InfoWindow
              position={infoWindowPosition}
              onCloseClick={() => closeInfoWindowOpen()}
            >
              <div
                className="naxxa-map-item-info-window"
                ref={isInfoWindowOpen && infoWindowRef}
              >
                <Spin size='large'
                  spinning={isFetchingParcel || isFetchingProject
                    || isProjectParcelsGeoDataFetching}
                >
                  {(isParcelSuccess || isProjectSuccess) ? (
                    <MatchedItemPopover
                      onViewAllClick={() =>
                        handleOnViewAllClick(
                          selectedItem || {
                            type: '',
                            id: '',
                            parcelId: '',
                            formattedParcelId: '',
                          },
                        )
                      }
                      information={data.information}
                      allData={data?.information?.allData}
                    />
                  ) : <div style={{ textAlign: 'center', margin: '0.5rem' }}>
                    <Spin />
                  </div>}
                </Spin>
              </div>
            </InfoWindow>
          )}
          <StreetViewPanorama
            options={{
              addressControlOptions: {
                position: 11.0,
              },
              enableCloseButton: true,
            }}
          />
          <AimOutlined onClick={zoomChange} style={{
            position: 'absolute', padding: '8px', bottom: '200px', right: '9px', fontSize: '30px', backgroundColor: '#fff'
          }} />
        </GoogleMap>
      </Spin>
    </div>
  );
};
