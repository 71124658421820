import { useEffect } from 'react';
import { Button, Checkbox, Form, Input, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { McCleeryLogo } from 'components/Icons';
import { useRegisterMutation } from 'app/services/auth.api';

interface IRegister {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
}

// Signup component definition
export const Signup = () => {
  // State and mutation hooks
  const [register, { data: isRegister, isSuccess, isError, isLoading, error }] =
    useRegisterMutation();
  const { push } = useHistory();

  // Effect to handle success and error notifications
  useEffect(() => {
    if (isRegister && isSuccess) {
      // Notification for successful registration
      notification.success({
        placement: 'topRight',
        message: 'You have successfully created your account.',
      });
      // Redirect to login page after successful registration
      push({ pathname: '/login' });
    }
    if (!isRegister && isError && error) {
      // Notification for registration error
      const errors: any = error;
      notification.error({
        placement: 'topRight',
        message: errors?.data?.message,
      });
    }
  }, [isError, isRegister, isSuccess, push, error]);

  // Function to handle form submission
  const onFinish = async (values: IRegister) => {
    await register(values).unwrap();
  };

  // Component JSX
  return (
    <div className='authentication-container'>
      {/* <img src="/login-bg.jpeg" alt="Background" className="bg-img" /> */}
      <img src="/all-properties-w_animation.gif" className="bg-img" alt="Background Animation" />
      <div className="authentication-form signup-form">
        <div className="authentication-form-container signup-form-container">
        <div className="signup-new-container">
          {/* McCleery logo */}
          <div className="authentication-form-img">
            <McCleeryLogo />
          </div>
          {/* Title */}
          <h2 className="authentication-form-title">Create your account</h2>

          {/* Ant Design Form */}
          <Form
            name="authentication"
            className="form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            {/* First Name input */}
            <Form.Item label="First Name">
              <Form.Item
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: 'Please input your first name!',
                  },
                ]}
              >
                <Input tabIndex={1} />
              </Form.Item>
            </Form.Item>

            {/* Last Name input */}
            <Form.Item label="Last Name">
              <Form.Item
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: 'Please input your last name!',
                  },
                ]}
              >
                <Input tabIndex={2} />
              </Form.Item>
            </Form.Item>

            {/* Email input */}
            <Form.Item label="E-mail">
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'The entered E-mail is not valid E-mail!',
                  },
                ]}
              >
                <Input
                  placeholder="name@email.com"
                  autoComplete="off"
                  tabIndex={3}
                />
              </Form.Item>
            </Form.Item>

            {/* Password input */}
            <Form.Item label="Password">
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password autoComplete="off" tabIndex={4} />
              </Form.Item>
            </Form.Item>

            {/* Confirm Password input */}
            <Form.Item label="Confirm Password">
              <Form.Item
                name="confirmPassword"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!',
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password tabIndex={5} />
              </Form.Item>
            </Form.Item>

            {/* Checkbox for terms and conditions */}
            <Form.Item>
              <Form.Item
                name="agreeToTerms"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                          new Error('Agree to the Terms of Use and Privacy Statement'),
                        ),
                  },
                ]}
              >
                <Checkbox>
                  I agree to the <Link to="/terms-conditions">Terms of Use</Link> and{' '}
                  <Link to="/privacy-statement">Privacy Statement</Link>
                </Checkbox>
              </Form.Item>
            </Form.Item>

            {/* Submit button and link to sign in */}
            <Form.Item>
              <Button
                type="primary"
                loading={isLoading}
                htmlType="submit"
                tabIndex={6}
              >
                Sign Up
              </Button>
              <Link to="/login" className="signup-btn">
                Sign in instead
              </Link>
            </Form.Item>
          </Form>
          {/* <p>By clicking Signup, you agree to our <Link to='/terms-conditions'>Terms and Conditions</Link> and <Link to='/privacy-statement'>Privacy Statement</Link></p> */}
        </div>
      </div>
      </div>
    </div>
  );
};
