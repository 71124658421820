export const InformationFromOwner = [
   { label: 'Contact Owner', value: 'contact_owner' },
   { label: 'Landowner First Name', value: 'landowner_first_name' },
   { label: 'Landowner Last Name', value: 'landowner_last_name' },
   { label: 'Company Name (if property owned in entity)', value: 'company_name' },
   { label: 'Lead Source', value: 'lead_source' },
   { label: 'Property Address (or parcel number)', value: 'property_address' },
   { label: 'Property City', value: 'property_city' },
   { label: 'Property County', value: 'property_county' },
   { label: 'Phone Number', value: 'phone_number' },
   { label: 'Email', value: 'email' },
   { label: 'Property Listed?', value: 'property_listed' },
   { label: 'Other involved parties?', value: 'other_involved_parties' },
   { label: 'If yes, who? And what is their contact info', value: 'other_parties_contact_info' },
   { label: 'Property Acreage', value: 'property_acreage' },
   { label: 'Do you own water rights with the property?', value: 'water_rights' },
   { label: 'If yes, how many acre feet of water?', value: 'acre_feet_of_water' },
   { label: "Would you rather sell the land outright or partner", value: 'sell_or_partner' },
   { label: "If you're selling, how much do you want for the property?", value: 'selling_price' },
   { label: 'Is there a product you would like to see on your land?', value: 'preferred_product' },
   { label: 'Is there any debt owed on the land?', value: 'land_debt' },
   { label: 'If yes, how much? (estimated)', value: 'debt_estimate' },
   { label: 'Are any neighbors interested in developing?', value: 'neighbors_interested' },
   { label: 'If yes, which neighbors and do you have their contact info?', value: 'neighbors_contact_info' },
   { label: 'Due diligence already completed on land (see cell D47)', value: 'due_diligence_completed' },
   { label: 'Do you own other land you would like to sell?', value: 'other_land_to_sell' }
];

export const InformationFromOnline = [
   { label: 'Google Maps Link', value: 'google_maps_link' },
   { label: 'Parcel Map Link', value: 'parcel_map_link' },
   { label: 'Zoning Map Link', value: 'zoning_map_link' },
   { label: 'Zoning Ordinance Link', value: 'zoning_ordinance_link' },
   { label: 'Utility Map Link(s)', value: 'utility_map_links' },
   { label: 'Surrounding Neighborhood', value: 'surrounding_neighborhood' },
   { label: 'Topography *estimated*', value: 'topography_estimated' },
   { label: 'Close to Water?', value: 'topography_estimated' },
   { label: 'Existing Building or Public Use on Property?', value: 'existing_building_public_use' },
   { label: 'Power Lines on Property', value: 'power_lines_on_property' },
   { label: 'Railroad Tracks', value: 'railroad_tracks' },
   { label: 'Boulders/Rock Outcroppings', value: 'boulders_rock_outcroppings' },
   { label: 'Basement Next Door', value: 'basement_next_door' },
   { label: 'Temple Within Quarter Mile', value: 'temple_within_quarter_mile' },
   { label: 'Surrounding Product', value: 'surrounding_product' },
   { label: 'Property Has Access to Road', value: 'property_access_to_road' },
   { label: 'Parcel ID Number', value: 'parcel_id_number' },
   { label: 'Assigned Zoning', value: 'assigned_zoning' },
   { label: 'Allowed Permitted/Conditional Uses Within Zone', value: 'permitted_conditional_uses_within_zone' },
   { label: 'Allowed Permitted/Conditional Uses In Adjacent Zones', value: 'permitted_conditional_uses_adjacent_zones' }
];
export const InformationFromCity = [
   { label: 'Anything preventing project from moving forward', value: 'project_blockers' },
   { label: 'City vision for property', value: 'city_vision_for_property' },
   { label: 'Previous proposed projects on property and their outcomes/reactions (if any)', value: 'previous_proposals_outcomes' },
   { label: 'Incorporated or unincorporated?', value: 'incorporated_status' },
   { label: 'Overlays or restrictions on property?', value: 'property_overlays_restrictions' }
];
