/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import SiteLayout from 'components/SiteLayout';
import { Col, Row, Spin, Button, Typography, Switch, Drawer, Table, Pagination, Popover, notification, Checkbox, Modal, Dropdown, Menu, BackTop, message } from 'antd';
import {
  FormOutlined,
  Loading3QuartersOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { selectSelectedSurveyTakerDetails, useDeleteSurveyCriteriaMutation, useGetCurrentUserQuery, useGetSurveyCriteriasBySurveyIdQuery, useUpdateSurveyCriteriaMutation } from 'app/services';
import { useGetSurveyInfoQuery } from 'app/services';
import { skipToken } from '@reduxjs/toolkit/query';
import { SurvayJson } from './SurvayType';
import Column from 'antd/lib/table/Column';
import type { PaginationProps } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import Navbar from 'components/Navbar';
import { resetSurveyFromLocalStorage } from 'utils/resetSurveyFromStorage';
import UpdateProfile from './UpdateProfile';
import ChangePassword from './ChangePassword';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { getSurveyCriteriasByValue } from 'services/surveys.service';
import TooltipComponent from 'components/Tooltip';
import { useSelector } from 'react-redux';

const { Title } = Typography;

type SurvayJsonType = {
  name: string;
  url: string;
  image: string;
  matchingtype: string;
  title: string;
  surveyname: string;
  devUrl: string;
  retakebutton?: string;
};

const Profile = () => {
  const { data: userInfo, isLoading, refetch } = useGetCurrentUserQuery('');
  const [selectedSurveyId, setSelectedSurveyId] = useState<any>()
  const [selectedSurveyName, setSelectedSurveyName] = useState<any>()
  const [openSurveyDrawer, setOpenSurveyDrawer] = useState(false);
  const [openProfileDrawer, setOpenProfileDrawer] = useState(false);
  const [openPasswordDrawer, setOpenPasswordDrawer] = useState(false);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<any>([])
  const [tablePagination, setTablePagination] = useState<any>()
  const [isFetching, setIsFetching] = useState(false)
  const [selectedSurveyTaker, setSelectedSurveyTaker] = useState<any>('')
  const [deleteProjectOption, setDeleteProjectOption] = useState('')
  const [selectedSurveyForDelete, setSelectedSurveyForDelete] = useState<any>({})
  const selectedSurveyTakerDetails: any = useSelector(selectSelectedSurveyTakerDetails)
  const [userInfoToNavigate, setUserInfoToNavigate] = useState<any>(null)
  const take = 10;

  useEffect(() => {
    let info: any = {}
    if (selectedSurveyTakerDetails?.email) {
      info.firstname = selectedSurveyTakerDetails?.firstname || selectedSurveyTakerDetails?.recommender_first_name || ''
      info.lastname = selectedSurveyTakerDetails?.lastname || selectedSurveyTakerDetails?.recommender_last_name || ''
      info.email = selectedSurveyTakerDetails?.email
    } else {
      info.firstname = userInfo?.firstname || ''
      info.lastname = userInfo?.lastname || ''
      info.email = userInfo?.email
    }
    setUserInfoToNavigate(info)
  }, [selectedSurveyTakerDetails, userInfo])

  const [messageApi, contextHolder] = message.useMessage()

  const { search } = useLocation();

  const { push } = useHistory();

  const loginUser =
    localStorage.getItem('user') &&
    JSON.parse(localStorage.getItem('user') as string);

  useEffect(() => {
    loginUser.role === 'registered' && push({ pathname: '/home' });
  }, [loginUser.role, push]);

  const { data: surveyInfo, isLoading: loadingInfo } = useGetSurveyInfoQuery(
    selectedSurveyTaker ? selectedSurveyTaker :
      userInfo && userInfo.email ? userInfo.email
        : skipToken,
  );

  let { data: surveyCriterias } =
    useGetSurveyCriteriasBySurveyIdQuery({
      surveyid: selectedSurveyId ? selectedSurveyId : skipToken
    }, {
      skip: !selectedSurveyId
    }
    );

  // const {
  //   data: surveyList,
  //   isFetching,
  //   refetch: refetchSurveyCriteria
  // } = useGetSurveyCriteriasByValueQuery({
  //   page, take, value:
  //     selectedSurveyName, role: userInfo?.role,
  //   email: selectedSurveyTaker ? selectedSurveyTaker :
  //     userInfo && userInfo.email ? userInfo.email
  //       : skipToken,
  // }, { skip: !openSurveyDrawer || !selectedSurveyName });

  const onPageChange: PaginationProps['onChange'] = (pageNumber) => {
    setPage(pageNumber);
  };

  const [deleteSurveyCriteria] = useDeleteSurveyCriteriaMutation();
  const [openDeletePopup, setOpenDeletePopup] = useState(false)
  const [openProjectRolePopup, setOpenProjectRolePopup] = useState(false)
  const [projectSelectedRole, setProjectSelectedRole] = useState<any>([])
  const [selectedProject, setSelectedProject] = useState<any>({})


  const onOpenDeletePopup = (item: any) => {
    setSelectedSurveyForDelete(item)
    setOpenDeletePopup(true)
  }
  // const showDeleteConfirm = useCallback(
  //   (item: any) => {
  //     console.log(item)
  //     confirm({
  //       zIndex: 10000,
  //       title: item.value === 'I Have a Project Survey' ?
  //         <>
  //           <h2>Are you sure you want to delete this survey?</h2>
  //           <div style={{ marginTop: 20 }}>
  //             <Dropdown overlayStyle={{ zIndex: 9999999 }} overlay={(
  //               <Menu onClick={(e) => setDeleteProjectOption(e.key)} items={[
  //                 { label: 'Delete Sold Out Status', key: 'Delete Sold Out Status' },
  //                 { label: 'Abandoned', key: 'Abandoned' },
  //                 { label: 'Final Rejected by City Council', key: 'Final Rejected by City Council' },
  //                 { label: 'Prelim Rejected by City Council', key: 'Prelim Rejected by City Council' },
  //               ]} />
  //             )}>
  //               <a onClick={e => e.preventDefault()}>
  //                 {deleteProjectOption}</a>
  //             </Dropdown>
  //           </div>
  //         </>
  //         : 'Are you sure you want to delete this survey?',
  //       icon: <ExclamationCircleOutlined />,
  //       okText: 'Yes',
  //       okType: 'danger',
  //       cancelText: 'No',
  //       onOk() {
  //         deleteSurveyCriteria(item.surveyId).
  //           finally(() => refetchSurveyCriteria());
  //         if (localStorage.getItem('surveyId') === item.surveyId) {
  //           resetSurveyFromLocalStorage()
  //         }
  //       },
  //     });
  //   },
  //   [confirm, deleteSurveyCriteria, refetchSurveyCriteria],
  // );

  const [
    updateCriteria,
    { data: isUpdated, isSuccess, isError, error },
  ] = useUpdateSurveyCriteriaMutation();

  useEffect(() => {
    if (isUpdated && isSuccess) {
      setPage(0)
      setPage(1)
      notification.success({
        placement: 'topRight',
        message: 'Project updated successfully',
      });
      getSurveyCriterias()
    }
    if (!isUpdated && isError && error) {
      const errors: any = error;

      notification.error({
        placement: 'topRight',
        message: errors?.data?.message,
      });
    }
  }, [isError, isUpdated, isSuccess, error]);

  const updatePrivate = async (e: any, item: any) => {
    // const allData = [...tableData]
    // const index = allData.findIndex(elem => elem.surveyid === item.surveyid)
    // allData[index].isPrivate = !allData[index].isPrivate
    // setTableData(allData)
    const data = {
      isPrivate: !e.target.checked,
      surveyid: item.surveyid
    }
    await updateCriteria(data).unwrap();
  }

  const getSurveyCriterias = async () => {
    const email = selectedSurveyTaker ? selectedSurveyTaker :
      userInfo && userInfo.email ? userInfo.email : null
    let res
    if (email && openSurveyDrawer && selectedSurveyName) {
      setIsFetching(true)
      res = await getSurveyCriteriasByValue(page, take,
        selectedSurveyName, email)
      setIsFetching(false)
      if (res && res.data?.data) {
        setTablePagination(res.data.pagination)
        let data: any[] = [];
        Promise.all(
          res.data?.data?.map((item: any) => {
            const options: any = {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            };
            var today = new Date(item.createddate);
            const city = item.allData.find((elem: any) => elem.criteria === 'city')?.value || item.allData.find((elem: any) => elem.criteria === 'propertyLocatedInCity')?.value || item.allData.find((elem: any) => elem.criteria === 'property_located_in_city')?.value || item.allData.find((elem: any) => elem.criteria === 'tempCity')?.value || item.allData.find((elem: any) => elem.criteria === 'tempCityOfWaterRight')?.value
            const projectName = item.allData.find((elem: any) => elem.criteria === 'tempProjectName')?.value
            const address = item.allData.find((elem: any) => elem.criteria === 'address')?.value || item.allData.find((elem: any) => elem.criteria === 'what_is_the_property_address_')?.value || item.allData.find((elem: any) => elem.criteria === 'tempAddress')?.value
            const acres = item.allData.find((elem: any) => elem.criteria === 'how_many_acres_is_your_land_')?.value || item.allData.find((elem: any) => elem.criteria === 'howManyAcreFeetOfWaterRightsSharesDoYouHave')?.value
            return data.push({
              key: item.id,
              city,
              projectName,
              address,
              acres,
              createdDate: item.createddate
                ? today.toLocaleDateString('en-US', options)
                : '',
              surveyid: item.surveyid,
              criteria: item.criteria,
              value: item.value,
              email: item.email,
              isPrivate: item.isPrivate,
              surveyName: item.name
            });
          }),
        );
        setTableData(data)
      }
    }
  }

  useEffect(() => {
    getSurveyCriterias()
  }, [openSurveyDrawer, page, take, selectedSurveyName, selectedSurveyTaker])

  // console.log('surveyList', surveyList)
  // useEffect(() => {
  //   console.log('surveyList>>>>>>>>', surveyList)
  //   if (surveyList && surveyList.data) {
  //     let data: any[] = [];
  //     Promise.all(
  //       surveyList.data.map((item: any) => {
  //         const options: any = {
  //           year: 'numeric',
  //           month: 'long',
  //           day: 'numeric',
  //           hour: 'numeric',
  //           minute: 'numeric',
  //           second: 'numeric',
  //         };
  //         var today = new Date(item.createddate);
  //         const city = item.allData.find((elem: any) => elem.criteria === 'city')?.value || item.allData.find((elem: any) => elem.criteria === 'propertyLocatedInCity')?.value || item.allData.find((elem: any) => elem.criteria === 'property_located_in_city')?.value || item.allData.find((elem: any) => elem.criteria === 'tempCity')?.value || item.allData.find((elem: any) => elem.criteria === 'tempCityOfWaterRight')?.value
  //         const projectName = item.allData.find((elem: any) => elem.criteria === 'tempProjectName')?.value
  //         const address = item.allData.find((elem: any) => elem.criteria === 'address')?.value || item.allData.find((elem: any) => elem.criteria === 'what_is_the_property_address_')?.value || item.allData.find((elem: any) => elem.criteria === 'tempAddress')?.value
  //         const acres = item.allData.find((elem: any) => elem.criteria === 'how_many_acres_is_your_land_')?.value || item.allData.find((elem: any) => elem.criteria === 'howManyAcreFeetOfWaterRightsSharesDoYouHave')?.value
  //         return data.push({
  //           key: item.id,
  //           city,
  //           projectName,
  //           address,
  //           acres,
  //           createdDate: item.createddate
  //             ? today.toLocaleDateString('en-US', options)
  //             : '',
  //           surveyid: item.surveyid,
  //           criteria: item.criteria,
  //           value: item.value,
  //           email: item.email,
  //           isPrivate: item.isPrivate
  //         });
  //       }),
  //     );
  //     setTableData(data)
  //   }
  // }, [surveyList, isFetching]);

  const showSurveyDrawer = () => {
    setOpenSurveyDrawer(true);
  };
  const closeSurveyDrawer = () => {
    setPage(1)
    setOpenSurveyDrawer(false);
  };

  const onSwitchProfile = (item: any, e: any, surveyName: any) => {
    if (e === false) {
      setSelectedSurveyId('')
      // if (selectedSurveyName === 'I Have a Project Survey') {
      //   setSelectedSurveyTaker('')
      // }
      setSelectedSurveyName('')
      resetSurveyFromLocalStorage()
      messageApi.open({
        type: 'info',
        content: `${surveyName || item?.value} is disabled.`,
      })
      return
    }
    setSelectedSurveyTaker(item.email)
    setSelectedSurveyName(item.value)
    localStorage.setItem('surveyName', item.value)
    messageApi.open({
      type: 'success',
      content: `${surveyName || item?.value} is enabled.`,
    })
    if ((item.value === 'I Have a Project Survey' || item.value === 'Land Owner Survey' || item.value === 'Water Right Survey') && !openSurveyDrawer) {
      showSurveyDrawer()
      return
    } else {
      setSelectedSurveyId(item.surveyid)
      localStorage.setItem('surveyId', item.surveyid)
    }
    if ((item.value === 'I Have a Project Survey' || item.value === 'Land Owner Survey' || item.value === 'Water Right Survey') && openSurveyDrawer) {
      localStorage.setItem('surveyInnerDetails', JSON.stringify(item))
      window.dispatchEvent(new Event("storage"));
    }

    setSurveyCriterias()
  }

  const opportunities = (
    <div>
      <p>See matching parcels and projects</p>
    </div>
  );
  // const network = (
  //   <div>
  //     <p>See matching companies and contacts</p>
  //   </div>
  // );

  const setSurveyCriterias = () => {
    if (surveyCriterias && surveyCriterias.length) {
      const surveyNameObj = surveyCriterias.find((elem: any) => elem.criteria === 'surveyname')
      let obj
      if (surveyNameObj.value === 'Builder Company Survey') {
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaTags')?.value?.replace(/{|}|'|"/g, '')
        let numberAndStyleOfLotsPurchased = surveyCriterias.find((elem: any) => elem.criteria === 'numberAndStyleOfLotsPurchased')?.value?.replace(/{|}|'|"/g, '')
        obj = {
          numberAndStyleOfLotsPurchased: numberAndStyleOfLotsPurchased?.split(','),
          utahAreaTags: utahAreaTags?.split(',')
        }
      }
      if (surveyNameObj.value === 'Investor Survey') {
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaInvestmentTags')?.value?.replace(/{|}|'|"/g, '')
        obj = {
          utahAreaTags: utahAreaTags?.split(',')
        }
      }
      if (surveyNameObj.value === 'Developer Company Survey') {
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaTags')?.value?.replace(/{|}|'|"/g, '')
        let developerTags = surveyCriterias.find((elem: any) => elem.criteria === 'developerTags')?.value?.replace(/{|}|'|"/g, '')
        obj = {
          developerTags: developerTags?.split(','),
          utahAreaTags: utahAreaTags?.split(',')
        }
      }
      if (surveyNameObj.value === 'Land Owner Survey') {
        let propertyLocatedInCity = surveyCriterias.find((elem: any) => elem.criteria === 'propertyLocatedInCity')?.value
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaTags')?.value?.replace(/{|}|'|"/g, '')
        obj = {
          propertyLocatedInCity: propertyLocatedInCity?.split(','),
          utahAreaTags: utahAreaTags?.split(',')
        }
      }
      if (surveyNameObj.value === 'New Build Home Buyer Survey') {
        let newBuildHomeBuyerDesiredCities = surveyCriterias.find((elem: any) => elem.criteria === 'newBuildHomeBuyerDesiredCities')?.value || surveyCriterias.find((elem: any) => elem.criteria === 'new_build_home_buyer_desired_cities')?.value
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaTags')?.value?.replace(/{|}|'|"/g, '')
        obj = {
          newBuildHomeBuyerDesiredCities: newBuildHomeBuyerDesiredCities,
          utahAreaTags: utahAreaTags?.split(',') || []
        }
      }
      if (surveyNameObj.value === 'I Have a Project Survey') {
        let projectStatus = surveyCriterias.find((elem: any) => elem.criteria === 'tempProjectDevelopmentStage')?.value
        let city = surveyCriterias.find((elem: any) => elem.criteria === 'tempCity')?.value
        let numberAndStyleOfLotsPurchased = surveyCriterias.find((elem: any) => elem.criteria === 'numberAndStyleOfLots')?.value || surveyCriterias.find((elem: any) => elem.criteria === 'desiredNumberAndStyleOfLots')?.value
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaTags')?.value?.replace(/{|}|'|"/g, '')
        let specialProductTypes = surveyCriterias.find((elem: any) => elem.criteria === 'specialProductTypes')?.value || surveyCriterias.find((elem: any) => elem.criteria === 'desiredSpecialProductTypes')?.value
        let lotSize = surveyCriterias.find((elem: any) => elem.criteria === 'lotSize')?.value || surveyCriterias.find((elem: any) => elem.criteria === 'desiredLotSizes')?.value

        obj = {
          projectStatus,
          city,
          numberAndStyleOfLotsPurchased,
          utahAreaTags,
          specialProductTypes,
          lotSize
        }
      }
      if (surveyNameObj.value === 'Real Estate Agent Client Survey') {
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaTags')?.value?.split(';') || []
        let clientPropertyLocatedInCity = surveyCriterias.find((elem: any) => elem.criteria === 'clientPropertyLocatedInCity')?.value || surveyCriterias.find((elem: any) => elem.criteria === 'client_property_located_in_city')?.value
        let clientUtahAreaInvestmentTags = surveyCriterias.find((elem: any) => elem.criteria === 'clientUtahAreaInvestmentTags')?.value?.split(';') || []
        obj = {
          utahAreaTags: [...utahAreaTags, ...clientUtahAreaInvestmentTags],
          clientPropertyLocatedInCity
        }
      }
      if (surveyNameObj.value === 'Builder Client Survey') {
        let city = surveyCriterias.find((elem: any) => elem.criteria === 'clientPurchaseCity')?.value
        obj = {
          city
        }
      }
      if (surveyNameObj.value === 'Real Estate Agent Survey') {
        let utahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'utahAreaTags')?.value?.replace(/{|}|'|"/g, '')
        obj = {
          utahAreaTags
        }
      }
      if (surveyNameObj.value === 'Water Right Survey') {
        let tempWaterRightUtahAreaTags = surveyCriterias.find((elem: any) => elem.criteria === 'tempWaterRightUtahAreaTags')?.value
        let tempCityOfWaterRight = surveyCriterias.find((elem: any) => elem.criteria === 'tempCityOfWaterRight')?.value
        obj = {
          tempWaterRightUtahAreaTags,
          tempCityOfWaterRight
        }
      }
      localStorage.setItem('profileCriterias', JSON.stringify(obj))
      localStorage.setItem('isSwitchProfileOn', 'true')
      localStorage.setItem('surveyName', surveyNameObj.value)
      localStorage.setItem('surveyEmail', surveyNameObj.email)
      localStorage.setItem('surveyMessage', `Current Profile - ${surveyNameObj.value}`)
      window.dispatchEvent(new Event("storage"));
    }
  }

  useEffect(() => {
    setSurveyCriterias()
  }, [surveyCriterias])

  useEffect(() => {
    const surveyName = localStorage.getItem('surveyName')
    setSelectedSurveyName(surveyName)
    const selectedId = localStorage.getItem('surveyId')
    setSelectedSurveyId(selectedId)
  }, [])

  useEffect(() => {
    refetch();
  }, [refetch]);


  const onSurveyTakerChange = (value: any) => {
    console.log('survey taker change in profile', value)
    if (value?.length) {
      closeSurveyDrawer()
      setPage(1)
      setSelectedSurveyId(null)
      setSelectedSurveyTaker(value[0])
      if (!localStorage.getItem('isSwitchProfileOn')) {
        notification.warning({
          placement: 'topRight',
          message: 'Select a survey from the Question Pro Surveys List below',
        });
      }
    }
  }

  const onClientCodeChange = (value: any) => {
    if (value?.length) {
      setSelectedSurveyId(value[0])
      localStorage.setItem('surveyId', value[0])
    }
  }

  useEffect(() => {
    if (selectedSurveyTaker && (selectedSurveyTaker !== localStorage.getItem('surveyEmail'))) {
      setSelectedSurveyId('')
      // setSelectedSurveyName('')
      resetSurveyFromLocalStorage()
    }
  }, [selectedSurveyTaker])

  useEffect(() => {
    if (!localStorage.getItem('isSwitchProfileOn')) {
      localStorage.removeItem('surveyId')
      localStorage.removeItem('surveyEmail')
    }
  }, [])

  useEffect(() => {
    window.addEventListener('storageUncheck', () => {
      const surveyEmail: any = localStorage.getItem('surveyEmail')
      if (!surveyEmail) {
        setSelectedSurveyTaker('')
        setSelectedSurveyName('')
      }
    })
  }, [])

  useEffect(() => {
    if (search) {
      const surveyTakerFromQuery: any = qs.parse(search)?.surveyTaker
      const selectedSurveyFromQuery = surveyInfo?.find((elem: any) =>
        elem.surveyid
        === qs.parse(search)?.surveyId)
      if (surveyTakerFromQuery && selectedSurveyFromQuery) {
        onSwitchProfile(selectedSurveyFromQuery, true, undefined)
      }
    }
  }, [search, surveyInfo, openSurveyDrawer])

  const onRetakeClick = () => {
    localStorage.removeItem('surveyId')
  }

  return (
    // <SiteLayout menuShow={true} setSurveyid={onSetSurveyid}>
    <SiteLayout menuShow={true} onSurveyTakerChange={onSurveyTakerChange}
      onClientCodeChange={onClientCodeChange}>
      {contextHolder}
      <BackTop />
      <Navbar />
      <Spin
        spinning={isLoading || loadingInfo}
        size="large"
        tip="Loading"
      >
        <div className="profile-area">
          <h1 className="p-title">ACCOUNT INFORMATION</h1>
          <Row gutter={[16, 24]} justify="center">
            <Col className="gutter-row" span={12}>
              <div className="update-btn-group">
                <TooltipComponent
                  title='Update Profile'
                  placement='top'
                  body={<Button
                    type="primary"
                    shape="round"
                    icon={<FormOutlined />}
                    size="middle"
                    onClick={() => setOpenProfileDrawer(true)}
                  >
                    Update Profile
                  </Button>}
                />
                &nbsp; &nbsp;
                <TooltipComponent
                  title='Change Password'
                  placement='top'
                  body={<Button
                    type="primary"
                    shape="round"
                    icon={<LockOutlined />}
                    size="middle"
                    onClick={() => setOpenPasswordDrawer(true)}
                  >
                    Change Password
                  </Button>}
                />
              </div>
              <hr className="hr-tag" />
              <div className="profile-item">
                <span>
                  First Name: <b>{userInfo && userInfo.firstname}</b>
                </span>
              </div>
              <hr className="hr-tag" />
              <div className="profile-item">
                <span>
                  Last Name: <b>{userInfo && userInfo.lastname}</b>
                </span>
              </div>
              <hr className="hr-tag" />

              <div className="profile-item">
                <span>
                  Email Address: <b>{userInfo && userInfo.email}</b>
                </span>
              </div>
              <hr className="hr-tag" />
              <div className="profile-item">
                <span>
                  Account Status:{' '}
                  <TooltipComponent
                    title={userInfo && userInfo.isActive ? 'Account Active' : 'Account Inactive'}
                    placement='top'
                    body={<Button
                      type="primary"
                      shape="round"
                      size="small"
                      danger={userInfo && userInfo.isActive ? false : true}
                    >
                      {userInfo && userInfo.isActive ? 'Active' : 'Inactive'}
                    </Button>}
                  />
                </span>
              </div>
              <hr className="hr-tag" />
            </Col>
          </Row>
          <Title level={3} className="survey-title">
            Displayed Profile - <span className='survey-taker-email-title'>
              {selectedSurveyTaker ?
                selectedSurveyTaker : userInfo?.email}</span>
          </Title>
          <Row gutter={[16, 24]} justify="center">
            <Col className="gutter-row" span={12}>
              <div className="question-surveys">
                {!isLoading &&
                  !loadingInfo &&
                  SurvayJson.map((item: SurvayJsonType, index: number) => {
                    const filterItem =
                      surveyInfo &&
                      surveyInfo.length &&
                      surveyInfo.find(
                        (src: any) => src.value === item.surveyname,
                      );

                    return (
                      <Row justify={{ 'lg': 'start', 'sm': 'center', 'xs': 'center' }} className={filterItem && filterItem.surveyid === selectedSurveyId ? 'question-selected-item' : 'question-item'} key={index}>
                        <Col xxl={12} sm={24} xs={24}>
                          <Row gutter={[6, 6]} style={{ marginBottom: 6 }} justify={{ 'lg': 'start', 'sm': 'center', 'xs': 'center' }}>
                            <Col xxl={0} xl={6} lg={3} md={0} sm={0} xs={0}>
                            </Col>
                            <Col
                              xxl={20}
                              xl={8}
                              lg={14}
                              md={24}
                              sm={24}
                              xs={24}
                            >
                              <h3>{item.name}</h3>
                            </Col>
                            <Col xxl={4} xl={5} lg={3} md={24} sm={24} xs={24} style={{ textAlign: 'center' }}>
                              {filterItem ?
                                <>
                                  <TooltipComponent
                                    title={(selectedSurveyName
                                      === item.surveyname) ? `${item.name} is enabled` : `${item.name} is disabled`}
                                    placement='top'
                                    body={<Switch
                                      checkedChildren="ON" unCheckedChildren="OFF"
                                      disabled={selectedSurveyName &&
                                        selectedSurveyName !== item.surveyname}
                                      checked={((selectedSurveyName
                                        === item.surveyname) ? true : false)}
                                      onChange={(e) =>
                                        onSwitchProfile(filterItem, e, item.name)} />}
                                  />
                                </>
                                : null}
                            </Col>
                            <Col xxl={0} xl={7} lg={3} md={0} sm={0} xs={0}>
                            </Col>
                          </Row>
                        </Col>

                        {/* <Link to="/network">
                          <Popover content={network} title="Network" trigger="hover">
                            {' '}
                          </Popover>
                        </Link> */}
                        <Col xxl={12} sm={24} xs={24}>
                          <Row gutter={[6, 6]} justify="center">
                            <Col >
                              <Link to="/map">
                                <Popover content={opportunities} title="Opportunities" trigger="hover">
                                  <Button
                                    type="primary"
                                    target="_blank"
                                    rel="noreferrer"
                                    shape="round"
                                    size="middle"
                                  >
                                    Opportunities
                                  </Button>{' '}
                                </Popover>
                              </Link>
                            </Col>

                            <Col>
                              {item.name === 'Projects' ?
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    setOpenProjectRolePopup(true)
                                    setSelectedProject(item)
                                  }}
                                  rel="noreferrer"
                                  shape="round"
                                  danger={filterItem ? true : false}
                                  style={{ width: '128px' }}
                                >
                                  {filterItem
                                    ? item.retakebutton
                                    : 'Take Project'}
                                </Button>
                                :
                                <Button
                                  onClick={onRetakeClick}
                                  type="primary"
                                  href={window?.location?.origin?.toString().includes('getnaxxa') ? `${item.url}?custom1=${userInfoToNavigate?.email}&custom3=${userInfoToNavigate?.firstname}&custom4=${userInfoToNavigate?.lastname}` : `${item.devUrl}?custom1=${userInfoToNavigate?.email}&custom3=${userInfoToNavigate?.firstname}&custom4=${userInfoToNavigate?.lastname}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  shape="round"
                                  danger={filterItem ? true : false}
                                >
                                  {filterItem
                                    ? item.retakebutton || 'Retake'
                                    : 'Take'}
                                </Button>}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
              </div>
            </Col>
          </Row>

          <Modal
            title="What role are you playing on this project?"
            open={openProjectRolePopup}
            onOk={() => {
              let url = window?.location?.origin?.toString().includes('getnaxxa') ? selectedProject.url : selectedProject.devUrl
              window.open(`${url}?custom1=${userInfoToNavigate?.email}&custom2=${projectSelectedRole?.join(',')}&custom3=${userInfoToNavigate?.firstname}&custom4=${userInfoToNavigate?.lastname}`, "_blank")
              setOpenProjectRolePopup(false)
              setSelectedProject({})
              setProjectSelectedRole([])
              onRetakeClick()
            }}
            onCancel={() => {
              setOpenProjectRolePopup(false)
              setSelectedProject({})
              setProjectSelectedRole([])
            }}
            okText="Confirm"
            cancelText="Cancel"
          >
            {
              <Checkbox.Group
                style={{ display: 'flex', flexDirection: 'column' }}
                onChange={(list) => setProjectSelectedRole(list)}
                value={projectSelectedRole}
              >
                <Checkbox
                  style={{ marginLeft: '8px' }}
                  value="Builder"
                // checked={checkedList.some((item) => item === option)}
                >
                  Builder
                </Checkbox>
                <Checkbox
                  style={{ marginTop: '5px' }}
                  value="Investor"
                // checked={checkedList.some((item) => item === option)}
                >
                  Investor
                </Checkbox>
                <Checkbox
                  style={{ marginTop: '5px' }}
                  value="Developer"
                // checked={checkedList.some((item) => item === option)}
                >
                  Developer
                </Checkbox>
                <Checkbox
                  style={{ marginTop: '5px' }}
                  value="RealEstateAgent"
                // checked={checkedList.some((item) => item === option)}
                >
                  Real Estate Agent
                </Checkbox>
                <Checkbox
                  style={{ marginTop: '5px' }}
                  value="LandOwner"
                // checked={checkedList.some((item) => item === option)}
                >
                  Land Owner
                </Checkbox>
              </Checkbox.Group>
            }
          </Modal>

          <Drawer
            title={tableData?.length ? tableData[0].value : ''}
            placement="right"
            width={1000}
            onClose={closeSurveyDrawer}
            visible={openSurveyDrawer}
          >
            <Spin
              spinning={isFetching}
              indicator={<Loading3QuartersOutlined
                style={{ fontSize: 40 }} spin />}
            >
              <Modal
                title="Confirm Delete"
                open={openDeletePopup}
                onOk={() => {
                  if (!deleteProjectOption && selectedSurveyForDelete.value === 'I Have a Project Survey') {
                    notification.warn({
                      placement: 'topRight',
                      message: 'Please select an option to delete',
                      duration: 40,
                    });
                    return
                  }
                  deleteSurveyCriteria({
                    id: selectedSurveyForDelete.surveyid,
                    deleteStatus: deleteProjectOption || 'deleted'
                  }).
                    finally(() => getSurveyCriterias());
                  setOpenDeletePopup(false)
                  if (localStorage.getItem('surveyId') === selectedSurveyForDelete.surveyid) {
                    resetSurveyFromLocalStorage()
                  }
                }}
                onCancel={() => {
                  setOpenDeletePopup(false)
                }}
                okText="Yes"
                cancelText="No"
              >
                {
                  selectedSurveyForDelete.value === 'I Have a Project Survey' ?
                    <>
                      <p style={{ fontSize: 16 }}>
                        Are you sure you want to delete this survey?</p>
                      <div style={{ marginTop: 20 }}>
                        <Dropdown overlayStyle={{ zIndex: 9999999 }} overlay={(
                          <Menu onClick={(e) => setDeleteProjectOption(e.key)}
                            items={[
                              { label: 'Delete Sold Out Status', key: 'Delete Sold Out Status' },
                              { label: 'Abandoned', key: 'Abandoned' },
                              { label: 'Final Rejected by City Council', key: 'Final Rejected by City Council' },
                              { label: 'Prelim Rejected by City Council', key: 'Prelim Rejected by City Council' },
                            ]} />
                        )}>
                          <a style={{ fontSize: 16 }}
                            onClick={e => e.preventDefault()}>
                            {deleteProjectOption ? deleteProjectOption : 'Select an option'}</a>
                        </Dropdown>
                      </div>
                    </>
                    : 'Are you sure you want to delete this survey?'
                }
              </Modal>
              <div>
                <Table dataSource={tableData} pagination={false}>
                  {tableData?.length && tableData[0].value === 'I Have a Project Survey' ?
                    <>
                      <Column
                        title=""
                        key=""
                        width={200}
                        align="center"
                        render={(item: any) => (
                          <>
                            {userInfo?.email === item.email ?
                              <Checkbox checked={!item.isPrivate}
                                onChange={(e) => updatePrivate(e, item)}>
                                Public</Checkbox> : null}
                          </>
                        )}
                      />
                      <Column title="Project Name" dataIndex="projectName" key="projectName" />
                    </>
                    : null}
                  {tableData?.length && tableData[0].acres ?
                    <Column title="Acres" dataIndex="acres" key="acres" /> : null}
                  <Column
                    title="Created Date"
                    dataIndex="createdDate"
                    key="createdDate"
                  />
                  <Column
                    title="City"
                    dataIndex="city"
                    key="city"
                  />
                  {tableData?.length && tableData[0].address ?
                    <Column
                      title="Address"
                      dataIndex="address"
                      key="address"
                    /> : null}

                  <Column
                    title="Email"
                    dataIndex="email"
                    key="email"
                  />
                  <Column
                    title="Action"
                    key="action"
                    width={230}
                    render={(item: any) => (
                      <>
                        <Switch
                          title={item.surveyid === selectedSurveyId ? 'Reset' : !selectedSurveyId ? 'Switch Profile' : ''}
                          disabled={selectedSurveyId &&
                            item.surveyid !== selectedSurveyId}
                          checked={item.surveyid ===
                            selectedSurveyId ? true : false}
                          onChange={(e) =>
                            onSwitchProfile(item, e, item.surveyName)} />
                        {userInfo?.email === item.email ?
                          <Button
                            style={{ marginLeft: 10 }}
                            danger
                            size="middle"
                            onClick={() => {
                              onOpenDeletePopup(item);
                            }}
                          >
                            Delete
                          </Button> : null}
                      </>
                    )}
                  />
                </Table>
                <div style={{ textAlign: 'right', marginTop: '30px' }}>
                  <Pagination
                    showSizeChanger={false}
                    defaultCurrent={page}
                    current={page}
                    total={
                      tablePagination &&
                      tablePagination.totalItems
                    }
                    onChange={onPageChange}
                  />
                </div>
              </div>
            </Spin>
          </Drawer>

          <Drawer
            title={'Update Profile'}
            placement="right"
            width={550}
            onClose={() => setOpenProfileDrawer(false)}
            visible={openProfileDrawer}
          >
            <UpdateProfile closeDrawer={() => {
              setOpenProfileDrawer(false)
              refetch()
            }} />
          </Drawer>

          <Drawer
            title={'Change Password'}
            placement="right"
            width={550}
            onClose={() => setOpenPasswordDrawer(false)}
            visible={openPasswordDrawer}
          >
            <ChangePassword closeDrawer={() => {
              setOpenPasswordDrawer(false)
            }} />
          </Drawer>
        </div>
      </Spin>
    </SiteLayout >
  );
};
export default Profile;