/* eslint-disable jsx-a11y/anchor-is-valid */
import { Drawer, Row, Col, Empty, Spin, Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import { useGetCompanySurveyResultsAllQuery, useGetSurveyResultsAllQuery } from 'app/services';
import InfiniteScroll from 'react-infinite-scroll-component';
import CompanyInfoPopover from './CompanyInfoPopover';
import TabPane from 'antd/lib/tabs/TabPane';
import { MoreInfoPopup } from 'components/MoreInfoPopup';
import DownloadButton from 'components/DownloadExcel/DownloadButton';
import { HandleDownload } from 'components/DownloadExcel/HandleDownload';

interface IParamType {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  surveyid: string;
  count: any;
  matchingtype: string;
  data: any;
  title: string;
  color: string;
  initPage: number;
  setMatchingtype?: any;
}
const ItemListModal: React.FC<IParamType> = ({
  visible,
  setVisible,
  surveyid,
  matchingtype,
  count,
  data,
  title,
  color,
  initPage,
  setMatchingtype,
}) => {
  const limit = 20;
  const [listItem, setListItem] = useState<any>([]);
  const [page, setPage] = React.useState(initPage);
  const [visibleCompaniesModel, setVisibleCompaniesModel] = useState(false);
  const [companyName, setCompanyName] = useState('')

  const [companyListItem, setCompanyListItem] = useState([]);
  const [companyPage, setCompanyPage] = React.useState(initPage);

  const [hasMore, setHasMore] = useState(true);
  const [companyHasMore, setCompanyHasMore] = useState(true);

  const [countData, setCountData] = useState<any>('Loading...')
  const [companiesCountData, setCompaniesCountData] = useState<any>('Loading...')
  const [currentSelectedTab, setCurrentSelectedTab] = useState<any>('contacts')

  const {
    data: surveyResult,
    isFetching,
  } = useGetSurveyResultsAllQuery(
    { surveyid, page, limit, matchingtype },
    {
      skip: surveyid === '' || matchingtype === '',
    },
  );

  const {
    data: companySurveyResult,
    isFetching: isCompanyResutFetching,
  } = useGetCompanySurveyResultsAllQuery(
    { surveyid, page: companyPage, limit, matchingtype },
    {
      skip: surveyid === '' || matchingtype === '',
    },
  );

  useEffect(() => {
    setListItem([]);
    setPage(1);
    setCompanyListItem([])
    setCompanyPage(1)
  }, [matchingtype]); // eslint-disable-line

  useEffect(() => {
    if (
      surveyResult &&
      surveyResult?.length > 0 &&
      surveyResult[0][matchingtype]
    ) {
      const results: any = [...listItem]
      results.push(...surveyResult[0][matchingtype]);
      setListItem(results)
    }
  }, [surveyResult, matchingtype]); // eslint-disable-line

  // useEffect(() => {
  //   page > 1 && setHasMore(count > page * limit ? true : false);
  // }, [page]); // eslint-disable-line

  const fetchMoreData = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    if (
      companySurveyResult &&
      companySurveyResult?.length > 0 &&
      companySurveyResult[0][matchingtype]
    ) {
      const results: any = [...companyListItem]
      results.push(...companySurveyResult[0][matchingtype]);
      setCompanyListItem(results)
    }
  }, [companySurveyResult, matchingtype]); // eslint-disable-line

  const fetchMoreCompanyData = () => {
    setCompanyPage(companyPage + 1);
  };

  useEffect(() => {
    if (count?.companiesCount) {
      setCompanyHasMore(count.companiesCount >
        companyPage * limit ? true : false)
      setCompaniesCountData(count.companiesCount)
    } else {
      setCompaniesCountData(0)
    }
    if (count?.contactsCount) {
      setHasMore(count.contactsCount >
        page * limit ? true : false)
      setCountData(count.contactsCount)
    } else {
      setCountData(0)
    }
    if (count?.projectsCount) {
      setHasMore(count.projectsCount >
        page * limit ? true : false)
      setCountData(count.projectsCount)
    }
    if (count?.waterRightsCount) {
      setHasMore(count.waterRightsCount >
        page * limit ? true : false)
      setCountData(count.waterRightsCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, page, companyPage])



  const onClose = () => {
    setVisible(false);
    setListItem([]);
    setPage(1);
    if (setMatchingtype) {
      setMatchingtype('')
    }
  };

  const Tab = ({ property }: any) => (
    <div className="tab-title">
      <div className="name">
        <span>{property}</span>
      </div>
    </div>
  );

  const convertText = (input: any) => {
    if (input.includes('_')) {
      // Keep Snake_Case as is but format with proper capitalization
      return input
        .toLowerCase()
        .split('_')
        .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('_');
    } else if (/[A-Z]/.test(input)) {
      // PascalCase or camelCase to Snake_Case
      return input
        .replace(/([a-z])([A-Z])/g, '$1_$2') // Add underscore between lowercase and uppercase
        .replace(/([A-Z]+)([A-Z][a-z])/g, '$1_$2') // Handle acronyms followed by words
        .toLowerCase()
        .replace(/^./, (match: any) => match.toUpperCase()); // Capitalize the first letter
    } else {
      // Plain lowercase to TitleCase
      return input.charAt(0).toUpperCase() + input.slice(1);
    }
  };

  const onDownloadClick = () => {
    const matchingTypeFormatted = convertText(matchingtype)
    if (matchingtype === 'projects' || matchingtype === 'waterRights') {
      HandleDownload(listItem, `Matching_${matchingTypeFormatted}`)
    } else {
      if (currentSelectedTab === 'companies') {
        HandleDownload(companyListItem, `Matching_${matchingTypeFormatted}_Companies`)
      } else {
        HandleDownload(listItem, `Matching_${matchingTypeFormatted}_Contacts`)
      }
    }
  }

  const tabChangeHandler = (e: any) => {
    setCurrentSelectedTab(e)
  }

  return (
    <Drawer
      placement="right"
      size="large"
      width={'100%'}
      zIndex={1005}
      onClose={onClose}
      open={visible}
      className="item-list-modal"
    >
      <div
        style={{
          backgroundColor: color,
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '26px',
          color: '#fff',
          height: '70px',
          alignItems: 'center',
          marginBottom: '30px',
          borderRadius: '6px',
        }}
      >
        <div></div>
        <div>
          {title}
        </div>
        <DownloadButton type=""
          onClick={onDownloadClick}
          style={{ marginRight: 8, fontSize: 20, borderRadius: 7 }} items={listItem} />
      </div>
      {matchingtype === 'projects' || matchingtype === 'waterRights' ?
        <InfiniteScroll
          dataLength={listItem.length}
          next={fetchMoreData}
          hasMore={hasMore}
          height={'calc(100vh - 165px)'}
          loader={<></>}
          endMessage={''}
          style={{ overflowX: 'hidden', textAlign: 'center' }}
        >
          <Row gutter={16}>
            {listItem && listItem.length > 0 ? (
              listItem.map((item: any, index: number) => (
                matchingtype === 'projects' ?
                  <Col span={6} key={index}>
                    <div className="net-list-item">
                      <h4 className="ant-list-item-meta-title">
                        <strong>
                          {item.name}
                        </strong>
                      </h4>
                      <div className="ant-list-item-meta-description">
                        <div>
                          <strong>Address: </strong>
                          {item.address}
                        </div>
                        <div>
                          <strong>City: </strong>
                          {item.city}
                        </div>
                        <div>
                          <strong>State: </strong>
                          {item.state}
                        </div>
                        <div>
                          <strong>County: </strong>
                          {item.county}
                        </div>
                        <div className="popup-more-info">
                          <MoreInfoPopup title={title} allData={item} />
                        </div>
                      </div>
                    </div>
                  </Col> : matchingtype === 'waterRights' ? <Col span={6} key={index}>
                    <div className="net-list-item">
                      <h4 className="ant-list-item-meta-title">
                        <strong>
                          {item.firstname} {item.lastname}
                        </strong>
                      </h4>
                      <div className="ant-list-item-meta-description">
                        <div>
                          <strong>Email: </strong>
                          {item?.email?.length && Array.isArray(item.email) ?
                            item?.email?.map((elem: any, i: any) => {
                              return i > 0 ? <>, <a key={elem} href={'mailto:' + elem}>{elem}</a></> : <a key={elem} href={'mailto:' + elem}>{elem}</a>
                            }) : item?.email ? <a href={'mailto:' + item.email}>{item.email}</a> : null}
                        </div>
                        <div>
                          <strong>Company: </strong>
                          <a onClick={() => {
                            setCompanyName(item.company);
                            setVisibleCompaniesModel(true)
                          }}>{item.company}</a>
                        </div>
                        <div>
                          <strong>Website: </strong>
                          <a href={item.website} target="_blank" rel="noreferrer">
                            {item?.website?.length &&
                              item?.website?.
                                map((elem: any, i: any) => {
                                  return i > 0 ? <>, <a target='blank' key={elem}
                                    href={elem}>{elem}</a></> : <a target='blank' key={elem} href={elem}>{elem}</a>
                                })}
                          </a>
                        </div>
                        <div>
                          <strong>Address: </strong>
                          {item.address}
                        </div>
                        <div>
                          <strong>Phone: </strong>
                          {item?.phoneNumber?.length &&
                            item?.phoneNumber?.map((elem: any, i: any) => {
                              return i > 0 ? <>, <a key={elem} href={'tel:' + elem}>{elem}</a></> : <a key={elem} href={'tel:' + elem}>{elem}</a>
                            })}

                          {/* {item.phoneNumber1 &&
                        item.phoneNumber1.length > 6 &&
                        `, ${item.phoneNumber1}`}
                      {item.phoneNumber2 &&
                        item.phoneNumber2.length > 6 &&
                        `, ${item.phoneNumber2}`} */}
                        </div>

                        <div className="popup-more-info">
                          <MoreInfoPopup title={title} allData={item} />
                        </div>
                      </div>
                    </div>
                  </Col> : null
              ))
            ) : !isFetching ? (
              <div className="popupEmptyMsg">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            ) : null}
          </Row>
          {
            isFetching ? (
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25, marginTop: 25 }}><Spin tip='Loading' size="large"
                spinning={isFetching}
              /> </div>
            ) : null
          }
        </InfiniteScroll> : <Tabs tabPosition='left' activeKey={currentSelectedTab}
          onChange={(key) => tabChangeHandler(key)}>
          <TabPane
            key="contacts"
            tab={
              <Tab
                property={`Contacts (${countData})`}
              />
            }
          > <InfiniteScroll
            dataLength={listItem.length}
            next={fetchMoreData}
            hasMore={hasMore}
            height={'calc(100vh - 165px)'}
            loader={<></>}
            endMessage={''}
            style={{ overflowX: 'hidden', textAlign: 'center' }}
          >
              <Row gutter={16}>
                {listItem && listItem.length > 0 ? (
                  listItem.map((item: any, index: number) => (
                    <Col span={6} key={index}>
                      <div>
                        <div className="net-list-item">
                          <h4 className="ant-list-item-meta-title">
                            <strong>
                              {item.firstname} {item.lastname}
                            </strong>
                          </h4>
                          <div className="ant-list-item-meta-description">
                            <div>
                              <strong>Email: </strong>
                              {item?.email?.length &&
                                Array.isArray(item.email) ?
                                item?.email?.map((elem: any, i: any) => {
                                  return i > 0 ? <>, <a key={elem} href={'mailto:' + elem}>{elem}</a></> : <a key={elem} href={'mailto:' + elem}>{elem}</a>
                                }) : item?.email ? <a href={'mailto:' + item.email}>{item.email}</a> : null}
                            </div>
                            <div>
                              <strong>Company: </strong>
                              <a onClick={() => {
                                setCompanyName(item.company);
                                setVisibleCompaniesModel(true)
                              }}>{item.company}</a>
                            </div>
                            <div>
                              <strong>Website: </strong>
                              <a href={item.website} target="_blank" rel="noreferrer">
                                {item?.website?.length &&
                                  item?.website?.
                                    map((elem: any, i: any) => {
                                      return i > 0 ? <>, <a target='blank' key={elem}
                                        href={elem}>{elem}</a></> : <a target='blank' key={elem} href={elem}>{elem}</a>
                                    })}
                              </a>
                            </div>
                            <div>
                              <strong>Address: </strong>
                              {item.address}
                            </div>
                            <div>
                              <strong>Phone: </strong>
                              {item?.phoneNumber?.length &&
                                item?.phoneNumber?.map((elem: any, i: any) => {
                                  return i > 0 ? <>, <a key={elem} href={'tel:' + elem}>{elem}</a></> : <a key={elem} href={'tel:' + elem}>{elem}</a>
                                })}
                            </div>

                            <div className="popup-more-info">
                              <MoreInfoPopup title={title} allData={item} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : !isFetching ? (
                  <div className="popupEmptyMsg">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                ) : null}
              </Row>
              {
                isFetching ? (
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25, marginTop: 25 }}><Spin tip='Loading' size="large"
                    spinning={isFetching}
                  /> </div>
                ) : null
              }
            </InfiniteScroll>
          </TabPane>
          <TabPane
            key="companies"
            tab={
              <Tab
                property={`Companies (${companiesCountData})`}
              />
            }
          > <InfiniteScroll
            dataLength={companyListItem.length}
            next={fetchMoreCompanyData}
            hasMore={companyHasMore}
            height={'calc(100vh - 165px)'}
            loader={<></>}
            endMessage={''}
            style={{ overflowX: 'hidden', textAlign: 'center' }}
          >
              <Row gutter={16}>
                {companyListItem && companyListItem.length > 0 ? (
                  companyListItem.map((item: any, index: number) => (
                    <Col span={6} key={index}>
                      <div>
                        <div className="net-list-item">
                          <h4 className="ant-list-item-meta-title">
                            <strong>
                              {item.firstname} {item.lastname}
                            </strong>
                          </h4>
                          <div className="ant-list-item-meta-description">
                            <div>
                              <strong>Email: </strong>
                              {item?.email?.length &&
                                Array.isArray(item.email) ?
                                item?.email?.map((elem: any, i: any) => {
                                  return i > 0 ? <>, <a key={elem} href={'mailto:' + elem}>{elem}</a></> : <a key={elem} href={'mailto:' + elem}>{elem}</a>
                                }) : item?.email ? <a href={'mailto:' + item.email}>{item.email}</a> : null}
                            </div>
                            <div>
                              <strong>Website: </strong>
                              <a href={item.website} target="_blank" rel="noreferrer">
                                {item?.website?.length &&
                                  item?.website?.
                                    map((elem: any, i: any) => {
                                      return i > 0 ? <>, <a target='blank' key={elem}
                                        href={elem}>{elem}</a></> : <a target='blank' key={elem} href={elem}>{elem}</a>
                                    })}
                              </a>
                            </div>
                            <div>
                              <strong>Address: </strong>
                              {item.address}
                            </div>
                            <div>
                              <strong>Phone: </strong>
                              {item?.phoneNumber?.length &&
                                item?.phoneNumber?.map((elem: any, i: any) => {
                                  return i > 0 ? <>, <a key={elem} href={'tel:' + elem}>{elem}</a></> : <a key={elem} href={'tel:' + elem}>{elem}</a>
                                })}
                            </div>

                            <div className="popup-more-info">
                              <MoreInfoPopup title={title} allData={item} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : !isCompanyResutFetching ? (
                  <div className="popupEmptyMsg">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                ) : null}
              </Row>
              {
                isCompanyResutFetching ? (
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25, marginTop: 25 }}><Spin tip='Loading' size="large"
                    spinning={isCompanyResutFetching}
                  /> </div>
                ) : null
              }
            </InfiniteScroll>
          </TabPane>
        </Tabs>}
      <CompanyInfoPopover
        visible={visibleCompaniesModel}
        setVisible={setVisibleCompaniesModel}
        companyName={companyName}
        setCompanyName={setCompanyName}
      />
    </Drawer>
  );
};

export default ItemListModal;
